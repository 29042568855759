import { getInitials } from "@regrello/core-utils";
import {
  RegrelloAvatar,
  RegrelloAvatarProps,
  RegrelloIcon,
  RegrelloIntent,
  RegrelloShape,
  RegrelloTooltipV4,
  RegrelloTypography,
} from "@regrello/ui-core";
import { NotActivelyScimProvisionedTooltipText, UserAD, ViaScim } from "@regrello/ui-strings";
import React, { useMemo } from "react";

import { FlatParty } from "./utils";

export interface RegrelloPartyAvatarProps
  extends Omit<RegrelloAvatarProps, "indicator" | "scope" | "shape" | "text" | "visual"> {
  hideText?: boolean;

  /** The semantic intent (color) to apply to the icon, if it's an `icon` type. */
  iconIntent?: RegrelloIntent;

  isActivelyScimProvisioned?: boolean;

  /** Whether to visually emphasize and show an error icon for deleted parties. */
  isHighlightedIfDeleted?: boolean;

  party: FlatParty;

  /** Whether to show the party's email address as secondary text. */
  showEmail?: boolean;

  /** Whether to show the party's muted indicator. We typically want to enable this
   * where the user's mute status is important, such as when assigning or CCing them
   * else it gets super noisy to see the icon everywhere.
   */
  showIsMutedIndicator?: boolean;

  /** Show Via SCIM description under user avatar */
  showViaScimCaption?: boolean;
}

/** An avatar that represents a party in Regrello. */
export const RegrelloPartyAvatar = React.memo<RegrelloPartyAvatarProps>(function RegrelloPartyAvatarFn({
  hideText,
  iconIntent,
  isActivelyScimProvisioned = false,
  isHighlightedIfDeleted = false,
  party,
  showEmail,
  showIsMutedIndicator,
  showViaScimCaption = false,
  ...avatarProps
}) {
  const visual: RegrelloAvatarProps["visual"] = useMemo(() => {
    switch (true) {
      case party.type === "fieldInstance" && party.fieldInstanceSubtype !== "role":
        return {
          type: "icon",
          iconName: "person-field",
          isFullSize: true,
          isVisuallyUnwrapped: true,
        };
      case party.type === "role" || party.fieldInstanceSubtype === "role":
        return {
          type: "icon",
          iconName: "role",
          intent: iconIntent,
          isFullSize: true,
          isVisuallyUnwrapped: true,
        };
      case party.userType === "SERVICE_ACCOUNT":
      case party.userType === "SCIM_SERVICE_ACCOUNT":
        return {
          type: "icon",
          iconName: "settings",
          intent: "constructive",
          isFullSize: false,
        };

      default:
        return {
          type: "initials",
          text: getInitials(party.fullName),
        };
    }
  }, [iconIntent, party.fullName, party.fieldInstanceSubtype, party.type, party.userType]);

  return (
    <>
      <RegrelloAvatar
        {...avatarProps}
        indicator={party.isInactive ? "inactive" : showIsMutedIndicator && party.isMuted ? "muted" : undefined}
        isHighlightedWhenInactive={isHighlightedIfDeleted}
        scope={party.isInternal ? "internal" : "external"}
        secondaryText={showEmail ? party.email : undefined}
        shape={party.type === "team" ? RegrelloShape.RECTANGLE : RegrelloShape.CIRCLE}
        text={hideText ? undefined : showViaScimCaption ? UserAD : party.fullName}
        visual={visual}
      />
      {showViaScimCaption && (
        <div className="inline-flex">
          <RegrelloTypography className="flex items-center mt-auto" muted={true} variant="body-xs">
            {ViaScim}
          </RegrelloTypography>
          {!isActivelyScimProvisioned && (
            <RegrelloTooltipV4 content={NotActivelyScimProvisionedTooltipText}>
              <span>
                <RegrelloIcon className="pl-2" iconName="data-sync-error" intent="neutral" variant="muted" />
              </span>
            </RegrelloTooltipV4>
          )}
        </div>
      )}
    </>
  );
});
