import { clsx, mergeRefs, useSimpleHover, WithChildren } from "@regrello/core-utils";
import { RegrelloDragHandle, useDndReorder } from "@regrello/ui-core";
import React, { useRef } from "react";
import { FieldArrayPath, FieldArrayWithId, FieldValues } from "react-hook-form";

export interface RegrelloDraggableRowItemProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = "id",
> extends WithChildren {
  index: number;
  isDragEnabled?: boolean;
  row: FieldArrayWithId<TFieldValues, TFieldArrayName, TKeyName>;
  preview?: React.ReactNode;
  moveRow: (indexA: number, indexB: number) => void;
}

export const RegrelloDraggableRowItem = React.memo<RegrelloDraggableRowItemProps>(function RegrelloDraggableRowItemFn({
  index,
  isDragEnabled = false,
  row,
  moveRow,
  preview,
  children,
}) {
  const dropElementRef = useRef<HTMLElement | null>(null);
  const { dragRef, dropRef, dropState, onDragHandleMouseDown, renderDragPreviewUnderCursorIfDragging } = useDndReorder({
    dropElementRef,
    isDragEnabled: isDragEnabled,
    item: { id: row.id, index },
    itemType: "item",
    onDrop: (draggedItem, droppedOverItem, side) => {
      const itemWasAbove = draggedItem.index < droppedOverItem.index;
      const itemWasBelow = draggedItem.index > droppedOverItem.index;

      if (itemWasAbove && side === "above") {
        moveRow(draggedItem.index, droppedOverItem.index - 1);
      } else if (itemWasBelow && side === "above") {
        moveRow(draggedItem.index, droppedOverItem.index);
      } else if (itemWasAbove && side === "below") {
        moveRow(draggedItem.index, droppedOverItem.index);
      } else if (itemWasBelow && side === "below") {
        moveRow(draggedItem.index, droppedOverItem.index + 1);
      }
    },
  });

  const { isHovered, onMouseEnter, onMouseLeave } = useSimpleHover();
  return (
    <div
      key={row.id}
      ref={mergeRefs(dropRef, dropElementRef)}
      className={clsx("flex py-1 relative", {
        "after:content-[''] after:absolute after:inset-0 after:z-2 after:pointer-events-none after:ring-width--4 after:shadow-y":
          dropState.isDraggingOver && dropState.dropSide === "top",
        "after:content-[''] after:absolute after:inset-0 after:z-2 after:pointer-events-none after:ring-width-4 after:shadow-y":
          dropState.isDraggingOver && dropState.dropSide === "bottom",
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {preview != null && renderDragPreviewUnderCursorIfDragging(preview)}
      {isDragEnabled ? (
        <RegrelloDragHandle
          className="items-start mt-1 mr-2"
          dragRef={dragRef}
          isDragEnabled={isDragEnabled}
          isHovered={isHovered}
          onMouseDown={onDragHandleMouseDown}
        />
      ) : null}
      {children}
    </div>
  );
});
