import {
  assertNever,
  EMPTY_ARRAY,
  EMPTY_STRING,
  formatDateString,
  getKeys,
  isBoolean,
  isDate,
  isDefined,
  isString,
} from "@regrello/core-utils";
import {
  ConditionConnective,
  ConditionOperator,
  PartyFields,
  TagFields,
  UserFields,
  ViewColumnFields,
  ViewFilterFields,
  WorkflowDefaultColumnOption,
  WorkflowRuntimeStatus,
} from "@regrello/graphql-api";
import {
  BlueprintId,
  Completed,
  ConditionalPlannedEndDate,
  CreatedBy,
  EndDate,
  Ended,
  HomeDelayedDays,
  HomeLatestActivity,
  HomePercentComplete,
  Messages,
  NotStarted,
  OnTrack,
  Overdue,
  Owner,
  Paused,
  PlannedEndDate,
  Problem,
  ShowCompleted,
  StartDate,
  Status,
  Tags,
  VersionPound,
  Workflow,
  WorkflowTemplate,
} from "@regrello/ui-strings";

import { PartyTypeUnion } from "./parties/PartyTypeUnion";
import { getPartyId, getPartyTypeUnion, getUserId } from "./parties/partyUtils";
import { FilterTypeUnion, FilterTypeUnionUtil } from "../types/FilterTypeUnion";
import { CustomFieldPluginRegistrar } from "../ui/molecules/customFields/plugins/registry/customFieldPluginRegistrar";
import { BlueprintUuidOption } from "../ui/molecules/formFields/RegrelloFormFieldBlueprintUuidSelect";
import { WorkflowTemplateNameFields } from "../ui/molecules/formFields/RegrelloFormFieldWorkflowTemplateSelect";
import { getColumnIdFromViewColumn } from "../ui/molecules/tableColumnsButton/_internal/tableColumnsUtils";
import {
  getRegrelloDefaultFilterDefinitionBooleanValue,
  getRegrelloDefaultFilterDefinitionDateValue,
  getRegrelloDefaultFilterDefinitionMultiSelectValue,
  getRegrelloDefaultFilterDefinitionNumberValue,
  getRegrelloDefaultFilterDefinitionSingleSelectValue,
  getRegrelloDefaultFilterDefinitionStageStatusValue,
  getRegrelloDefaultFilterDefinitionStringValue,
  getRegrelloFilterDefinitionBlueprintUuidSingleSelectValue,
  getRegrelloFilterDefinitionBooleanValue,
  getRegrelloFilterDefinitionDateValue,
  getRegrelloFilterDefinitionNumberValue,
  getRegrelloFilterDefinitionPartySingleSelectValue,
  getRegrelloFilterDefinitionStageStatusValue,
  getRegrelloFilterDefinitionStringValue,
  getRegrelloFilterDefinitionTagSelectValue,
  getRegrelloFilterDefinitionWorkflowTemplateValue,
} from "../ui/molecules/tableFilterControlV2/_internal/core/regrelloFilterV2Constants";
import {
  RegrelloFilterDefinitionUnion,
  RegrelloFilterGroup,
} from "../ui/molecules/tableFilterControlV2/_internal/core/regrelloFilterV2Types";
import { TableFilter } from "../ui/molecules/tableFilters/constants/filters";
import { convertPercentCompleteToInt, getColumnName } from "../ui/views/pages/home/_internal/homePageUtils";
import { HomeFilters } from "../ui/views/pages/home/HomePage";

export enum HomeAdditionalFilters {
  SHOW_COMPLETED = "showCompleted",
}

type FilterSchema = {
  [columnKey: string]: {
    displayName: string;
    defaultFilterDefinition: RegrelloFilterDefinitionUnion<unknown>;
  };
};

export function isFilterActive(filter: FilterTypeUnion) {
  // (hchen): HACKHACK: Handles the case where filter is undefined thought it's not supported by
  // FilterTypeUnion. This should fixed the error Mudd encountered on Apr 26, 2022. Sentry reported
  // filter.runningWorkflow is undefined although it's supposed to always have a value.
  if (filter == null) {
    return false;
  }

  if (FilterTypeUnionUtil.isCheckbox(filter)) {
    return filter.checkboxFilter.value;
  }
  if (FilterTypeUnionUtil.isDate(filter)) {
    return filter.dateFilter.value != null;
  }
  if (FilterTypeUnionUtil.isNumber(filter)) {
    return filter.numberFilter.value.length > 0;
  }
  if (FilterTypeUnionUtil.isMultiSelect(filter)) {
    return filter.multiSelectFilter.value.length > 0;
  }
  if (FilterTypeUnionUtil.isPartySelect(filter)) {
    return filter.partySelectFilter.value.length > 0;
  }
  if (FilterTypeUnionUtil.isSelect(filter)) {
    return filter.selectFilter.value != null;
  }
  if (FilterTypeUnionUtil.isTagSelect(filter)) {
    return filter.tagSelectFilter.value.length > 0;
  }
  if (FilterTypeUnionUtil.isText(filter)) {
    return filter.textFilter.value.length > 0;
  }
  return false;
}

export function getResetFilter(filter: FilterTypeUnion): FilterTypeUnion {
  if (FilterTypeUnionUtil.isCheckbox(filter)) {
    return {
      ...filter,
      checkboxFilter: {
        value: false,
      },
    };
  } else if (FilterTypeUnionUtil.isDate(filter)) {
    return {
      ...filter,
      dateFilter: {
        value: null,
      },
    };
  } else if (FilterTypeUnionUtil.isNumber(filter)) {
    return {
      ...filter,
      numberFilter: {
        value: EMPTY_STRING,
      },
    };
  } else if (FilterTypeUnionUtil.isMultiSelect(filter)) {
    return {
      ...filter,
      multiSelectFilter: {
        ...filter.multiSelectFilter,
        value: EMPTY_ARRAY,
      },
    };
  } else if (FilterTypeUnionUtil.isPartySelect(filter)) {
    return {
      ...filter,
      partySelectFilter: {
        value: EMPTY_ARRAY,
      },
    };
  } else if (FilterTypeUnionUtil.isSelect(filter)) {
    return {
      ...filter,
      selectFilter: {
        ...filter.selectFilter,
        value: null,
      },
    };
  } else if (FilterTypeUnionUtil.isTagSelect(filter)) {
    return {
      ...filter,
      tagSelectFilter: {
        value: EMPTY_ARRAY,
      },
    };
  } else if (FilterTypeUnionUtil.isText(filter)) {
    return {
      ...filter,
      textFilter: {
        value: EMPTY_STRING,
      },
    };
  } else {
    return filter;
  }
}

export function getRawFilterValue(filter: FilterTypeUnion) {
  return FilterTypeUnionUtil.visit(filter, {
    checkbox: (filterInternal) => filterInternal.checkboxFilter.value,
    date: (filterInternal) => filterInternal.dateFilter.value,
    number: (filterInternal) => filterInternal.numberFilter.value,
    multiSelect: (filterInternal) => filterInternal.multiSelectFilter.value,
    partySelect: (filterInternal) => filterInternal.partySelectFilter.value,
    select: (filterInternal) => filterInternal.selectFilter.value,
    tagSelect: (filterInternal) => filterInternal.tagSelectFilter.value,
    text: (filterInternal) => filterInternal.textFilter.value,
    divider: () => undefined,
    unknown: () => undefined,
  });
}

export function setRawFilterValue(filter: FilterTypeUnion, nextValue: unknown) {
  if (FilterTypeUnionUtil.isCheckbox(filter)) {
    if (isBoolean(nextValue)) {
      filter.checkboxFilter.value = nextValue;
    }
  } else if (FilterTypeUnionUtil.isDate(filter)) {
    if (nextValue === null || isString(nextValue)) {
      filter.dateFilter.value = nextValue;
    } else if (isDate(nextValue)) {
      filter.dateFilter.value = formatDateString(nextValue);
    }
  } else if (FilterTypeUnionUtil.isNumber(filter)) {
    if (isString(nextValue)) {
      filter.numberFilter.value = nextValue;
    }
  } else if (FilterTypeUnionUtil.isMultiSelect(filter)) {
    if (Array.isArray(nextValue)) {
      filter.multiSelectFilter.value = nextValue;
    }
  } else if (FilterTypeUnionUtil.isPartySelect(filter)) {
    if (Array.isArray(nextValue)) {
      filter.partySelectFilter.value = nextValue;
    }
  } else if (FilterTypeUnionUtil.isSelect(filter)) {
    if (nextValue === null || isString(nextValue)) {
      filter.selectFilter.value = nextValue;
    }
  } else if (FilterTypeUnionUtil.isTagSelect(filter)) {
    if (Array.isArray(nextValue)) {
      filter.tagSelectFilter.value = nextValue;
    }
  } else if (FilterTypeUnionUtil.isText(filter)) {
    if (isString(nextValue)) {
      filter.textFilter.value = nextValue;
    }
  }
}

export function getActiveFilterCount(filters: TableFilter) {
  const keys = getKeys(filters);
  let activeFilterCount = 0;
  for (const key of keys) {
    if (isFilterActive(filters[key])) {
      activeFilterCount += 1;
    }
  }
  return activeFilterCount;
}

export function getUserValues(filterValues: string[], parties: PartyTypeUnion[]): PartyTypeUnion[] {
  return filterValues
    .map((filterValue) => parties.find((party) => getUserId(party)?.toString() === filterValue))
    .filter(isDefined);
}

export function getPartyValues(filterValues: string[], parties: PartyTypeUnion[]): PartyTypeUnion[] {
  return filterValues
    .map((filterValue) => parties.find((party) => getPartyId(party)?.toString() === filterValue))
    .filter(isDefined);
}

export function getTagValues(filterValues: string[], tags: TagFields[]): TagFields[] {
  return filterValues
    .map((filterValue) => {
      const maybeTag = tags.find((tag) => tag.id.toString() === filterValue);
      return maybeTag != null ? maybeTag : undefined;
    })
    .filter(isDefined);
}

export function getBlueprintValues(filterValues: string[], blueprints: BlueprintUuidOption[]): BlueprintUuidOption[] {
  return filterValues
    .map((filterValue) => blueprints.find((blueprint) => blueprint.blueprintUuid === filterValue))
    .filter(isDefined);
}

function getFilterDefinition(
  column: ViewColumnFields,
  filter: ViewFilterFields,
  parties: PartyTypeUnion[],
  tags: TagFields[],
  blueprints: BlueprintUuidOption[],
): RegrelloFilterDefinitionUnion<unknown> | undefined {
  const columnName = getColumnName(column);

  if (filter.value == null && filter.values == null) {
    return undefined;
  }

  const isDefaultColumn =
    getKeys(WorkflowDefaultColumnOption).find((columnOption) => columnOption === columnName) != null;

  if (isDefaultColumn) {
    switch (columnName) {
      case WorkflowDefaultColumnOption.WORKFLOW_NAME:
        return filter.value != null ? getRegrelloFilterDefinitionStringValue(filter.operator, filter.value) : undefined;
      case WorkflowDefaultColumnOption.WORKFLOW_TEMPLATE_NAME:
        return filter.values != null
          ? getRegrelloFilterDefinitionWorkflowTemplateValue(filter.operator, filter.value ?? undefined, {
              type: "string",
            })
          : filter.value != null
            ? getRegrelloFilterDefinitionStringValue(filter.operator, filter.value)
            : undefined;
      case WorkflowDefaultColumnOption.WORKFLOW_TEMPLATE_VERSION_NUMBER:
        return getRegrelloFilterDefinitionNumberValue(
          filter.operator,
          filter.value != null ? Number.parseInt(filter.value, 10) : 1,
        );
      case WorkflowDefaultColumnOption.WORKFLOW_LAST_UPDATED:
      case WorkflowDefaultColumnOption.START_DATE:
      case WorkflowDefaultColumnOption.END_DATE:
      case WorkflowDefaultColumnOption.PLANNED_END_DATE:
      case WorkflowDefaultColumnOption.CONDITIONAL_PLANNED_END_DATE:
        return getRegrelloFilterDefinitionDateValue(filter.operator, filter.value, filter.values);
      case WorkflowDefaultColumnOption.WORKFLOW_EXCEPTION:
        return getRegrelloFilterDefinitionBooleanValue(filter.value === "true");
      case WorkflowDefaultColumnOption.PERCENT_COMPLETE:
        return getRegrelloFilterDefinitionNumberValue(
          filter.operator,
          // (zstanik): Round percent complete to nearest int (we don't support decimal places for
          // this filter)
          filter.value != null ? convertPercentCompleteToInt(filter.value) : 0,
        );
      case WorkflowDefaultColumnOption.WORKFLOW_DAYS_DELAYED:
        return getRegrelloFilterDefinitionNumberValue(
          filter.operator,
          filter.value != null ? Number.parseFloat(filter.value) : 0,
        );
      case WorkflowDefaultColumnOption.STAGE_STATUS:
        return getRegrelloFilterDefinitionStageStatusValue(
          filter.operator,
          filter.values ?? EMPTY_ARRAY,
          getKeys(WorkflowRuntimeStatus).map((status, index) => {
            return {
              id: index,
              label: getStageStatusDisplayValue(status),
              value: status,
            };
          }),
        );
      case WorkflowDefaultColumnOption.OWNER_PARTY:
        return getRegrelloFilterDefinitionPartySingleSelectValue(
          filter.operator,
          getPartyValues(filter.values ?? EMPTY_ARRAY, parties),
        );
      case WorkflowDefaultColumnOption.CREATED_BY_V2:
        return getRegrelloFilterDefinitionPartySingleSelectValue(
          filter.operator,
          getUserValues(filter.values ?? EMPTY_ARRAY, parties),
        );
      case WorkflowDefaultColumnOption.WORKFLOW_TAGS:
        return getRegrelloFilterDefinitionTagSelectValue(
          filter.operator,
          getTagValues(filter.values ?? EMPTY_ARRAY, tags),
        );
      case WorkflowDefaultColumnOption.BLUEPRINT_UUID:
        return getRegrelloFilterDefinitionBlueprintUuidSingleSelectValue(
          filter.operator,
          getBlueprintValues(filter.values ?? EMPTY_ARRAY, blueprints),
        );
      default:
        return undefined;
    }
  }

  // Custom field columns
  if (column.field != null) {
    const customFieldPlugin = CustomFieldPluginRegistrar.getPluginForField(column.field);
    return customFieldPlugin.getFilterDefinitionWithValues(column.field, filter, parties);
  }

  return undefined;
}

function getHomeV2FilterDefinitions(
  column: ViewColumnFields,
  parties: PartyTypeUnion[],
  tags: TagFields[],
  blueprints: BlueprintUuidOption[],
): Array<RegrelloFilterDefinitionUnion<unknown>> | undefined {
  const filterDefinitions = column.filters
    .map((filter) => getFilterDefinition(column, filter, parties, tags, blueprints))
    .filter(isDefined);

  // (wbuchanan): For the "Stage status" filter, the backend can return multiple single-value
  // filters which we must combine into one multi-value filter in order to render a mutliselect on
  // the frontend.
  if (getColumnName(column) === WorkflowDefaultColumnOption.STAGE_STATUS && column.filters.length > 0) {
    const values = column.filters
      .map((filter) => (filter.value != null ? getStageStatusDisplayValue(filter.value) : undefined))
      .filter(isDefined);

    const filterDefinition = getRegrelloFilterDefinitionStageStatusValue(
      ConditionOperator.IS_ANY_OF,
      values,
      getKeys(WorkflowRuntimeStatus).map((status, index) => {
        return {
          id: index,
          label: getStageStatusDisplayValue(status),
          value: getStageStatusDisplayValue(status),
        };
      }),
    );

    return filterDefinition != null ? [filterDefinition] : undefined;
  }

  return filterDefinitions.length > 0 ? filterDefinitions : undefined;
}

export function getHomeTableFiltersV2(
  columns: ViewColumnFields[],
  parties: PartyTypeUnion[],
  tags: TagFields[],
  blueprints: BlueprintUuidOption[],
  isShowCompletedEnabled: boolean,
): RegrelloFilterGroup<HomeFilters> {
  const filters: RegrelloFilterGroup<HomeFilters>["filters"] = [];
  columns?.forEach((column) => {
    const columnId = getColumnName(column);
    const filterDefinitions = getHomeV2FilterDefinitions(column, parties, tags, blueprints) ?? EMPTY_ARRAY;
    filterDefinitions.forEach((filterDefinition) => {
      if (filterDefinition != null) {
        filters.push({
          type: "filter",
          columnId,
          filterDefinition,
        });
      }
    });
  });

  filters.push({
    type: "filter",
    columnId: HomeAdditionalFilters.SHOW_COMPLETED,
    filterDefinition: getRegrelloFilterDefinitionBooleanValue(isShowCompletedEnabled),
  });

  // TODO HOME: update join operator
  return {
    connective: ConditionConnective.AND,
    filters,
  };
}

function getStageStatusDisplayValue(status: string): string {
  switch (status) {
    case WorkflowRuntimeStatus.OPEN:
      return OnTrack;
    case WorkflowRuntimeStatus.OVERDUE:
      return Overdue;
    case WorkflowRuntimeStatus.EXCEPTION:
      return Problem;
    case WorkflowRuntimeStatus.COMPLETED:
      return Completed;
    case WorkflowRuntimeStatus.NOT_STARTED:
      return NotStarted;
    case WorkflowRuntimeStatus.PAUSED:
      return Paused;
    case WorkflowRuntimeStatus.ENDED:
      return Ended;
    default:
      return EMPTY_STRING;
  }
}

export function getStageStatus(status: string): WorkflowRuntimeStatus | undefined {
  switch (status) {
    case OnTrack:
      return WorkflowRuntimeStatus.OPEN;
    case Overdue:
      return WorkflowRuntimeStatus.OVERDUE;
    case Problem:
      return WorkflowRuntimeStatus.EXCEPTION;
    case Completed:
      return WorkflowRuntimeStatus.COMPLETED;
    case NotStarted:
      return WorkflowRuntimeStatus.NOT_STARTED;
    case Paused:
      return WorkflowRuntimeStatus.PAUSED;
    case Ended:
      return WorkflowRuntimeStatus.ENDED;
    default:
      return undefined;
  }
}

export function getHomeFilterV2Schema(columns: ViewColumnFields[]) {
  const featureFlaggedStatusFilterValues = [
    WorkflowRuntimeStatus.OVERDUE,
    WorkflowRuntimeStatus.OPEN,
    WorkflowRuntimeStatus.COMPLETED,
    WorkflowRuntimeStatus.NOT_STARTED,
    WorkflowRuntimeStatus.EXCEPTION,
    WorkflowRuntimeStatus.PAUSED,
    WorkflowRuntimeStatus.ENDED,
  ];

  const defaultColumnSchema: FilterSchema = {
    ...(columns.find((col) => col.defaultColumnOption === WorkflowDefaultColumnOption.STAGE_STATUS) && {
      STAGE_STATUS: {
        displayName: Status,
        defaultFilterDefinition: getRegrelloDefaultFilterDefinitionStageStatusValue<string>({
          type: "default",
          // (anthony): Hard-coded because of requirement to always display in this order.
          values: featureFlaggedStatusFilterValues.map((status, index) => {
            return {
              id: index,
              label: getStageStatusDisplayValue(status),
              value: getStageStatusDisplayValue(status),
            };
          }),
        }),
      },
    }),
    ...(columns.find((col) => col.defaultColumnOption === WorkflowDefaultColumnOption.WORKFLOW_NAME) && {
      WORKFLOW_NAME: {
        displayName: Workflow,
        defaultFilterDefinition: getRegrelloDefaultFilterDefinitionStringValue(),
      },
    }),
    ...(columns.find((col) => col.defaultColumnOption === WorkflowDefaultColumnOption.OWNER_PARTY) && {
      OWNER_PARTY: {
        displayName: Owner,
        defaultFilterDefinition: getRegrelloDefaultFilterDefinitionSingleSelectValue({ type: "party" }),
      },
    }),
    ...(columns.find((col) => col.defaultColumnOption === WorkflowDefaultColumnOption.CREATED_BY_V2) && {
      CREATED_BY_V2: {
        displayName: CreatedBy,
        defaultFilterDefinition: getRegrelloDefaultFilterDefinitionSingleSelectValue({ type: "party" }),
      },
    }),
    ...(columns.find((col) => col.defaultColumnOption === WorkflowDefaultColumnOption.PERCENT_COMPLETE) && {
      PERCENT_COMPLETE: {
        displayName: HomePercentComplete,
        defaultFilterDefinition: getRegrelloDefaultFilterDefinitionNumberValue(),
      },
    }),
    ...(columns.find((col) => col.defaultColumnOption === WorkflowDefaultColumnOption.WORKFLOW_DAYS_DELAYED) && {
      WORKFLOW_DAYS_DELAYED: {
        displayName: HomeDelayedDays,
        defaultFilterDefinition: getRegrelloDefaultFilterDefinitionNumberValue(),
      },
    }),
    ...(columns.find((col) => col.defaultColumnOption === WorkflowDefaultColumnOption.WORKFLOW_UNREAD_MESSAGES) && {
      WORKFLOW_UNREAD_MESSAGES: {
        displayName: Messages,
        defaultFilterDefinition: getRegrelloDefaultFilterDefinitionNumberValue(),
      },
    }),
    ...(columns.find((col) => col.defaultColumnOption === WorkflowDefaultColumnOption.WORKFLOW_LAST_UPDATED) && {
      WORKFLOW_LAST_UPDATED: {
        displayName: HomeLatestActivity,
        defaultFilterDefinition: getRegrelloDefaultFilterDefinitionDateValue(),
      },
    }),
    ...(columns.find((col) => col.defaultColumnOption === WorkflowDefaultColumnOption.START_DATE) && {
      START_DATE: {
        displayName: StartDate,
        defaultFilterDefinition: getRegrelloDefaultFilterDefinitionDateValue(),
      },
    }),
    ...(columns.find((col) => col.defaultColumnOption === WorkflowDefaultColumnOption.END_DATE) && {
      END_DATE: {
        displayName: EndDate,
        defaultFilterDefinition: getRegrelloDefaultFilterDefinitionDateValue(),
      },
    }),
    ...(columns.find((col) => col.defaultColumnOption === WorkflowDefaultColumnOption.PLANNED_END_DATE) && {
      PLANNED_END_DATE: {
        displayName: PlannedEndDate,
        defaultFilterDefinition: getRegrelloDefaultFilterDefinitionDateValue(),
      },
    }),
    ...(columns.find((col) => col.defaultColumnOption === WorkflowDefaultColumnOption.CONDITIONAL_PLANNED_END_DATE) && {
      CONDITIONAL_PLANNED_END_DATE: {
        displayName: ConditionalPlannedEndDate,
        defaultFilterDefinition: getRegrelloDefaultFilterDefinitionDateValue(),
      },
    }),
    ...(columns.find((col) => col.defaultColumnOption === WorkflowDefaultColumnOption.WORKFLOW_TEMPLATE_NAME) && {
      WORKFLOW_TEMPLATE_NAME: {
        displayName: WorkflowTemplate,
        // (wsheehan): Use string value until we support blueprints versioning to avoid user
        // confusion.
        defaultFilterDefinition: getRegrelloDefaultFilterDefinitionStringValue(),
        // defaultFilterDefinition: getRegrelloDefaultFilterDefinitionWorkflowTemplateValue({
        //   type: "string",
        // }),
      },
    }),
    ...(columns.find(
      (col) => col.defaultColumnOption === WorkflowDefaultColumnOption.WORKFLOW_TEMPLATE_VERSION_NUMBER,
    ) && {
      WORKFLOW_TEMPLATE_VERSION_NUMBER: {
        displayName: VersionPound,
        defaultFilterDefinition: getRegrelloDefaultFilterDefinitionNumberValue(),
      },
    }),
    ...(columns.find((col) => col.defaultColumnOption === WorkflowDefaultColumnOption.BLUEPRINT_UUID) && {
      BLUEPRINT_UUID: {
        displayName: BlueprintId,
        defaultFilterDefinition: getRegrelloDefaultFilterDefinitionSingleSelectValue({ type: "blueprintUuid" }),
      },
    }),
    ...(columns.find((col) => col.defaultColumnOption === WorkflowDefaultColumnOption.WORKFLOW_TAGS) && {
      WORKFLOW_TAGS: {
        displayName: Tags,
        defaultFilterDefinition: getRegrelloDefaultFilterDefinitionMultiSelectValue({ type: "tag" }),
      },
    }),
    showCompleted: {
      displayName: ShowCompleted,
      defaultFilterDefinition: getRegrelloDefaultFilterDefinitionBooleanValue(),
    },
  };

  const customColumnSchema: FilterSchema = {};

  for (const col of columns) {
    if (col.field != null) {
      const customFieldPlugin = CustomFieldPluginRegistrar.getPluginForField(col.field);
      const columnId = getColumnIdFromViewColumn(col);
      let displayName = col.field.name;

      if (col.regrelloObjectProperty?.displayName != null) {
        displayName = `${col.regrelloObjectProperty.displayName} (${col.field.name})`;
      }

      customColumnSchema[columnId] = {
        displayName: displayName,
        defaultFilterDefinition: customFieldPlugin.getFilterDefinition(col.field),
      };
    }
  }

  return { ...defaultColumnSchema, ...customColumnSchema };
}

export function getFilterSingleValueAsString(filter: RegrelloFilterGroup<HomeFilters>["filters"][0]) {
  if (filter.filterDefinition.type === "multiSelect") {
    return undefined;
  }

  const value = filter.filterDefinition.filter;
  switch (value.operator) {
    case ConditionOperator.EQUALS:
    case ConditionOperator.NOT_EQUALS:
    case ConditionOperator.GREATER_THAN:
    case ConditionOperator.LESS_THAN:
      // TODO Home: convert to percentage, e.g. 80 --> .80
      if (filter.columnId === WorkflowDefaultColumnOption.PERCENT_COMPLETE) {
        return value.value.toString();
      }

      // Special case for dates, as the value is contained in dateString object
      if (filter.filterDefinition.type === "date") {
        return filter.filterDefinition.filter.value.dateString ?? EMPTY_STRING;
      }

      return value.value.toString();
    case ConditionOperator.CONTAINS_ALL_OF:
      if (filter.filterDefinition.type === "document") {
        return value.value.toString();
      }
      return undefined;
    default:
      return undefined;
  }
}

export function getFilterMultiValue(filter: RegrelloFilterGroup<HomeFilters>["filters"][0]): string[] | undefined {
  if (
    filter.filterDefinition.type !== "multiSelect" &&
    filter.filterDefinition.type !== "singleSelect" &&
    filter.filterDefinition.type !== "stageStatus"
  ) {
    return undefined;
  }

  const multiselectType = filter.filterDefinition.options.type;

  switch (multiselectType) {
    case "default": {
      const val = filter.filterDefinition.filter.values as Array<{ id: number; label: string; value: string }>;
      return val.map(({ value }) => value);
    }
    case "party": {
      if (filter.columnId === WorkflowDefaultColumnOption.CREATED_BY_V2) {
        // (wbuchanan): Filtering the "Created by" column is a special case that requires user IDs, not party IDs
        const val = filter.filterDefinition.filter.values as Array<{ type: string; user: UserFields }>;
        return val.map(({ user }) => user.id.toString());
      } else {
        const val = filter.filterDefinition.filter.values as PartyFields[];
        return val.map((party) => getPartyId(getPartyTypeUnion(party)).toString());
      }
    }
    case "tag": {
      const val = filter.filterDefinition.filter.values as TagFields[];
      return val.map(({ id }) => id.toString());
    }
    case "workflowTemplate": {
      const val = filter.filterDefinition.filter.values as WorkflowTemplateNameFields[];
      return val.map(({ id }) => id.toString());
    }
    case "blueprintUuid": {
      const val = filter.filterDefinition.filter.values as BlueprintUuidOption[];
      return val.map(({ blueprintUuid }) => blueprintUuid);
    }
    default:
      assertNever(multiselectType);
  }
}

export function getIsShowCompletedEnabled(filters: RegrelloFilterGroup<HomeFilters>): boolean {
  const showCompletedFilter = filters.filters.find(
    (filter) => filter.columnId === HomeAdditionalFilters.SHOW_COMPLETED,
  );
  if (showCompletedFilter == null) {
    return false;
  }
  return getFilterSingleValueAsString(showCompletedFilter) === "true";
}
