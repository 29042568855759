import { clsx } from "@regrello/core-utils";
import { RegrelloSpinner, RegrelloTypography } from "@regrello/ui-core";
import React, { useEffect, useState } from "react";

interface RegrelloRedirectingSpinnerProps {
  /**
   * The maximum time in milliseconds to keep the background blurred while the spinner is showing.
   *
   * @default 5000 // (5 seconds)
   */
  timeoutMillis?: number;

  /**
   * Message to display next to the spinner.
   */
  message: string;
}

/** Draws a full-page centered spinner with the given message, while blurring the background a bit. */
export const RegrelloRedirectingSpinner = React.memo<RegrelloRedirectingSpinnerProps>(
  function RegrelloRedirectingSpinnerFn({ timeoutMillis = 5000, ...props }) {
    const [timeoutExpired, setTimeoutExpired] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setTimeoutExpired(true);
      }, timeoutMillis);
      return () => {
        clearTimeout(timer);
      };
    }, [timeoutMillis]);

    return (
      <div
        className={clsx(
          "absolute flex w-full h-full top-0 left-0 justify-around items-center z-sticky",
          !timeoutExpired && "bg-[#ffffff0a] backdrop-blur-sm",
          timeoutExpired && "pointer-events-none",
        )}
      >
        <div
          className={clsx(
            "flex",
            "max-w-[50%]",
            "max-h-12.5",
            "text-textContrast",
            "bg-primary-solid",
            "rounded",
            "[box-shadow:_0px_1px_12px_rgba(0,0,0,0.25)]", // HACKHACK (clewis): This is an old style that I ported over when switching to UnoCSS.
            "pointer-events-none",
          )}
        >
          <div className="p-3.5 flex gap-2 items-center">
            <RegrelloSpinner {...props} size="small" />
            <RegrelloTypography variant="body" weight="semi-bold">
              {props.message}
            </RegrelloTypography>
          </div>
        </div>
      </div>
    );
  },
);
