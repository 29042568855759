import * as PopoverPrimitive from "@radix-ui/react-popover";
import { clsx } from "@regrello/core-utils";
import React from "react";

/**
 * Wrapper for composing `RegrelloPopover` component.
 * Make sure to include `RegrelloPopoverTrigger`, `RegrelloPopoverContent` and `RegrelloPopoverClose` inside.
 * Interactions will be handled automatically.
 */
const RegrelloPopoverRoot = PopoverPrimitive.Root;

/**
 * This component will be rendered as a trigger to show/hide the `RegrelloPopoverContent`.
 * Ideally you would want to use `asChild` property and then use any component inside to work as your popover trigger.
 * Ex.
 * ```
 *  <RegrelloPopoverTrigger asChild={true}>
      <RegrelloButton>
        This will toggle popover content
      </RegrelloButton>
    </RegrelloPopoverTrigger>
 * ```
 */
const RegrelloPopoverTrigger = PopoverPrimitive.Trigger;

/**
 * Anchor popover based on this component position and dimensions.
 */
const RegrelloPopoverAnchor = PopoverPrimitive.Anchor;

/**
 * This is a composable `close` button to be included inside the `RegrelloPopoverContent`.
 * Wrap it around any copmponent that should `close` the popover on `click`.
 * Interactions will be handled automatically.
 * Ex.
 * ```
 * <RegrelloPopoverContent>
 *   <RegrelloPopoverClose asChild={true}>
 *     <RegrelloButton>
 *       This will close the popover
 *     </RegrelloButton>
 *   </RegrelloPopoverClose>
 * </RegrelloPopoverContent>
 * ```
 */
const RegrelloPopoverClose = PopoverPrimitive.Close;

/**
 * This component should hold you collapsible content. It will be toggled when
 * `RegrelloPopoverTrigger` is clicked on. This component contain styling for the popover overlay,
 * but do not include any content padding, so you can properly compose content with static and scrollable
 * sections inside.
 */
const RegrelloPopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
    /** Weather to disable animations if immediate transition is necessary */
    disableAnimations?: boolean;

    /** Whether to omit the default padding around the popover content. */
    omitDefaultPadding?: boolean;

    /**
     * The stylistic variant of this popover. Use `intro` to render an accented popover with a
     * primary-color background, intended for walking the user through new features in the app.
     */
    variant?: "default" | "intro";
  }
>(
  (
    {
      className,
      align = "center",
      disableAnimations = false,
      omitDefaultPadding = false,
      sideOffset = 4,
      variant,
      ...props
    },
    ref,
  ) => (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        ref={ref}
        align={align}
        className={clsx(
          `
        z-dialog
        w-72
        max-h-[calc(var(--radix-popover-content-available-height)_-_8px)]
        border
        rounded
        bg-background
        text-textDefault
        shadow-md
        outline-none
        `,
          {
            "p-4": !omitDefaultPadding,
            "bg-primary-solid text-textContrast border-none": variant === "intro",
            [`data-[state=open]:animate-in
            data-[state=open]:fade-in-0
            data-[state=open]:zoom-in-95

            data-[state=closed]:animate-out
            data-[state=closed]:fade-out-0
            data-[state=closed]:zoom-out-95

            data-[side=bottom]:slide-in-from-top-2
            data-[side=left]:slide-in-from-right-2
            data-[side=right]:slide-in-from-left-2
            data-[side=top]:slide-in-from-bottom-2`]: !disableAnimations,
          },
          className,
        )}
        sideOffset={sideOffset}
        {...props}
      >
        {props.children}
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  ),
);
RegrelloPopoverContent.displayName = PopoverPrimitive.Content.displayName;

export interface RegrelloPopoverProps extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Root> {
  /** Where along the specified {@link side} of the target to align the menu. */
  align?: PopoverPrimitive.PopoverContentProps["align"];

  /** The element that should open the menu when clicked. */
  children: React.ReactNode;

  /** The content to display in the popover. */
  content: React.ReactNode;

  /** Props to pass to the `content` element. */
  contentProps?: Omit<React.ComponentProps<typeof RegrelloPopoverContent>, "align" | "side"> & {
    "data-testid"?: string;
  };

  /**
   * Whether the popover is disabled and should not open when the user hovers the trigger. The
   * popover will also be automatically disabled if the `content` is `null` or `""`.
   */
  disabled?: boolean;

  /** Callback invoked when the popover opens or closes. */
  onOpenChange?: (nextIsOpen: boolean) => void;

  /** Whether to show a close button in the top-right of the popover. */
  showCloseButton?: boolean;

  /** The side of the target on which to align the menu content. */
  side?: PopoverPrimitive.PopoverContentProps["side"];

  /** Props to pass to the trigger element. */
  triggerProps?: React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger>;

  /**
   * The stylistic variant of this popover. Use `intro` to render an accented popover with a
   * primary-color background, intended for walking the user through new features in the app.
   */
  variant?: "default" | "intro";
}

/**
 * The standard popover component that you should use. Provides convenient, streamlined accessors
 * to standard popover functionality. If you need to go rogue, you can use the other atomic
 * components to build a custom solution.
 */
const RegrelloPopover = React.memo<RegrelloPopoverProps>(
  ({
    align,
    children,
    content,
    contentProps,
    disabled = false,
    onOpenChange,
    showCloseButton = false,
    side,
    triggerProps,
    variant,
    ...props
  }) => {
    return (
      <RegrelloPopoverRoot
        {...props}
        onOpenChange={onOpenChange}
        open={disabled || content == null || content === "" ? false : props.open}
      >
        <RegrelloPopoverTrigger asChild={true} {...triggerProps}>
          {children}
        </RegrelloPopoverTrigger>
        <PopoverPrimitive.Portal>
          <RegrelloPopoverContent
            align={align}
            onTouchMove={(event) => {
              // (dosipiuk): There is a bug currently with radix if popover appears inside dialog, this fixes the interaction
              event.stopPropagation();
            }}
            onWheel={(event) => {
              // (dosipiuk): There is a bug currently with radix if popover appears inside dialog, this fixes the interaction
              event.stopPropagation();
            }}
            side={side}
            variant={variant}
            {...contentProps}
          >
            {showCloseButton && <RegrelloPopoverClose />}
            {content}
            {variant === "intro" && <PopoverPrimitive.Arrow className="fill-primary-solid" />}
          </RegrelloPopoverContent>
        </PopoverPrimitive.Portal>
      </RegrelloPopoverRoot>
    );
  },
);
RegrelloPopover.displayName = "RegrelloPopover";

export {
  RegrelloPopover,
  RegrelloPopoverAnchor,
  RegrelloPopoverClose,
  RegrelloPopoverContent,
  RegrelloPopoverRoot,
  RegrelloPopoverTrigger,
};
