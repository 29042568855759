// (dosipiuk): Disabled due to mixed JSX and const exports. Strings are not updated frequently so
// we can live with it for now.
/* eslint-disable react-refresh/only-export-components */
// (clewis): Strings from the whole app. Eventually, we should port these to i18n.
//
// The strategy with naming should be to make the string as general as possible by default (e.g.
// const Home = "Home") so that it can be discovered and reused easily, but to segment into multiple
// namespaced constants if you need need to change the string differently in the various places it's
// used (e.g., const PageTitleHome = "Home", const TabHome = "My home").
//
// If you need to import a string into a file that already includes an other variable having the
// same name, just add a '-String' suffix to the imported string name: import { Foo as FooString }.
//

import React, { ReactNode } from "react";

export const Access = "Access";
export const ActionItem = "Task";
export const ActionItems = "Tasks";
export const ActionItemComplete = (name: string) => `Task ${name} is completed`;
export const ActionItemDeleted = (name: string) => `Task ${name} deleted`;
export const ActionItemProblemResolved = (name: string) => `Task ${name} problem resolved`;
export const ActionItemProblemReported = (name: string) => `Task ${name} problem reported`;
export const ActionItemSubmittedForApproval = (name: string) => `Task ${name} submitted for approval`;
export const Activity = "Activity";
export const AddActionItem = "Add task";
export const AddAdmin = "Add admin";
export const AddAdminSubtitle = "You will be added as an admin automatically.";
export const AddApproval = "Add approval";
export const Added = "Added";
export const AddedBy = "Added by";
export const AddedPrefix = (dateString: string) => `Added: ${dateString}`;
export const AddHomepageCategory = "Add tag category";
export const AddLink = "Add link";
export const AddTag = "Add tag";
export const AddTagCategory = "Add tag category";
export const AddOption = "Add option";
export const AddOptionToRegrello = ({ name }: { name: JSX.Element }) => <span>Add &apos;{name}&apos; to Regrello</span>;
export const AddOptionToRegrelloNotAllowed = ({ name }: { name: JSX.Element }) => (
  <span>Please contact an admin if you would like to add {name}</span>
);
export const AllPeopleAreInactive = "All people assigned to the task are inactive";
export const AlphabetizeOptions = "Alphabetize options";
export const AlphabetizeOptionsTooltip = "Options are already alphabetized";

export const AddIndustryOption = ({ name }: { name: JSX.Element }) => <span>Use &apos;{name}&apos; for industry</span>;
export const AddIndustryOptionEmptySearch = "Select or type an industry";
export const Advanced = "Advanced";
export const AdvancedFormSectionDescription = "Configure advanced task functionality such as splitting.";
export const AdvancedFormSectionDescriptionV2 =
  "Configure advanced task functionality such as splitting or save to PDF.";
export const AndLower = "and";
export const ApprovalActionItemDeleted = (name: string) => `Approval for task ${name} deleted`;
export const ButLower = "but";
export const CreateTagToRegrelloEmpty = "Add tag";
export const CreateTagToRegrelloNotAllowedEmpty = "Please contact an admin if you would like to add tags";
export const CreateYourPassword = "Create your password";
export const AddHomepageCategoryToRegrelloNotAllowedEmpty =
  "Please contact an admin if you would like to add a new homepage category";
export const AddPeople = "Add people";
export const AddPeopleAndTeams = "Add people and teams";
export const AddPersonOrTeam = "Add person or team";
export const Admin = "Admin";
export const AddWorkspace = "Add workspace";
export const Alerts = "Alerts";
export const All = "All";
export const AllBlueprints = "All blueprints";
export const AllowMultiple = "Allow adding multiple people or teams";
export const AllTasks = "All tasks";
export const AllActionItems = "All tasks";
export const AndLowercase = "and";
export const Apply = "Apply";
export const ApplyingEllipses = "Applying...";
export const Approval = "Approval";
export const Approvals = "Approvals";
export const ApprovalPrefixedName = (name: string) => `Approval: ${name}`;
export const ApprovalTasks = "Approval tasks";
export const ArrayToCommaSeparatedList = (strings: string[], lastStringSeparator: "and" | "or") => {
  const len = strings.length;
  const stringsLocal = [...strings];
  const maybeLastStr = stringsLocal.pop();
  const joinedStrs = stringsLocal.join(", ");
  return `${joinedStrs}${len > 2 ? `, ${lastStringSeparator} ` : len > 1 ? ` ${lastStringSeparator} ` : ""}${maybeLastStr}`;
};
export const Assign = "Assign";
export const Assigned = "assigned";
export const AssignedBy = "Assigned by";
export const AssignedByMe = "By me";
export const AssignedDirectlyToMe = "Directly to me";
export const AssignedTo = "Assigned to";
export const AssignedToMe = "To me";
export const AssignedToTeam = "To team ";
export const AssignedStatusToPrefix = "Assigned: ";
export const AssignTask = "Assign task";
export const AsteriskSymbol = "*";
export const Attach = "Attach";
export const Attachment = "Attachment";
export const AuthenticationErrorTooManyAttempts =
  "That’s one too many failed attempts. Your account has been locked for your safety. Check your inbox for an email with instructions on how to unlock your account.";
export const Author = "Author";
export const Beta = "Beta";
export const Blueprints = "Blueprints";
export const BlueprintUsed = "Blueprint used";
export const By = "By";
export const OwnedByName = (name: string) => `Owned by ${name}`;
export const Ccd = "Cc'd"; // This is the only casing to display Cc'd
export const Checkbox = "Checkbox";
export const CheckYourEmail = "Check your email";
export const Columns = "Columns";
export const Company = "Company";
export const CompanyTemplateAlreadyExists = "Blueprint already exists";
export const CompanyTemplateAlreadyExistsDialogText = (
  templateName: string,
  templateLink: JSX.Element,
  tenantDisplayName: string,
) => (
  <span>
    A blueprint named {templateName} already exists in {tenantDisplayName}&apos;s blueprints. Please rename the
    blueprint before sharing. Existing blueprint: {templateLink}
  </span>
);
export const CompanyTemplateAlreadyExistsDialogTextDefault = (templateName: string, templateLink: JSX.Element) => (
  <span>
    A blueprint with the name {templateName} already exists in Company Blueprints. Please rename the blueprint before
    sharing. Existing blueprint: {templateLink}
  </span>
);
export const PublishedBlueprintAlreadyExistsDialogTextDefault = (templateName: string, templateLink: JSX.Element) => (
  <span>
    A blueprint with the name {templateName} already exists in Published Blueprints. Please rename the blueprint before
    sharing. Existing blueprint: {templateLink}
  </span>
);
export const Configured = "Configured";
export const Cancel = "Cancel";
export const CanInvite = "Can invite";
export const Checked = "Checked";
export const CheckYourEmailForInviteInstructionsListItem1 = () => (
  <span>There should be a new invite from us in your inbox.</span>
);
export const CheckYourEmailForInviteInstructionsListItem2 = () => (
  <span>Don’t see it there? Check your Junk folder.</span>
);

export const CheckYourEmailForInviteInstructionsListItem3 = () => (
  <span>
    Click the <strong>Accept invite</strong> link.
  </span>
);
export const CheckYourEmailForInviteInstructionsListItem4 = () => <span>Create your password.</span>;
export const CheckYourEmailForInviteInstructionsListItem5 = () => (
  <span>That’s it. You and your customer can now collaborate with Regrello.</span>
);
export const ClearAll = "Clear all";
export const ClearSelection = "Clear selection";
export const Close = "Close";
export const Confirm = "Confirm";
export const ConnectionFailure = "Connection failure";
export const Continue = "Continue";
export const ContinueWithGoogle = "Continue with Google";
export const Create = "Create";
export const Created = "Created";
export const CreatedBy = "Created by";
export const CreateNewFieldEmpty = "Add field";
export const CreateNewFieldEmptyV2 = "New field";
export const CreateNewFieldNotAllowed = "Please contact an admin if you would like to add a new field";
export const PermissionsV2CreateNewFieldNotAllowed = "You require at least 'Creator' permissions to add a new field";
export const CreateTask = "Create task";
export const CreatePassword = "Create password";
export const CreateWorkflowFromCsv = "Create workflows from CSV file";
export const CsvFile = "CSV file";
export const CsvFieldNameWithOptionalPrefix = (fieldName: string) => `(Optional) ${fieldName}`;
export const Currency = "Currency";
export const CurrencySymbol = "Currency symbol";
export const Current = "Current";
export const CompletedTasks = "Completed tasks";
export const ConfirmPassword = "Confirm password";
export const CopyrightTitleCased = "©2023 Regrello Group";
export const ContactSupportAt = "Having trouble? Contact us at ";
export const ContactSupportForAssistance = (link: JSX.Element) => <>Please contact {link} for assistance</>;
export const CredentialErrorTitle = "There’s a problem with your credentials";
export const Customer = "Customer";
export const Creator = "Creator";
export const DateMessage = "Date";
export const DateAdded = "Date added";
export const DateUnitDays = "Day(s)";
export const DateUnitMonths = "Month(s)";
export const DateUnitWeeks = "Week(s)";
export const Default = "Default";
export const Delayed = "Delayed";
export const Delete = "Delete";
export const Deleted = "Deleted";
export const DeleteFieldHelperText =
  "This field has been deleted from your workspace. We recommend using a different field instead.";
export const DeleteDocumentContent = "Deleting this document will permanently remove it from Regrello.";
export const DeleteDocumentNotAllowedCompleted = "Documents cannot be deleted from a completed task";
export const DeleteDocumentNotAllowedPermission = "You cannot delete documents uploaded by other people";
export const DeleteUserConfirmation = (name: string) => `${name} will be removed from associated workflows and tasks.`;
export const DeleteScimServiceAccountUserConfirmation =
  "This user is a service account configured for managing SCIM integrations. Removing it may cause the user directory sync to fail.";
export const DeletingWorkflows = (n: number) => (
  <span>
    Deleting <strong>{n}</strong> {n === 1 ? "workflow" : "workflows"}...
  </span>
);
export const DeletionCancelled = "Deletion cancelled.";
export const Description = "Description";
export const Details = "Details";
export const DeveloperPageTitle = "Developer";
export const DesignSystemDocs = "Design system docs";
export const Document = "Document";
export const DocumentDeleted = (name: string) => `Document ${name} deleted`;
export const Documents = "Documents";
export const Done = "Done";
export const DownloadSubmissionPDF = "Download submission PDF";
export const Examples = "Examples";
export const FileNotFound = "File could not be found. Please contact us if you continue to have trouble.";
export const AssignedTasks = "Assigned tasks";
export const CcdTasks = "Cc'd tasks";
export const OptionalFieldDisabledReason = "This role was optional and has not been assigned.";
export const Options = "Options";
export const DocumentNames = "Document names";
export const DueDate = "Due date";
export const Duplicate = "Duplicate";
export const DuplicateTask = "Duplicate task";
export const DuplicateApprovalTask = "Duplicate approval task";
export const DuplicateTaskName = (name: string) => `${name} (Duplicate)`;
export const Duration = "Duration";
export const DurationInDays = (number: number) => `${number} ${number === 1 ? "day" : "days"}`;
export const DurationInWeeks = (number: number) => `${number} ${number === 1 ? "week" : "weeks"}`;
export const EditField = "Edit field";
export const EditTag = "Edit tag";
export const EditUser = "Edit person";
export const Email = "Email";
export const Emails = "Emails";
export const EmailVerifying = "Almost there! We are verifying your email address.";
export const Empty = "Empty";
export const EnableLoginSpoofing = "Enable login spoofing";
export const EndDateTooltipText = "The end date of a workflow is estimated if it is in progress.";
export const ActualEndDateTooltipText = "The date the workflow finished on, if applicable.";
export const EstimatedEndDateTooltipText = "Estimated end date will adjust as the workflow runs.";
export const PlannedEndDateTooltipText = "The original end date calculated when the workflow started.";
export const ConditionalPlannedEndDateTooltipText =
  "The end date calculated when the workflow started (and recalculated when stages are skipped).";
export const EndedOnDate = (date: string) => (
  <>
    Ended on <strong>{date}</strong>
  </>
);
export const ErrorMessage = "Error";
export const ErrorsFound = "Errors found";
export const ErrorOnLoadDescription =
  "Something went wrong while loading Regrello. Please contact us if you continue to have trouble.";
export const ErrorNetworkFailedToFetch = "The internet connection was lost. Please check the connection and refresh.";
export const ErrorNoWorkspaceAccess = "You do not have access to this workspace.";
export const ErrorNoAccess =
  "There is a problem with your access. Please contact your organization's administrators for assistance.";
export const ErrorNoAccessTenantIsArchived =
  "The Regrello workspace you are trying to access is currently inaccessible, possibly for maintenance. If the issue persists longer than an hour, please reach out to your organization's administrator.";
export const ErrorFindingTenantByUuidDescription =
  "This task does not exist, or you do not have access to the workspace it is in. Please contact us if you continue to have trouble.";
export const ErrorLoadingTaskByUuid =
  "This task no longer exists or you do not have access to it. Please contact us if you continue to have trouble.";
export const ErrorLoadingTaskOnRegrelloLite =
  "You no longer have access to this task. Please contact us if you believe this is incorrect.";
export const Problem = "Problem";
export const ProblemReported = "Problem reported";
export const Problems = "Problems";
export const Export = "Export";
export const ExportCsvTasksTooltip = "Export workflows, their tasks, and field values";
export const ExportCsvTooltip = "Export the current saved view";
export const FieldOnlyAcceptOneDocument = (name: string | undefined) =>
  `${name ?? "This field"} only accepts one document`;
export const FieldOnlyAcceptNumberOfDocuments = (name: string | undefined, maxCount: number) =>
  `${name ?? "This field"} only accepts ${maxCount} document${maxCount !== 1 ? "s" : ""}`;
export const FieldCreated = (name: string) => `Field ${name} created`;
export const FieldDeleted = (name: string) => `Field ${name} deleted`;
export const FieldInstanceStageAndActionItemSource = (stageName: string, taskName: string) =>
  `stage: ${stageName} > task: ${taskName}`;
export const FieldsUpdated = (count: number) => (
  <span>
    Updated <strong>{count}</strong> field{count === 1 ? "" : "s"}.
  </span>
);
export const FieldsSelected = (count: number) => (
  <>
    {count} field{count === 1 ? "" : "s"} selected
  </>
);
export const FieldDeleteDialogTextStartConditions = (name: string) => (
  <span>
    Field <strong>{name}</strong> might be used in tasks, workflows, or stage starting conditions.
  </span>
);
export const CopiedToClipboard = (what: string) => `The ${what} has been copied to the clipboard`;

export const EnterText = "Enter text here";
export const EnterTenantName = "Name your workspace";
export const External = "External";
export const FieldUpdated = (name: string) => `Field ${name} updated`;
export const File = "File";
export const Files = "Files";
export const FileName = "File name";
export const Filter = "Filter";
export const Filters = "Filters";
export const FromUserStartingTheWorkflow = "From the person starting the workflow";
export const ForgotPassword = "Forgot password";
export const ForgotYourPassword = "Forgot your password?";
export const From = "From";
export const FullName = "Full name";
export const GeneratingPdf = "Generating PDF...";
export const GoToMessage = "Go to message";
export const GrantAccess = "Grant access";
export const HelperTextAttachOneOrMoreDocuments = "Attach 1 or more documents.";
export const HelperTextSelectOneOrMore = "Select 1 or more.";
export const HelperTextSelectOneOrMoreAssignees = "Select 1 or more assignees.";
export const HelperTextSelectOneOrMoreCcs = "Select 1 or more Cc's.";
export const HelperTextSelectOneOrMoreTags = "Select 1 or more tags.";
export const Home = "Home";
export const HomepageCategory = "Tag category";
export const Hide = "Hide";
export const HideUnselected = "Hide unselected";
export const Highlight = "Highlight";
export const Hyphen = "-";
export const Inactive = "Inactive";
export const Individuals = "People";
export const Integer = "Integer";
export const IntegrationsDownloadHealthLog = "Download health log";
export const Internal = "Internal";
export const InvalidJson = "Invalid JSON";
export const Invite = "Invite";
export const InviteResent = "Invite resent";
export const InvitePeople = "Invite people";
export const InvitePerson = "Invite person";
export const LastActive = "Last active";
export const LastModified = "Last modified";
export const LastUpdated = "Last updated";
export const LetsGetStarted = "Let's get started";
export const Link = "Link";
export const LatestUpdate = "Latest update";
export const LoadingEllipses = "Loading...";
export const LoadingResultsEllipses = "Loading results...";
export const LinkCopied = CopiedToClipboard("link");
export const CopyUnsupported = "Your browser does not support copying to clipboard";
export const LogOut = "Sign out";
export const MarkAsRead = "Mark as read";
export const Members = "Members";
export const Message = "Message";
export const Messages = "Messages";
export const Mine = "Mine";
export const ModifiedBy = "Modified by";
export const NMore = (n: number) => `${n} more`;
export const MultiSelect = "Multi-select";
export const Muted = "This user has muted notification emails.";
export const MyActionItems = "My tasks";
export const New = "New";
export const NonAdminExternal = "Non-Admin (External)";
export const NonAdminInternal = "Non-Admin (Internal)";
export const Name = "Name";
export const NewUsers = "New Users";
export const NewSuppliers = "New Suppliers";
export const No = "No";
export const None = "None";
export const Notifications = "Notifications";
export const NotStartedDelayed = "Not Started (Delayed)";
export const NumberMessage = "Number";
export const Occurrences = "Occurrences";
export const Off = "Off";
export const Okay = "Okay";
export const On = "On";
export const Only = "Only";
export const OnTrack = "On track";
export const OnTrackTasks = "On track tasks";
export const Open = "Open";
export const OpenActionItems = "Open tasks";
export const Or = "Or";
export const Overdue = "Overdue";
export const OverdueTasks = "Overdue tasks";
export const OwnedWorkflows = "Owned workflows";
export const Owner = "Owner";
export const OwnerPrefix = (ownerName: string) => `Owner: ${ownerName}`;
export const ProblemTasks = "Problem tasks";
export const RoleDeleted = (name: string) => `Role ${name} deleted`;
export const StatusPrefix = (status: string) => `Status: ${status}`;
export const TaskPrefix = (taskName: string) => `Task: ${taskName}`;
export const TypePrefix = (type: string) => `Type: ${type}`;
export const PageNotFound = "Page not found";
export const PageNotFoundInstruction =
  "The requested page does not exist. If this is not what you expected to see, please report a bug to our support email.";
export const PageNotFoundRedirectToastContentForAuthenticatedUser = "You are already signed in";
export const ParentTag = "Parent tag";
export const Party = "Party";
export const Password = "Password";
export const PasswordResetEmailSent = "Password reset email sent";
export const PasswordRuleHasLowerCaseLetter = "Lower case letters (a-z)";
export const PasswordRuleHasNumber = "Numbers (1-9)";
export const PasswordRuleHasSpecialCharacter = "Special characters (!@#$%^&*)";
export const PasswordRuleHasUpperCaseLetter = "Upper case letters (A-Z)";
export const PasswordRuleHasValidCharacterCombination = "Contains at least 3 of the following 4 types of characters";
export const PasswordRuleMinLength = "At least 8 characters in length";
export const PasswordRuleNoConsecutiveCharacter = "No more than 2 identical characters in a row (aaa is not allowed)";
export const Paste = "paste";
export const PausedOnDate = (date: string) => (
  <>
    Paused on <strong>{date}</strong>
  </>
);
export const Pending = "Pending";
export const People = "People";
export const PeopleAssigned = "People assigned";
export const PermissionLevel = "Permission level";
export const PleaseContactLinkForAssistance = (link: JSX.Element) => <>Please contact {link} for assistance.</>;
export const PleaseTryAgain = "Please try again.";
export const PlusN = (n: number) => `+${n}`;
export const PrivacyPolicyTitleCased = "Privacy Policy";
export const Provide = "Provide";
export const Preview = "Preview";
export const Refresh = "Refresh";
export const RegrelloIsInviteOnly = "Regrello is invite only";
export const Remove = "Remove";
export const Removed = "Removed";
export const RenameWorkspace = "Rename workspace";
export const RenameWorkspaceHelper = "Enter a new name for the current workspace:";
export const RestartConfirmationDialogTitle = "Before restarting";
export const RestartConfirmationDialogContentParagraphOne =
  "Depending on the conditions that have been set, restarting this task could result in restarting and resetting all the tasks that follow it.";
export const RestartConfirmationDialogContentParagraphTwo =
  "No documents or data are deleted when a task is restarted or reset, but the assignees of those tasks will have to “complete” them again.";
export const NResults = (n: number) => `${n} Results`;
export const ReportProblem = "Report problem";
export const ReportProblemOn = "Report problem on";
export const ReportProblemRejectActionTooltipText = "Rejecting this task will report a problem on the current task";
export const Request = "Request";
export const Required = "Required";
export const RestApi = "Rest API";
export const Restored = "Restored";
export const ResendEmailInvite = "Resend email invite";
export const ResendEmailInviteSuccess = "Email invite sent.";
export const ResendEmailInviteText = (
  <span>
    You must click/tap <strong>Accept invite</strong> in your email invite in order to set your password.
  </span>
);
export const ResendEmailInviteTextSupplierMode = (
  <span>
    Been assigned tasks in Regrello? If you would like to sign in to complete your work, please click send email invite.
    You must click/tap <strong>Accept invite</strong> in your email invite in order to create your account password.
  </span>
);
export const WorkflowPrefix = (workflowName: string) => `Workflow: ${workflowName}`;

export const ReceivedAnInvite = "Received an invite?";
export const ReceivedAnInviteInstruction =
  "Enter the email address we sent your invite to and we’ll resend your invite.";
export const ReceivedAnInviteSentInstructionBulletOne =
  "If this email address is registered with Regrello, we have sent an email with further instructions.";
export const ReceivedAnInviteSentInstructionBulletTwo = () => (
  <span>
    Click <strong>Accept invite</strong>
  </span>
);
export const RedirectingToCompanySSO = "Redirecting to your company's sign in page";
export const RequiresAction = "Requires action";
export const RequiresActionBy = (date: React.ReactChild) => <span>Requires action by {date}</span>;
export const RequiresActionByString = (date: string) => `Requires action by ${date}`;
export const Rerun = "Rerun";
export const ResendInvite = "Resend invite";
export const Reset = "Reset";
export const Resolve = "Resolve";
export const ResolveProblem = "Resolve problem";
export const ReturnToHome = "Back to home";
export const ReturnToLogin = "Back to sign in";
export const ReturnToSupplierLogin = "Back to supplier sign in";
export const Roles = "Roles";
export const Rows = "Rows";
export const RowsAdded = "Rows added";
export const RowsDeleted = "Rows deleted";
export const RowsUpdated = "Rows updated";
export const Save = "Save";
export const SaveAsEllipses = "Save as...";
export const SaveSubmittedDataAsPdf = "Save a copy of submitted data as a PDF";
export const SaveSubmittedDataAsPdfDisabledTooltip = "Must share or request information on this task first.";
export const SavedViews = "Saved views";
export const ScrollToTask = "Scroll to task";
export const Search = "Search";
export const SearchPlaceholder = "Search Regrello";
export const SearchPlaceholderFieldsTable = "Search fields by name";
export const SearchPlaceholderIntegrationsTable = "Search integrations by name";
export const SearchPlaceholderPeopleTable = "Search people by name or email";
export const SearchPlaceholderPeopleTableV2 = "Search people by name, email, or role";
export const SearchPlaceholderRolesTable = "Search roles by name";
export const SearchPlaceholderTeamsTable = "Search teams by name";
export const Section = "Section";
export const Sections = "Sections";
export const Select = "Select";
export const Selected = "selected";
export const SelectApprovers = "Select approvers";
export const SelectFrom = (isMultiselectEnabled: boolean, suffix?: string) =>
  `Select ${isMultiselectEnabled ? "one or more" : "one"} from ${suffix}`;
export const Settings = "Settings";
export const Share = "Share";
export const ShareInformation = "Share information";
export const Show = "Show";
export const ShowCompleted = "Show completed";
export const ShowPassword = "Show password";
export const ShowMe = "Show me";
export const ShowMore = "Show more";
export const ShowMoreResults = "Show more results";
export const ShowLess = "Show less";
export const ShowUnselected = "Show unselected";
export const SignatureConsent =
  "By checking this box, I agree that this electronic signature will be as valid as handwritten signatures to the extent allowed by local law.";
export const SignatureLegalHint = "This signature may not be legally binding depending on your jurisdiction.";
export const SignIn = "Sign in";
export const SignInToRegrello = "Sign in to Regrello";
export const SignInWithSSO = "Sign in with SSO";
export const SignInWithSSOToGetStarted = "Sign in with SSO to get started.";
export const SignUp = "Sign up";
export const SnoozeDay = "Day";
export const SnoozeWeek = "Week";
export const Snowflake = "Snowflake";
export const Source = "Source";
export const Stage = "Stage";
export const StageName = "Stage name";
export const StageToRestart = "Stage to restart: ";
export const StageReevaluateDialogText =
  "Stages leveraging the updated fields in their starting conditions will be reevaluated and may start.";
export const StageReevaluateDialogTextInPausedWorkflow =
  "Stages leveraging the updated fields in their starting conditions will be reevaluated and may start when the workflow resumes.";
export const Standard = "Standard";
export const StandardTasks = "Standard tasks";
export const StarredOn = "Starred on";
export const Status = "Status";
export const Step = "Step";
export const StepOf = (current: number, total: number) => `Step ${current} of ${total}`;
export const Subject = "Subject";
export const SubmittedBy = "Submitted by";
export const SyncedOn = "Synced on";
export const SyncId = "Sync ID";
export const SyncPageTitle = (objectName: string) => `Sync log - ${objectName || "unknown"}`;
export const Tag = "Tag";
export const Tags = "Tags";
export const TagCreated = (tagName: string) => `Tag ${tagName} created`;
export const TagDeleted = (tagName: string) => `Tag ${tagName} deleted`;
export const TagUpdated = (tagName: string) => `Tag ${tagName} updated`;
export const Task = "Task";
export const Tasks = "Tasks";
export const TaskDetail = "Task detail";
export const TaskDetails = "Task details";
export const TaskToRestart = "Task to restart: ";
export const TeamAdmin = "Team admin";
export const TeamAdminHelperText =
  "Team admins are able to add and remove members of the team. They have access to all workflows and tasks owned by any team member.";
export const TeamAdminDeprecationText =
  "Only workspace admins can edit teams. Please contact an admin for updating a team.";
export const TeamAdminPermissionsText =
  "Can view workflows and tasks owned by or assigned to the team or any team member";
export const TeamAdmins = "Team admins";
export const Team = "Team";
export const TeamMembers = "Team members";
export const Teams = "Teams";
export const TeamDialogHelperText = "Create a team to collaborate on tasks and workflows.";
export const TeamDialogHelperEmailText = "Members of the team must all be from the same email domain.";
export const TeamEmail = "Team email";
export const TeamEmailHelperText =
  "If your team uses a group email for communication, you can provide it instead of adding members individually. Notifications will only go out to members of this team email.";
export const TeamEmailPlaceholder = "teamalias@yourcompany.com";
export const TeamMembersPlaceholder = "Choose members";
export const TeamName = "Team name";
export const Tenants = "Workspaces";
export const Template = "Blueprint";
export const Text = "Text";
export const Theme = "Theme";
export const ThemeLight = "Light";
export const ThemeDark = "Dark (beta)";
export const ThemeSystem = "System (beta)";
export const TheresAProblemWithYourCredentials = "There's a problem with your credentials.";
export const TryAgain = "Try again";
export const Type = "Type";
export const Unchecked = "Unchecked";
export const Unknown = "Unknown";
export const UnknownEmail = "Unknown email";
export const UnknownErrorOccured = "Unknown error occured";
export const Unread = "Unread";
export const UnreadMessagesCount = (count: number) => `${count} unread ${count === 1 ? "message" : "messages"}`;
export const Update = "Update";
export const Updated = "Updated";
export const UpdatedPrefix = (dateString: string) => `Updated: ${dateString}`;
export const Updates = "Updates";
export const Upload = "Upload";
export const UploadDate = "Upload date";
export const Users = "People";
export const UserSyncLog = "User sync log";
export const View = "View";
export const ViewSyncLog = "View sync log";
export const ViewData = "View data";
export const ViewDraft = "View draft";
export const ViewAllSelectOptions = "View all select options";
export const VerificationRequiredInstructionAuth0 =
  "Enter the email address associated with your Regrello account and we’ll send you a link to reset your password.";
export const VerificationRequiredSentInstruction =
  "You’ll need to verifiy your identity before changing your password. If you signed up with email and password, you should receive an email.";
export const VerificationSentInstruction =
  "Looks like the link has expired, we've sent you an email with a new verification link.";
export const VerificationRequiredSentInstructionBulletOne = () => (
  <span>
    Check your inbox for the email we just sent with the subject line <strong>Password reset</strong>
  </span>
);
export const VerificationRequiredSentInstructionBulletTwo = () => (
  <span>
    Click <strong>Reset password</strong>
  </span>
);
export const VerifyIdentityInstruction =
  "First, we need to verify your identity. Enter the email address used to send you a task.";
export const WhatToDoNext = "What to do next:";

export const SupplierSignIn = "Supplier sign in";
export const Verify = "Verify";
export const WelcomeToRegrello = "Welcome to Regrello";
export const WorkspacePermission = "Workspace permission";
export const More = "More";
export const Yes = "Yes";

// Task status
export const Acknowledge = "Acknowledge";
export const Acknowledged = "Acknowledged";
export const Adjusted = "Adjusted";
export const Started = "Started";
export const StartedOnDate = (formattedDate: JSX.Element) => <span>Started {formattedDate}</span>;
export const Submit = "Submit";
export const Submitted = "Submitted";
export const Approve = "Approve";
export const Approved = "Approved";
export const Approvers = "Approvers";
export const AtDate = (date: string) => `at ${date}`;
export const OnDate = (date: string) => `on ${date}`;
export const Complete = "Complete";
export const Completed = "Completed";
export const CompletedLate = "Completed late";
export const CompletedOnDate = (formattedDate: JSX.Element) => <span>Completed {formattedDate}</span>;
export const Ended = "Ended";
export const PendingAcknowledgement = "Pending acknowledgement";
export const PendingApproval = "Pending approval";
export const PendingResolution = "Pending resolution";
export const PendingSubmission = "Pending submission";
export const Planned = "Planned";
export const Reopen = "Reopen";
export const Restart = "Restart";
export const Rejected = "Rejected";
export const RejectedTooltipText = "This task was last rejected.";

// Stage status
export const Skipped = "Skipped";

// Task dialog
export const Action = "Action";
export const ActionAdded = (link: JSX.Element) => <span>Task {link} added</span>;
export const ActionUpdated = (link: JSX.Element) => <span>Task {link} updated</span>;
export const Add = "Add";
export const AddCc = "Add Cc";
export const ApprovalFormPlaceholderMessage = "Send a message when approving or rejecting (optional).";
export const ApprovalsStatusTooltipText = "Approvals are not allowed to be manually reopened.";
export const AttachFromYourComputer = "Attach from your computer";
export const Cc = "Cc";
export const DropFilesHere = "Drop files here";
export const PasteLink = "Paste a link to a document";

// Nonverified Page
export const NonverifiedHeading = "Almost there!";
export const NonverifiedContent = "Please contact your admin to gain access to Regrello.";

// User access levels
export const UserAccessLevelExternal = "Non-Admin (External)";
export const UserAccessLevelInternal = "Non-Admin (Internal)";

// Tooltip
export const TooltipUnknownUser = "Added via task but not a member of this workspace";

// Search
export const Contents = "Contents";
export const NoResults = "No results";
export const NoResultsFor = (str: string) => `No results found for "${str}"`;
export const RefineYourSearchMessage = "Please refine your search if you did not find what you were looking for.";
export const OpenInNewTab = "Open in new tab";

// Detail View
export const AddedMessage = "added a message";
export const GetLink = "Get link";
export const DeleteActionItem = "Delete task";
export const FailedToLoadTitle = "Failed to load";
export const FailedToLoadWorkflow = "Error loading workflow";
export const FailedToLoadWorkflowNoAccess = "You do not have access to this workflow";
export const FailedToLoadWorkflowNotFound = "Workflow not found";
export const FailedToLoadWorkflowTemplate = "Failed to load blueprint";
export const SuggestionVerifyInCorrectWorkspace = "Please verify that you are in the correct workspace.";
export const FailedToLoadDescription = "Please try again, or contact us if you continue to see this error.";
export const NoAdditionalInformation = "No additional information";
export const SendAndComplete = "Send & Complete";
export const SendAndReportProblem = "Send & Report problem";
export const SendAndResolveProblem = "Send & Resolve problem";
export const TaskStatusChipReasonExceptionDueToRejection = "Due to rejection";
export const TaskStatusChipReasonExceptionDueToRejectionTooltipText =
  "A problem was reported on this task because it was rejected.";
export const TaskStatusChipReasonResetDueToRejection = "Reset due to rejection";
export const TaskStatusChipReasonResetDueToRejectionTooltipText =
  "This task is no longer active because another task was rejected and reset it.";
export const TaskStatusChipReasonRestartedDueToRejection = "Restarted due to rejection";
export const TaskStatusChipReasonRestartedDueToRejectionTooltipText =
  "This task restarted because another task was rejected.";

// Problems
export const ReportProblemDialogDescription =
  "Reporting a problem will notify this task's assignees and prevent it from being completed until the problem is resolved. Please explain the reason for this problem here:";

// Message threads
export const AuditHistoryAutomationRequestFormData = "Request form data:";
export const AuditHistoryAutomationRequestJsonData = "Request JSON data:";
export const AuditHistoryAutomationRequestHeaders = "Request headers:";
export const AuditHistoryAutomationRequestMethod = "Request method:";
export const AuditHistoryAutomationRequestUrl = "Request url:";
export const AuditHistoryAutomationResponseBody = "Response body:";
export const AuditHistoryAutomationResponseItemTitle = (n: number) => `Response #${n}:`;
export const AuditHistoryAutomationResponseStatus = "Response status:";
export const AuditHistoryAutomationSharepointFolderNotFound = "Folder not found";
export const AuditHistoryAutomationLinkCreationFailed = "Failed to create shareable link";
export const AuditHistoryCouldNotBeSaved = "could not be saved";
export const AuditHistoryApprovalSubmission = (partyName: ReactNode) => (
  <>{partyName} submitted this task for approval</>
);
export const AuditHistoryFieldChanged = (partyName: ReactNode, fieldName: ReactNode) => (
  <>
    {partyName} changed the <strong>{fieldName}</strong>
  </>
);
export const AuditHistoryFieldChangedNoBold = (partyName: ReactNode, fieldName: ReactNode) => (
  <>
    {partyName} changed the {fieldName}
  </>
);
export const AuditHistoryDueDateAdjustedAhead = (
  <>Due date was pulled in because the workflow was running ahead of schedule.</>
);
export const AuditHistoryDueDateAdjustedBehind = (
  <span>Due date was pushed out because the workflow was running behind schedule.</span>
);
export const AuditHistoryCustomFieldChecked = (partyName: ReactNode, fieldName: ReactNode) => (
  <>
    {partyName} checked <strong>{fieldName}</strong>
  </>
);
export const AuditHistoryCustomFieldUnchecked = (partyName: ReactNode, fieldName: ReactNode) => (
  <>
    {partyName} unchecked <strong>{fieldName}</strong>
  </>
);
export const AuditHistoryFromDateToDate = (dateFrom: ReactNode, dateTo: ReactNode) => (
  <span>
    From <strong>{dateFrom}</strong> to <strong>{dateTo}</strong>
  </span>
);
export const AuditHistoryFromDateToDateTimezone = (timezone: ReactNode) => (
  <span>Assignees determined to be in {timezone}</span>
);
export const AuditHistoryFromDateToDateNoBold = (dateFrom: ReactNode, dateTo: ReactNode) => (
  <span>
    From {dateFrom} to {dateTo}
  </span>
);
export const AuditHistoryPlannedStartDate = (plannedStartDate: ReactNode) => (
  <>Planned start date: {plannedStartDate}</>
);
export const AuditHistoryPlannedDueDate = (plannedDueDate: ReactNode) => <>Planned due date: {plannedDueDate}</>;

export const AuditHistoryDelegation = (partyName: ReactNode) => <>{partyName} delegated this task</>;
export const AuditHistoryDelegationIsOutOfOffice = (partyName: ReactNode) => <>{partyName} is out of office</>;
export const AuditHistoryDelegationStarting = (dateFrom: ReactNode) => (
  <>
    Starting <strong>{dateFrom}</strong>
  </>
);
export const AuditHistoryStatusChange = (partyName: ReactNode) => <>{partyName} changed task status</>;
export const AuditHistoryStatusChangeReasonCondition = "Due to previous task(s) updating";
export const AuditHistoryStatusChangeReasonRejection = "Due to rejection";
export const AuditHistoryStatusChangeAcknowledgedV2 = (partyName: ReactNode) => <>{partyName} acknowledged this task</>;
export const AuditHistoryStatusChangeApprovedV2 = (partyName: ReactNode) => <>Task approved by {partyName}</>;
export const AuditHistoryStatusChangeCompletedV2 = (partyName: ReactNode) => <>{partyName} completed this task</>;
export const AuditHistoryStatusChangeLinkedWorkflowCompleted = <>Linked workflow completed</>;
export const AuditHistoryStatusChangeStartedV2 = <>Task started</>;
export const AuditHistoryStatusChangeLinkedWorkflowStarted = <>Linked workflow started</>;
export const AuditHistoryStatusChangeRejectedV2 = (partyName: ReactNode) => <>Task rejected by {partyName}</>;
export const AuditHistoryStatusChangeRestartedV2 = <>Task restarted</>;
export const AuditHistoryStatusChangeLinkedWorkflowRestarted = (
  <>Linked workflow previously completed now in progress</>
);
export const AuditHistoryStatusProblemReported = (partyName: ReactNode) => <>{partyName} reported a problem</>;
export const AuditHistoryStatusProblemReportedInLinkedWorkflow = "Problem reported (in a task in the linked workflow)";
export const AuditHistoryStatusProblemReportedLinkedWorkflowDeleted = "Problem reported (linked workflow deleted)";
export const AuditHistoryStatusProblemReportedLinkedWorkflowEnded = "Problem reported (linked workflow ended)";
export const AuditHistoryStatusProblemReportedLinkedWorkflowFailedToCreate =
  "Problem reported (failed to create linked workflow)";
export const AuditHistoryStatusProblemReportedLinkedWorkflowFailedToStart =
  "Problem reported (failed to start linked workflow)";
export const AuditHistoryStatusProblemReportedLinkedWorkflowMissingInfoToStart =
  "Problem reported (missing information to start linked workflow)";
export const AuditHistoryStatusProblemReportedLinkedWorkflowMissingRequiredOutput =
  "Problem reported (field was removed from the linked workflow)";

export const AuditHistoryStatusProblemResolved = (partyName: ReactNode) => <>{partyName} resolved a problem</>;
export const AuditHistoryStatusChangeDocusignTaskEnvelopeVoided = <>Envelope voided</>;
export const AuditHistoryStatusTaskReset = <>Task reset</>;

export const AuditHistoryRejectedMentioningAnotherTask = (taskLink: ReactNode) => <>rejected the task {taskLink}</>;
export const WithTheMessageHelperText = "with the message:";

export const AuditHistoryLessDetails = "Less details";
export const AuditHistoryMoreDetails = "More details";

export const AuditHistoryAssigneesUpdatedV2 = (partyName: JSX.Element) => <>{partyName} updated the task assignees</>;
export const AuditHistoryLinkedWorkflowOwnerUpdated = (partyName: JSX.Element) => (
  <>{partyName} updated the linked workflow owner</>
);
export const AuditHistoryLinkedWorkflowInvalidOwnerFieldValue = (partyName: JSX.Element) => (
  <span>{partyName} has been made the workflow owner, since an external person cannot own a workflow</span>
);
export const AuditHistoryLinkedWorkflowInvalidOwnerFieldValueUpdate = (
  <span>The workflow owner has not updated, since an external person cannot own a workflow</span>
);
export const AuditHistoryAutomationResponseFailureMessage = (statusCode: string) =>
  `HTTP request sent to external system but failed with status code ${statusCode}`;
export const AuditHistoryDocuSignAutomationResponseFailureMessage = (statusCode: string) =>
  `Request sent to DocuSign but failed with status code ${statusCode}`;

export const AuditHistoryAutomationResponseSuccessMessage = "HTTP request sent successfully to external system";
export const AuditHistoryDocuSignAutomationResponseSuccessMessage = "Request sent successfully to DocuSign";
export const AuditHistoryAutomationResponseOtherMessage = "HTTP request sent to external system";

export const AuditHistoryCcsUpdatedV2 = (partyName: ReactNode) => (
  <>{partyName} updated the Cc&apos;d users on the task</>
);

export const AuditHistoryDescriptionChangedMessage = (partyName: ReactNode) => <>{partyName} changed the description</>;

export const AuditHistoryDocusignApproved = (partyName: ReactNode) => <>{partyName} approved the document</>;
export const AuditHistoryDocusignDeclined = (partyName: ReactNode) => <>{partyName} declined the document</>;
export const AuditHistoryDocusignSigned = (partyName: ReactNode) => <>{partyName} signed the document</>;

export const AuditHistoryDocusignSentForSignature = (partyName: ReactNode) => (
  <>Document sent for signature to {partyName}</>
);
export const AuditHistoryDraftEdit = (partyName: ReactNode, numberOfFields: number) => (
  <>
    {partyName} edited {numberOfFields} field{numberOfFields === 1 ? "" : "s"}
  </>
);

export const AuditHistoryDocusignReassignedV2 = (partyName: ReactNode, newPartyName: ReactNode) => (
  <>
    Document reassigned by {partyName} to {newPartyName}
  </>
);

export const AuditHistoryWorkflowPausedV2 = (partyName: ReactNode) => <>{partyName} paused this workflow</>;
export const AuditHistoryLinkedWorkflowPaused = (partyName: ReactNode) => <>{partyName} paused the linked workflow</>;

export const AuditHistoryWorkflowEndedV2 = (partyName: ReactNode) => <>{partyName} ended this workflow</>;
export const AuditHistoryLinkedWorkflowEnded = (partyName: ReactNode) => <>{partyName} ended the linked workflow</>;
export const AuditHistoryWorkflowRestartedV2 = "Workflow restarted";
export const AuditHistoryLinkedWorkflowRestarted = "Linked workflow restarted";
export const AuditHistoryWorkflowStageRestartedV2 = "Stage restarted";
export const AuditHistoryWorkflowResumedV2 = (partyName: ReactNode) => <>{partyName} resumed this workflow</>;
export const AuditHistoryLinkedWorkflowResumed = (partyName: ReactNode) => <>{partyName} resumed the linked workflow</>;

export const AuditHistoryFileNameError = ({
  taskName,
  fieldName = "it",
  isWorkflow = false,
}: {
  fieldName: React.ReactNode;
  taskName: React.ReactNode;
  isWorkflow: boolean;
}) => (
  <>
    Sharepoint does not allow filenames containing spaces or special characters such as <b>* : &lt; &gt; ? / \\ |</b> or
    any filename beginning with <b>~$</b>. To save this file,{" "}
    {isWorkflow ? "change workflow field" : <>restart the {taskName} task containing</>} {fieldName} and re upload the
    file after renaming it. Afterwards, resolve the problem or restart the automated task.
  </>
);
export const AuditHistoryFileUploadError = ({
  fileName,
  taskName,
  fieldName = "it",
  isWorkflow = false,
}: {
  fileName: React.ReactNode;
  fieldName: React.ReactNode;
  taskName: React.ReactNode;
  isWorkflow: boolean;
}) => (
  <>
    {fileName} could not be downloaded since it is a non-public or invalid link. To save this file,{" "}
    {isWorkflow ? "change workflow field" : <>restart the {taskName} task containing</>} {fieldName} and attach a file.
    Afterwards, resolve the problem or restart the automated task.
  </>
);
export const AuditHistoryUnknown = "[Unknown]";
export const AuditHistoryUpdatedVia = (args: { field: React.ReactNode; trigger: string }) => (
  <>
    {args.field || "Field"} <span>was updated via {args.trigger}</span>
  </>
);
export const AuditHistoryUpdatedViaFileTrigger = "file upload";

// Network errors
export const NetworkErrorActionItemIncorrectStatus = "This task's status has already been changed by someone else.";
export const NetworkErrorActionItemStatusChangeForbidden =
  "You don't have sufficient permissions to change the status of this task.";
export const NetworkErrorFailedToFetch = "Failed to fetch";

// Forms
export const FormBulkEmailSuccess = (numSucceeded: number, createdUsers: string) =>
  `Successfully created the following ${numSucceeded === 1 ? "user" : "users"}: ${createdUsers}. `;
export const FormBulkEmailFailure = (numFailed: number) =>
  `Failed to create ${numFailed} ${numFailed === 1 ? "user" : "users"}. `;
export const FormBulkEmailSuccessAndFailure = (numFailed: number, numSucceeded: number, createdUsers: string) =>
  `Successfully created the following ${
    numSucceeded === 1 ? "user" : "users"
  }: ${createdUsers}. Failed to create ${numFailed} other ${numFailed === 1 ? "user" : "users"}`;
export const FormReject = "Reject";
export const FormSend = "Send";
export const FormSendNow = "Send now";
export const FormSendNowHotkeyHelp = "Press Cmd + Return";
export const FormErrorAfterDate = (formattedDateString: string) => `Must be after ${formattedDateString}`;
export const FormErrorBeforeDate = (formattedDateString: string) => `Must be before ${formattedDateString}`;
export const FormErrorIsRequired = "This field is required";
export const FormErrorEmailInvalid = "Email is invalid";
export const FormErrorBulkEmailInvalid = "Email(s) are invalid";
export const FormErrorEmailAlreadyInUse = "This email is already associated with another account.";
export const FormErrorFileTypeInvalid = (acceptedTypes: string[]) =>
  `Only ${acceptedTypes} ${acceptedTypes.length === 1 ? "is" : "are"} accepted.`;
export const FormErrorBulkEmailAlreadyInUse = "One or more emails are already associated with an account.";
export const FormErrorPasswordDoesntMatch = "Passwords must be the same";
export const FormErrorPasswordDoesntMeetCriteria = "Password must have at least 8 characters";
export const FormErrorPhoneInvalid = "Phone number is invalid";
export const FormErrorEmailExternalDomain =
  "You don’t have permission to add a user who is outside of your organization. Please contact a Regrello admin to add a person from another domain.";
export const FormErrorGreaterThanOrEqualToLowerBound = (lowerBound: number) => `Must be at least ${lowerBound}`;
export const FormErrorLessThanOrEqualToUpperBound = (upperBound: number) =>
  `Must be less than or equal to ${upperBound}`;
export const FormErrorMaxLength = (maxLength: number) => `Must be at most ${maxLength} characters`;
export const FormErrorMinLength = (minLength: number) => `Must be at least ${minLength} characters`;
export const FormHelperTextInviteUserRoles =
  "Roles grant access to blueprints, workflows, and other data in this workspace. Select one or multiple.";
export const FormHelperTextInviteUserType = "Use Internal for anyone belonging to your organization.";
export const FormHelperTextMultipleEmailInput = "Type an email, then press enter, space, or comma to add your input.";
export const FormHelperTextMultipleEmailInputV2 = "Enter email addresses separated by commas or spaces.";
export const FormLabelEmail = "Email";
export const FormLabelName = "Name";

// 'Avoid Losing Edits?' prompt
export const AvoidLosingEditsPromptCancel = "Continue editing";
export const AvoidLosingEditsPromptConfirm = "Close";
export const AvoidLosingEditsPromptDescription = "You have unsaved changes that will be lost if you close.";
export const AvoidLosingEditsPromptSaveAndClose = "Save and close";
export const AvoidLosingEditsPromptTitle = "Are you sure?";

// Zero state
export const ZeroStateDescriptionActionItems = "No tasks match your current filters.";
export const ZeroStateDescriptionWorkflows = "No workflows match your current filters.";

// Dialogs
export const DialogActionBack = "Back";
export const DialogActionNext = "Next";

// Reject actions
export const EndWorkflow = "End workflow";
export const EndWorkflowRejectActionTooltipText = "Rejecting this task will end the workflow";
export const IfRejected = "If rejected";
export const RestartTask = "Restart task";
export const ReopenTaskRejectActionTooltipText = (taskName: string) =>
  `Rejecting this task will restart the task ${taskName}`;
export const RestartStage = "Restart stage";
export const RestartStageRejectActionTooltipText = (stageName: string) =>
  `Rejecting this task will restart the stage ${stageName}`;
export const RestartWorkflow = "Restart workflow";
export const RestartWorkflowRejectActionTooltipText = "Rejecting this task will restart the workflow";
export const Actions = "Actions";
export const RejectActionFieldHelperText = "The action to take upon rejecting the task.";

// Form fields
export const ActionItemDescriptionUpdated = 'Task "Description" updated';
export const FormFieldHomepageCategoryHelperText = "This helps you organize all your tags into groups.";
export const FormFieldAtMostOnePartySelectedHelperText = "Choose one person or team.";

// Confirmation Dialog
export const AreYouSure = "Are you sure?";
export const DeleteTagWarning = (args: { count: number; name: React.ReactChild }) => {
  return (
    <span>
      {args.name} has {args.count} open task(s) visible to you.
    </span>
  );
};
export const DeleteTagTypeWarning = (args: { count: number; name: React.ReactChild }) => {
  return (
    <span>
      {args.name} has {args.count} tags. All tags under {args.name} will be deleted from their associated tasks.
    </span>
  );
};
export const ConfirmationErrorMessage =
  "onConfirm is not executed because value passed into RegrelloConfirmationDialogInternal is null";

// Create Field Dialog
export const CreateFieldDialogFieldTypeHelperText = "e.g. Date, Text, Document";
export const CreateFieldDialogFieldTypeSelectHelperText =
  "Select allows you to select a single option from predefined options in a dropdown.";
export const CreateFieldDialogFieldTypeMultiSelectHelperText =
  "Multi-select allows you to select multiple options from predefined options in a dropdown.";

// Custom Fields
export const AddField = "Add fields";

export const CustomFieldsDescription = "Additional information required to complete this task";

export const FromPreviousTasks = "From previous tasks";
export const FromPreviousHelperText = (taskName: string) => `From: ${taskName}`;
export const FromPreviousHelperTextWithStage = (stageName: string, taskName: string) =>
  `from ${stageName} > ${taskName}`;
export const FromWorkflow = "From workflow";
export const NoFieldsToSelect = "No inheritable fields were found";
export const SelectField = "Select field";
export const SelectFieldEllipses = "Select field...";
export const SelectFields = "Select fields";
export const SelectFieldHelperText = "Select fields from previous tasks to share with the person completing the task.";
export const SelectFieldHelperTextApprovalTask =
  "Selecting fields to submit for approval automatically cc's related assignees.";
export const SystemFieldEditButtonDisabled = "Regrello's system-level fields cannot be edited.";
export const SystemFieldDeleteButtonDisabled = "Regrello's system-level fields cannot be deleted.";
export const FieldInUseDeleteButtonDisabled = (blueprintCount: number) => (
  <>
    This field cannot be deleted because it is still being used in <strong>{blueprintCount}</strong> company blueprint
    {blueprintCount === 1 ? "" : "s"}.
  </>
);
export const FieldInUseDeleteButtonDisabledV2 = (blueprintCount: number) => (
  <>
    This field cannot be deleted because it is still being used in <strong>{blueprintCount}</strong> published blueprint
    {blueprintCount === 1 ? "" : "s"}.
  </>
);
export const SyncObjectFieldButtonDisabled =
  "This field cannot be edited or deleted because it is connected to an external data source. Only workspace admins can edit or delete connected data sources.";

// Workflow
export const ActionItemStarted = (name: string) => `Task ${name} started`;
export const AddDescription = "Add description";
export const AddStage = "Add stage";
export const AddWorkflow = "Add workflow";
export const AreYouReadyToStartYourWorkflow = "Are you ready to start your workflow?";
export const AttachDocuments = "Click or drop to attach documents";
export const AttachDocumentsLimitReached = (limit: number) => `${limit} / ${limit} documents attached`;
export const AttachDocumentsV2 = (attachAction: React.ReactChild, pasteAction: React.ReactChild) => (
  <>
    Drop,{attachAction}, or {pasteAction} a link
  </>
);
export const CopyToMyBlueprints = "Copy to My blueprints";
export const CopyToMyBlueprintsTooltip = "Copy as new draft in My blueprints";
export const CopyToDraftBlueprints = "Copy to Draft blueprints";
export const CopyToDraftBlueprintsTooltip = "Copy as new draft in Draft blueprints";
export const CopyAsNewDraftBlueprintTooltip = "Copy as a new draft blueprint.";
export const BeforeYouPause = "Before you pause";
export const CreateBlueprint = "Create blueprint";
export const End = "End";
export const EndWorkflowDialogTitle = "End this workflow?";
export const EndWorkflowInstruction =
  "Ending this workflow will immediately halt all of its uncompleted tasks and stages. Once you end this workflow, it cannot be restarted.";
export const EndedByUserOnDate = (name: string, date: string) => (
  <>
    This workflow was ended by <strong>{name}</strong> on <strong>{date}</strong>
  </>
);
export const EndWorkflowTypeToConfirmError = "Type 'end workflow' to confirm.";
export const EndWorkflowTypeToConfirmLabel = "Type 'end workflow' to confirm";
export const EndWorkflowTypeToConfirmValue = "end workflow";
export const Edit = "Edit";
export const EditStage = "Edit stage";
export const EditBlueprintDetails = "Edit blueprint details";
export const EditBlueprintFields = "Edit blueprint fields";
export const EditWorkflowDetails = "Edit workflow details";
export const EditWorkflowOwner = "Edit workflow owner";
export const EditWorkflowFields = "Edit workflow fields";
export const Comment = "Comment";
export const Copy = "Copy";
export const CreateAndReview = "Create and review";
export const CreateWorkflowFromBlueprint = "Create workflow from blueprint";
export const DoNotAdjustDueDates = "Do not adjust due dates";
export const EndDate = "End date";
export const EstimatedEndDate = "Estimated end date";
export const PlannedEndDate = "Planned end date";
export const ConditionalPlannedEndDate = "Conditional planned end date";
export const ActualEndDate = "Actual end date";
export const ActionItemDueDateMustBeInFuture = (actionItemName: string) =>
  `The due date for the task '${actionItemName}' is in the past. This may cause visual inconsistencies in your final Gantt Chart.`;
export const WorkflowStageMustStartInFuture = (stageNameOrNumber: string | number) =>
  `The start date for the stage ${
    typeof stageNameOrNumber === "number" ? `number ${stageNameOrNumber}` : `'${stageNameOrNumber}'`
  } is in the past. This may cause visual inconsistencies in your final Gantt Chart.`;
export const MustBeUniqueValue = "Must be a unique value";
export const MustBeValidUrl = "Must be a valid URL";
export const MustBeNumber = "Must be a number";
export const MustBeWholeNumber = "Must be a whole number";
export const MustBeWholeNumberInRange = (args: { min: number; max: number }) =>
  `Must be a whole number between ${args.min} and ${args.max}`;
export const NotifyAssignees = "Notify those assigned on in progress tasks via email";
export const OverdueActionItems = "Overdue tasks";
export const PendingAssignment = "Pending assignment";
export const Pause = "Pause";
export const PauseWorkflow = "Pause workflow";
export const PauseWorkflowInstruction =
  "Pausing the workflow means no action can be taken by task assignees until the workflow is resumed.";
export const PausedByUserOnDate = (name: string, date: string) => (
  <>
    This workflow was paused by <strong>{name}</strong> on <strong>{date}</strong>
  </>
);
export const PleaseEnterAMessage = "Include a message";
export const Profile = "Profile";
export const Resume = "Resume";
export const ResumeWorkflow = "Resume workflow";
export const ResumeWorkflowInstruction =
  "Some tasks may now be overdue. Stages and tasks with specific start dates that have passed during this time will start automatically.";
export const ShiftDueDates = "Shift due dates";
export const ShiftDueDatesInstruction = "Task with due dates set via custom fields will not be affected.";
export const SignOut = "Sign out";
export const StageAdded = (stageName: string) => `Stage ${stageName} added`;
export const StageDeleted = (stageName: string) => `Stage ${stageName} deleted`;
export const Stages = "Stages";
export const StagesWithCount = (stageCount: number) => `${stageCount} stage${stageCount === 1 ? "" : "s"}`;
export const StageStatus = "Stage status";
export const CurrentStage = "Current stage";
export const CurrentStages = "Current stages";
export const StageStarted = (stageName: string) => `Stage ${stageName} started`;
export const StartActionDialogContent =
  "This task will start immediately. Notifications will be sent out to the assigned parties.";
export const StartStageDialogContent =
  "This stage will start immediately. All tasks for this stage will be sent out to the assigned parties.";
export const StartWorkflowError = "There was an error starting the workflow";
export const Start = "Start";
export const StartInNewTab = "Start in new tab";
export const StartWorkflow = "Start workflow";
export const StartWorkflows = "Start workflows";
export const StartAfter = "Start after";
export const StartsAfter = "Starts after";
export const StartDate = "Start date";
export const CompletedOnTime = (time: React.ReactChild) => `Completed on ${time}`;
export const StartedOn = "Started on";
export const UpdateBlueprintTooltip = "Update to create a new version of the blueprint.";
export const UpdateBlueprintCannotViewDraftTooltip = "You do not have permissions to view the draft.";
export const UpdateBlueprintDisabledTooltip = "Blueprint currently being updated.";
export const UseTemplate = "Use this blueprint";
export const CompanyBlueprint = "Company blueprint";
export const Published = "Published";
export const CompanyTemplates = "Company blueprints";
export const UsedInCompanyBlueprints = "Used in company blueprints";
export const UsedInCompanyBlueprintsIntegrationsTableColumnTooltip =
  "Shows counts of integrations added to blueprints after May 2024";
export const UsedInPublishedBlueprints = "Used in published blueprints";
export const CreateAWorkflow = "Create a workflow";
export const CreateWorkflow = "Create workflow";
export const Workflows = "Workflows";
export const Workflow = "Workflow";
export const WorkflowCompleted = "Workflow completed";
export const WorkflowDataTabCalloutPublishedBlueprintFieldsLockedText =
  "This blueprint is published, so its fields will not respond to field changes at the workspace level. To make changes to fields (e.g., to rename them), copy this blueprint to My blueprints.";
export const WorkflowDataTabCalloutPublishedBlueprintFieldsLockedTitle = "Fields are locked";
export const WorkflowDataTabCalloutMyBlueprintFieldsOutdatedAction = "Update fields";
export const WorkflowDataTabCalloutMyBlueprintFieldsOutdatedText =
  "Some fields used in this blueprint have been modified since this blueprint was published. Would you like to update fields to their latest versions? This will not affect fields that belong to a form.";
export const WorkflowDataTabCalloutMyBlueprintFieldsOutdatedTitle = "New field versions available";
export const WorkflowDetails = "Workflow details";
export const WorkflowName = "Workflow name";
export const WorkflowHasBeenPausedForDays = (days: number) => `This workflow has been paused for ${days} days`;
export const WorkflowWarningCannotStartDueToMissingAssigneesOrEmptyStages =
  "To start this workflow, assign all tasks in stages configured to start when the workflow starts and remove empty stages.";
export const WorkflowWarningCannotStartInsufficientPermissions = "Insufficient permissions to start the workflow.";
export const WorkflowWarningCannotStartDueToMissingRequiredFieldValues =
  "Fill out all required fields to start this workflow.";
export const WorkflowCreatorTooltip =
  "The creator of the workflow has commenter access on the workflow. They can also delete the workflow.";

export const WorkflowTemplateCannotUseV2 = "Cannot create workflow from blueprint until there is at least one task.";
export const WorkflowTemplateCannotUseInsufficientPermissions =
  "Insufficient permissions to create a workflow from this blueprint.";
export const WorkflowTemplateCanUse =
  "Using this workflow blueprint will create a new workflow with this content as a starting point.";
export const WorkflowTemplateListByFieldDialogDescription = (fieldName: string, templateCount: number) => (
  <span>
    Here are the {templateCount} company blueprints using the <strong>{fieldName}</strong> field.
  </span>
);
export const WorkflowTemplateListByFieldDialogDescriptionSingular = (fieldName: string) => (
  <span>
    Here is the company blueprint using the <strong>{fieldName}</strong> field.
  </span>
);
export const WorkflowTemplateListByIntegrationDialogDescription = (integrationName: string, templateCount: number) => (
  <span>
    Here are the {templateCount} blueprints using the <strong>{integrationName}</strong> integration.
  </span>
);
export const WorkflowTemplateListByIntegrationDialogDescriptionSingular = (integrationName: string) => (
  <span>
    Here is the blueprint using the <strong>{integrationName}</strong> integration.
  </span>
);
export const WorkflowTemplateListByRoleDialogDescription = (roleName: string, templateCount: number) => (
  <span>
    Here are the {templateCount} blueprints using the <strong>{roleName}</strong> role.
  </span>
);
export const WorkflowTemplateListByRoleDialogDescriptionSingular = (roleName: string) => (
  <span>
    Here is the blueprint using the <strong>{roleName}</strong> role.
  </span>
);
export const WorkflowEnded = "Workflow ended";
export const WorkflowPaused = "Workflow paused";
export const WorkflowResumed = "Workflow resumed";
export const WorkflowStarted = "Workflow started";
export const WorkflowTemplate = "Blueprint";
export const WorkflowId = "Workflow Id";
export const Workspace = "Workspace";
export const Templates = "Blueprints";
export const WorkflowTemplateUpdated = (link: JSX.Element) => <span>Blueprint {link} updated</span>;
export const WorkflowUpdated = (link: JSX.Element) => <span>Workflow {link} updated</span>;
export const MyBlueprint = "My blueprint";
export const MyTemplates = "My blueprints";
export const NoAdditionalData = "No additional data";
export const NoCompanyTemplatesZeroStateDescription = "Blueprints published for company use will appear here.";
export const NoCompanyTemplatesZeroStateDescriptionV2 = "Published blueprints you have access to will appear here.";
export const NoCompanyTemplatesZeroStateTitle = "No company blueprints";
export const NoTemplatesZeroStateDescription = "Blueprints you create will appear here.";
export const NoTemplatesZeroStateDescriptionV2 = "Draft blueprints you have access to will appear here.";
export const NoTemplatesZeroStateTitle = "No blueprints";
export const StageCompletedCount = (numCompleted: number, numSkipped: number) =>
  `${numCompleted} stage${numCompleted === 1 ? "" : "s"} completed (${numSkipped} skipped)`;
export const NoCompletedStages = "No completed stages";
export const Days = "day(s)";
export const DaysToApprove = "Days to approve";
export const DatabaseInstance = "Database instance";
export const DatabaseInstanceHelperText =
  "Select the instance the workspace will be assigned to. Exercise caution to ensure that customer workspaces are assigned to their database.";
export const TimeToComplete = "Time to complete";
export const TimeToCompleteHelperText =
  "Enter a number between 1 and 365, representing calendar days. Clear the value to leave without a due date.";
export const DaysLeadTimeWithNumber = (number: number) => `${number} ${number === 1 ? "day" : "days"}`;
export const NotAssigned = "Not assigned";
export const DeleteWorkflow = "Delete workflow";
export const DeleteDraft = "Delete draft";
export const DeleteTemplate = "Delete blueprint";
export const InProgress = "In progress";
export const NotStarted = "Not started";
export const Paused = "Paused";
export const SaveChanges = "Save changes";
export const RenameTemplate = "Rename blueprint";
export const EditDescription = "Edit description";
export const EditActionItem = "Edit task";
export const EditApprovalActionItem = "Edit approval task";
export const Day = "day";
export const Wk = "wk";
export const DeleteTheStage = "Delete the stage?";
export const DeleteTheStageContent = (stageName: string) =>
  `Are you sure you want to delete the '${stageName}' stage? This will also delete all tasks in this stage. This operation cannot be undone.`;

export const DeleteTheActionItem = "Delete the task?";
export const DeleteTheActionItemContent = (name: string) => `Delete the ${name} task.`;
export const DeleteTheField = "Delete the field?";
export const DeleteTheFieldStartingConditionsContent =
  "Deleting this field will also delete all fields that inherit from it and any conditions using it to start a stage. Do you want to proceed?";
export const WorkflowDeleted = (name: string) => `Workflow ${name} deleted`;
export const BlueprintWillBePermanentlyDeleted = "This blueprint will be permanently deleted.";
export const WorkflowWillBePermanentlyDeleted = "This workflow will be permanently deleted.";
export const WorkflowFieldsString = "Workflow fields"; // (zstanik): Not to be confused with the type `WorkflowFields`.
export const BlueprintAccess = "Blueprint access";
export const BlueprintAccessSubtitle = "Control which people, teams, and roles can access this blueprint.";
export const BlueprintAccessSubtitleWhenPublished = "People, teams, and roles who can access this blueprint.";
export const BlueprintAccessSubtitleDisclaimer =
  "Only Admins, Creators, and Publishers can access this blueprint in its draft state.";
export const WorkflowAccess = "Workflow access";
export const WorkflowAccessSubtitle = "Grant people or teams access to the workflow.";
export const WorkflowAccessSubtitleForPermissionsV2 =
  "Control which people, teams, and roles can access workflows created from this blueprint.";
export const WorkflowAccessSubtitleWhenPublishedForPermissionsV2 =
  "People, teams, and roles who can access workflows created from this blueprint.";
export const TaskAccess = "Task access";
export const TaskAccessSubtitle = "Review the people, teams, and roles who are assigned a task in this blueprint.";
export const TaskAccessSubtitleWhenPublished = "People, teams, and roles who are assigned a task in this blueprint.";
export const TaskAccessSubtitleDisclaimer =
  "These task assignees are automatically granted access to this blueprint and to workflows created from it.";
export const NoAccess = "No access";
export const WorkflowNoAccessRoleName = "No access";
export const WorkflowNoAccessRoleDescription = "Only view assigned or cc'd tasks";
export const CommenterRoleName = "Commenter";
export const EditorRoleName = "Editor";
export const CommenterRoleDescription = "View entire workflow, its data, and comment";
export const EditorRoleDescription = "Add, remove, and edit tasks and stages";

export const WorkflowOwner = "Workflow owner";
export const WorkflowOwnerSubtitle =
  "Set the default workflow owner for any workflows that start from this blueprint. Only 1 person or team can be selected.";
export const WorkflowOwnerSubtitleForPermissionsV2 =
  "Control which person or team will be the default owner for workflows created from this blueprint.";
export const WorkflowOwnerCannotBeEditedTooltip =
  "The workflow owner cannot be edited. The owner is managed by the parent workflow.";
export const WorkflowTemplateWorkflowAccessSubtitle =
  "People or teams granted workflow access will be able to view or edit any workflows run off this blueprint.";
export const WorkflowAddCollaborator = "Give workflow access";
export const WorkflowMustHaveOwner = "Workflow must have exactly 1 owner";
export const BlueprintAtMostOneOwner = "Blueprint can have at most 1 owner";
export const WorkflowOwnerMustBeInternal = "Workflow owner must be internal";
export const AddPerson = "Add person";

// Templates
export const AddVersionNotes = "Add version notes";
export const AllStandardFieldsCollected = "All standard blueprint fields collected";
export const AutogenerateFileName = "Autogenerate file name";
export const AutogenerateWorkflowName = "Autogenerate workflow name";
export const BlueprintId = "Blueprint ID";
export const BlueprintUuidOptionSubtext = (blueprintName: string) => `Name: ${blueprintName}`;
export const BlueprintVersionPublished = (
  blueprintName: React.ReactNode,
  versionNumber: number,
  publishedBlueprintsLink: React.ReactNode,
) => (
  <>
    {blueprintName} has been published.
    <br />
    The version {versionNumber} is now available in {publishedBlueprintsLink}.
  </>
);
export const BulkStartWorkflowsDialogTitle = (workflowTemplateName: string) =>
  `Bulk add "${workflowTemplateName}" workflows`;
export const BulkStartWorkflowsLoading = (numWorkflows: number) =>
  `Starting ${numWorkflows} ${numWorkflows === 1 ? "workflow" : "workflows"}...`;
export const BulkStartWorkflowsDocumentFieldWarningContent1 =
  "This workflow blueprint includes the following document fields:";
export const BulkStartWorkflowsDocumentFieldWarningContent2 =
  "You'll be able to create workflows from your uploaded CSV, but afterward, you'll need to manually upload documents for each workflow.";
export const BulkStartWorkflowsDocumentFieldWarningTitle = "Document fields detected";
export const BulkStartWorkflowsLongWaitContent =
  "It looks like this upload will take some time. Feel free to move on to something else, and we'll email you when the upload is finished.";
export const BulkStartWorkflowsLongWaitTitle = "Upload in progress";
export const BulkStartWorkflowsSuccessConfirm = "Take me there";

export const BulkStartWorkflowsSummaryTitle = "Upload summary";
export const BulkStartWorkflowsValidationHintInvalidDateFormat = (commaSeparatedDateFormats: string) =>
  `Please enter dates in one of the following formats: ${commaSeparatedDateFormats}`;
export const BulkStartWorkflowsValidationHintInvalidEmailsV2 = (commaSeparatedEmails: string, numEmails: number) =>
  `These ${numEmails === 1 ? "user" : "users"} are unrecognized and will be created on submit: ${commaSeparatedEmails}`;
export const BulkStartWorkflowsValidationErrorInvalidEmails = (commaSeparatedEmails: string, numEmails: number) =>
  `These ${
    numEmails === 1 ? "user" : "users"
  } are unrecognized. Please enter internal users who exists in this workspace: ${commaSeparatedEmails}`;
export const BulkStartWorkflowsValidationHintInvalidNumber = "Must be a valid number";
export const BulkStartWorkflowsValidationHintInvalidValue = "Must be a recognized value";
export const BulkStartWorkflowsValidationHintInvalidValues = (commaSeparatedValues: string, numValues: number) =>
  `Unrecognized ${numValues === 1 ? "value" : "values"}: ${commaSeparatedValues}`;
export const BulkStartWorkflowsValidationHintUnrecognizedEmailV2 =
  "This person is unrecognized and will be created on submit.";
export const BulkStartWorkflowsValidationErrorUnrecognizedEmail =
  "This person is unrecognized. Please enter an internal user who exists in this workspace.";
export const BulkStartWorkflowsValidationErrorMultipleEmails =
  "Cannot provide multiple emails. Please provide only one email.";
export const BulkStartWorkflowsValidationHintUnrecognizedTags = (commaSeparatedTagNames: string) =>
  `Unrecognized tag: ${commaSeparatedTagNames}`;
export const BulkStartWorkflowsColumnHelperTextForTags = "Will be added to the default tags for this blueprint.";
export const ChangeLog = "Change log";
export const CreatedOn = "Created on";
export const CreateVariant = "Create variant";
export const Download = "Download";
export const DownloadCsv = "Download CSV";
export const DownloadCsvTemplate = "Download to bulk start workflows";
export const DownloadCsvTemplateHelper = "Fill out and upload this CSV to start several workflows at once";
export const DownloadCsvTemplateMenuText = "Download CSV to bulk start";
export const DeleteCompanyBlueprintHelperText = (blueprintName: string) => (
  <>
    <p>
      Deleting <strong>{blueprintName}</strong> will also delete any versions of the blueprint. Workflows currently
      using this blueprint will not be affected.
    </p>
    <br />
    <p>Confirm deleting by typing the blueprint name below:</p>
  </>
);
export const DraftVersion = "Draft version";
export const EditBlueprint = "Edit blueprint";
export const EditDetails = "Edit details";
export const FieldInUse = "In use";
export const LockApprovals = "Lock approvals";
export const LockApprovalsHelperText = "Prevent future changes to approvals.";
export const LockTooltipTextApprove = "Only assigned approvers or admins can approve this task";
export const LockTooltipTextBluprint = "This task will not be editable in workflows";
export const LockTooltipTextDelete = "This task cannot be deleted";
export const LockTooltipTextReject = "Only assigned approvers or admins can reject this task";
export const LockTooltipTextWorkflow = "This task cannot be edited";
export const LockTooltipTextParentTaskEditDialog = "Approvals cannot be deleted or reassigned";
export const LockTooltipTextRequestedFieldEdit = "This field cannot be edited";
export const NamingConvention = "Naming convention";
export const NamingConventionTooltipText =
  "A naming convention allows you to specify the default format for the workflow name. Names will then be automatically generated by Regrello. Workflow owners can add additional text after the naming convention.";
export const NamingConventionDeleteFieldDisabledTooltipText =
  "This field is in use in the blueprint's naming convention and cannot be deleted";
export const NamingConventionOptionalFieldConfirmTitle = "Change the field type to optional?";
export const NamingConventionOptionalFieldConfirmContent =
  "This field is in use in the blueprint's naming convention. Changing its type to optional may result in an empty value for that field in the autogenerated workflow name. Do you want to proceed?";
export const NamingConventionEditFieldsConfirmContent =
  "The workflow's name will be regenerated to use the updated field values.";
export const NamingConventionStageReevaluateEditFieldsConfirmContent = `${NamingConventionEditFieldsConfirmContent} ${StageReevaluateDialogText}`;
export const NamingConventionSyncedObjectProjectionsHelperText =
  "Unselected fields can still be used in the blueprint's naming convention.";
export const TenantPossessiveTemplates = (name: string) => `${name}'s blueprints`;
export const TenantAdjectiveTemplates = (description: string) => `${description} blueprints`;
export const Publish = "Publish";
export const PublishedBy = "Published by";
export const PublishedOn = "Published on";
export const PublishTemplate = "Publish blueprint";
export const StandardBlueprintFields = "Standard blueprint fields";
export const StandardBlueprintFieldsCollectedHelperText =
  "These fields must be collected as defined in the standard blueprint.";
export const StandardField = "Standard field";
export const CsvDisabledExplanation =
  "Blueprints that have no tasks or contain a workflow-level field of type Document or Synced Object cannot be exported to CSV.";
export const UpdateFields = "Update fields";
// (zstanik) blueprint variations TODO: replace this with the actual how to guide once it gets
// published.
export const VariantsLearnMoreLink = "https://regrello.notion.site/Regrello-How-To-46a14cc3e5994b5197fe9cf6318d7000";
export const VariantBlueprintFieldsSectionHelperText =
  "Blueprint fields must match the standard blueprint fields exactly.";
export const VariantBlueprintHeaderText =
  "This is a variant blueprint. Customize the tasks and stages, edit the conditions in the About tab, and publish.";
export const VariantBlueprintPublishNonCompliantTooltip =
  "This blueprint is non-compliant. Collect the required fields as defined by the standard blueprint to publish this variant.";
export const VariantBlueprintNonCompliantTooltip =
  "Workflows will not be created from this variant because it does not collect all the required fields as defined by the standard blueprint. Update the variant and publish it to start creating workflows.";
export const VariantBlueprintUpdateFieldsBannerTitle =
  "New changes to the blueprint fields in the standard blueprint available";
export const VariantBlueprintUpdateFieldsBannerHelperText =
  "Some fields in the standard blueprint have been modified. Would you like to update to the latest blueprint fields?";
export const VariantBlueprintWorkflowCannotBeCreatedTooltip = (standardBlueprintName: string) =>
  `Workflows cannot be created from a variant blueprint. Create a workflow from ${standardBlueprintName}.`;
export const VariantConditions = "Variant conditions";
export const VariantConditionsHelperText = "Set the conditions for selecting this variant.";
export const VariantOfStandard = (standardNameOrLink: React.ReactNode) => <>Variant of {standardNameOrLink}</>;
export const VariantsTabEmptyStateExample1 = (
  <>
    Use different tasks if <strong>Country</strong> = &quot;India&quot;
  </>
);
export const VariantsTabEmptyStateExample2 = (
  <>
    Request more fields if <strong>Region</strong> = &quot;EMEA&quot;
  </>
);
export const VariantsTabEmptyStateExample3 = (
  <>
    Delegate to different owners based on <strong>Factory</strong>
  </>
);
export const VariantsTabEmptyStateTitle = "No variants";
export const VariantsTabEmptyStateHelperText =
  "Variants are linked, customizable blueprints that must collect the same fields as this one. Rules control when each variant should be used.";
export const Version = "Version";
export const VersionNotes = "Version notes";
export const VersionNumber = (versionNumber: number) => `Version ${versionNumber}`;
export const ViewChangeLog = "View change log";
export const VNumberParentheses = (versionNumber: number) => `(v${versionNumber})`;
export const VersionPound = "Version #";
export const WorkflowPermissions = "Workflow permissions";
export const WorkflowTemplatePublished = (templateNameLink: React.ReactNode, tenantDisplayName: string) => (
  <>
    {templateNameLink} blueprint published in {tenantDisplayName}&apos;s blueprints.
  </>
);
export const WorkflowTemplatePublishedDefault = (templateNameLink: React.ReactNode) => (
  <>{templateNameLink} blueprint was published.</>
);
export const WorkflowTemplateDeleted = (name: string) => `Blueprint ${name} deleted`;
export const WorkflowAddStageDescription = "Stages help you group tasks that need to be completed";
export const DeleteTheWorkflowTemplate = "Delete this blueprint?";
export const DeleteTheWorkflowTemplateContent = (name: string) => `Delete the ${name} blueprint.`;
export const WorkflowTemplateNameSaved = "Workflow blueprint name saved";
export const WorkflowTemplateSavedToMyBlueprints = (blueprintNameLink: React.ReactNode) => (
  <>{blueprintNameLink} copied to My blueprints.</>
);
export const WorkflowTemplateSavedToDraftBlueprints = (blueprintNameLink: React.ReactNode) => (
  <>{blueprintNameLink} copied to Draft Blueprints.</>
);
export const WorkflowTemplateTypeRegrello = "Regrello blueprint";
export const WorkspaceCreated = (name: string) => `Workspace ${name} added`;
export const WorkspaceRenamed = (newDisplayName: string) => `Workspace renamed to ${newDisplayName}`;

// Settings
export const Subscribed = "Subscribed";
export const AnyNotificationIsTurnedOn = "Email notifications are turned ON";
export const AnyNotificationIsTurnedOff = "Email notifications are turned OFF";
export const EmailNotificationIsTurnedOnForAllAssignedTasks = "Assigned task updates are turned ON for all tasks";
export const EmailNotificationIsTurnedOffForAllAssignedTasks = "Assigned task updates are turned OFF for all tasks";
export const EmailNotificationIsTurnedOnForAllCcdTasks = "Cc'd task updates are turned ON for all tasks";
export const EmailNotificationIsTurnedOffForAllCcdTasks = "Cc'd task updates are turned OFF for all tasks";
export const DailyDigestIsTurnedOn = "Daily task alerts are turned ON";
export const DailyDigestIsTurnedOff = "Daily task alerts are turned OFF";
export const WeeklyDigestIsTurnedOn = "Weekly digest is turned ON";
export const WeeklyDigestIsTurnedOff = "Weekly digest is turned OFF";
export const YouAreNowSubscribedTo = (link: React.ReactNode) => <span>You are now subscribed to {link}</span>;

// Sidebar
export const DraftBlueprints = "Draft blueprints";
export const Draft = "Draft";
export const PeopleAndTeams = "People & Teams";
export const PublishedBlueprints = "Published blueprints";
export const UserMenu = "User menu";

// Schedule
export const ScheduleActionItemStartTimeDefaultV2 = "When the stage starts";
export const ScheduleActionItemStartTimeAfterDependencyV2 = "After another task ends";
export const ScheduleActionItemDueTimeToComplete = "Time to complete";
export const ScheduleStageStartTimeDefaultV2 = "After the previous stage completes";
export const ScheduleStageStartTimeDefaultV3 = "After the previous stage";
export const ScheduleStageStartTimeOnNConditionsMet = (numConditions: number) =>
  `If ${numConditions} condition${numConditions === 1 ? "" : "s"} met`;
export const ScheduleStageStartTimeAfterDependenciesV2 = "After specific stages";
export const ScheduleStageStartTimeOnWorkflowStart = "When the workflow starts";
export const ScheduleStageStartTimeStartConditionStage = "Based on specific stage conditions";
export const ScheduleStageStartTimeStartConditionWorkflow = "Based on specific workflow conditions";
export const ScheduleTimeFromCustomField = "From a field";
export const ScheduleTimeOnDate = "On a specific date";
export const Due = "Due";
export const NoDueDateSet = "No due date set";
export const TaskName = "Task name";

// Starred views
export const Star = "Star";
export const Starred = "Starred";
export const Unstar = "Unstar";
export const ToastSuccessOnStarEntity = (linkToEntity: JSX.Element) => <span>Successfully starred {linkToEntity}</span>;
export const ToastSuccessOnUnstarEntity = (linkToEntity: JSX.Element) => (
  <span>Successfully unstarred {linkToEntity}</span>
);

// Custom Fields
export const CreateNewField = "Add field";
export const DocumentsCount = (count: number) => `${count} document${count === 1 ? "" : "s"}`;
export const Field = "Field";
export const Fields = "Fields";
export const FieldName = "Field name";
export const FieldRequiredHelperText = "Fields are set to required by default. Flip the toggle to make them optional.";
export const FieldSectionHelperTextWhenConfiguringBlueprintV2 =
  "Information necessary to start a workflow from this blueprint.";
export const FieldSectionHelperTextWhenConfiguringTask = "Add fields to request information to complete the task.";
export const FieldSectionHelperTextWhenConfiguringWorkflow = "Information necessary to start the workflow.";
export const FieldSectionHelperTextWhenConfiguringWorkflowV2 = "Information needed to start this workflow.";
export const FieldType = "Field type";
export const InformationForApproval = "Information for approval";
export const ProvidedFields = "Provided fields";
export const ProvideInformation = "Provide information";
export const RemoveField = "Remove field";
export const RequestInformation = "Request information";
export const RequestedInformation = "Requested information";

// Home
export const HomeAddPeopleOrTeams = "Add people or teams";
export const HomeCancelChanges = "Cancel";
export const HomeChangesNotSaved = "Changes could not be saved.";
export const HomeDaysDelayed = (days: number) => `${days} ${days > 1 ? "days" : "day"}`;
export const HomeDefaultViewNotUpdated = "Your default view has not been updated.";
export const HomeDefaultViewUpdated = "Your default view has been updated.";
export const HomeDelayed = "Delayed";
export const HomeDelayedDays = "Delayed (days)";
export const HomeDeleteView = "Delete";
export const HomeDeleteViewDialogContent = "This will also delete the view for all people you have shared it with.";
export const HomeEditView = "Edit view";
export const HomeLatestActivity = "Latest activity";
export const HomeNoColumnsSelected =
  "There are no columns selected. Click on the columns button above to add columns to the table.";
export const HomeNoUnreadMessages = "No unread messages";
export const HomePaginationLabel = (start: number, end: number, total: number) =>
  `${start}-${end} of ${total.toLocaleString()}`;
export const HomePercentComplete = "% Complete";
export const HomeRename = "Rename";
export const HomeResetDefaultView = "Reset default view";
export const HomeResetViewDialogContent = "This will discard all unsaved changes to filtering, sorting, and columns.";
export const HomeSaveAsNewView = "Save this table as a new view";
export const HomeSaveChangesToView = "Save the changes you’ve made to this view";
export const HomeSetDefaultView = "Set default view";
export const HomeSharedThisViewWithYou = (name: string) => `${name} shared this view with you.`;
export const HomeShareView = "Share view";
export const HomeShareWithEntireWorkspace = "Share with entire workspace";
export const HomeUnreadMessages = "Unread messages";
export const HomeUntitledView = "Untitled view";
export const HomeViewDeleted = (view: string) => (
  <span>
    <strong>{view}</strong> has been deleted.
  </span>
);

export const HomeViewShared = (view: string) => (
  <span>
    <strong>{view}</strong> shared list has been updated.
  </span>
);
export const HomeViewSaved = (view: string) => (
  <span>
    <strong>{view}</strong> has been saved.
  </span>
);
export const HomeWeeksDelayed = (weeks: number) => `${weeks} ${weeks > 1 ? "weeks" : "week"}`;

export const HomeCannotShareMyWorkflowsView = `You cannot share views created from "My Workflows" as other users may not have permission to view your workflows`;

export const HomeBulkEditWorkflowsSelected = (n: number) => (
  <span>
    <strong>{n}</strong> {n === 1 ? "workflow" : "workflows"} selected
  </span>
);
export const HomeBulkEditWorkflowsDeletedAllSuccessful = (n: number) => (
  <span>
    <strong>{n}</strong> {n === 1 ? "workflow" : "workflows"} deleted.
  </span>
);
export const HomeBulkEditWorkflowsDeletedPartialSuccess = (countSuccess: number, countFailure: number) => (
  <span>
    <strong>{countSuccess}</strong> {countSuccess === 1 ? "workflow" : "workflows"} deleted successfully.
    <strong>{countFailure}</strong> {countFailure === 1 ? "workflow" : "workflows"} could not be deleted.
  </span>
);
export const HomeBulkEditInProgressWorkflowsWillBeDeleted = (n: number) => (
  <span>
    <strong>{n}</strong> {n === 1 ? "workflow" : "workflows"} will be permanently deleted.
  </span>
);
export const HomeBulkEditConfirmDeleteWorkflows = (n: number) => `Delete ${n} ${n === 1 ? "workflow" : "workflows"}`;
export const HomeBulkEditYouLackNecessaryPermissions = (n: number) =>
  `You lack the necessary permissions to edit ${n === 1 ? "this workflow" : "one or more of these workflows"}.`;
export const HomeBulkEditClearSelections = (n: number) => `Clear ${n === 1 ? "selection" : "selections"}`;
export const HomeBulkEditSelectionsCleared = "Selection cleared.";
export const HomeBulkEditFailedToDeleteWorkflows = (n: number) => (
  <span>
    <strong>Failed to delete {n === 1 ? "workflow" : "workflows"}.</strong> Please try again, or contact a Regrello
    admin if you continue to see this error.
  </span>
);

// User Custom Fields
export const AllTeamMembersMustHaveSameTypeAsTeam = (teamType: string) =>
  `All users must be ${teamType} in an ${teamType} team.`;
export const AllTeamMembersMustHaveSameDomain = "All users must be in the same email domain for an internal team";
export const AssignToUserOrField = "Assign to 1 or more specific users or fields assigned in a previous task.";
export const NoDuplicateTeamMembers = "Cannot specify a user as both a team admin and member";

// Error Messages
export const ErrorFollowup = "Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorFollowupYouAreNotAnAdminOrTeamManager =
  "Only admins and team managers can view others' task summaries.";
export const ErrorViewTeamNoAccessTitle = "Failed to load team";
export const ErrorViewTeamNoAccessDescription = "Only admins and team managers can view this team.";
export const ErrorActionItemDescriptionTooLong = "Please enter a shorter description.";
export const ErrorActionItemNameMustNotBeEmpty = "Please enter a non-empty name.";
export const ErrorActionItemNameTooLong = "Please enter a shorter subject.";
export const ErrorAddComment =
  "Failed to add comment. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorCorruptDocumentExplanation =
  "The document supplied appears corrupt. Please remove this one and try uploading it again.";
export const ErrorCorruptDocuments = (documentNamesJoinedWithCommas: string) =>
  `Failed to submit task. The following documents were uploaded incorrectly: ${documentNamesJoinedWithCommas}`;
export const ErrorAdminAccessGiveToUser =
  "Failed to give admin access. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorAppOutOfDate = "Regrello is out of date and needs to refresh.";
export const ErrorApproveActionItem =
  "Failed to approve task. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorApproveActionItemParentNotSubmitted =
  "Failed to approve task. The task cannot be approved or rejected since the task is pending re-submission";
export const ErrorBoundaryTitle = "Something went wrong";
export const ErrorBoundaryDescription =
  "Please refresh, or contact a Regrello admin if you continue to see this error.";
export const ErrorBulkEdit = "Failed to perform bulk update operation.";
export const ErrorChangeActionItemState =
  "Failed to change task state. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorCreateActionItem =
  "Failed to create task. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorCreateActionItemTemplate =
  "Failed to create task. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorCreateBlueprintVariant =
  "Failed to create blueprint variant. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorCreateDocument =
  "Failed to create document. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorCreateDraftBlueprintVersion =
  "Failed to create draft blueprint version. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorCreateField =
  "Failed to create field. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorCreateRole =
  "Failed to create role. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorCreateTag =
  "Failed to create tag. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorCreateTagType =
  "Failed to create tag category. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorCreateUser =
  "Failed to create person. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorCreateWorkspace =
  "Failed to create workspace. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorCreateWorkflow =
  "Failed to create workflow. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorCreateWorkflowStage =
  "Failed to create workflow stage. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorCreateWorkflowStageTemplate =
  "Failed to create workflow stage blueprint. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorCreateWorkflowTemplate =
  "Failed to create workflow blueprint. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorCreateWorkflowFromTemplate =
  "Failed to create workflow from blueprint. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorCreateWorkflowFromTemplateTimeout =
  "Create workflow from blueprint timed out. Please ensure there are no long-running automation tasks and try again.";
export const ErrorDeleteActionItem =
  "Failed to delete task. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorDeleteActionItemTemplate =
  "Failed to delete task. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorDeleteDocument =
  "Failed to delete document. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorDeleteIntegrationConnection =
  "Failed to delete the integration connection. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorDeleteField =
  "Failed to delete field. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorDeleteForm =
  "Failed to delete form. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorDeleteTag =
  "Failed to delete tag. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorDeleteUser =
  "Failed to remove person. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorDeleteWorkflow =
  "Failed to delete workflow. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorDeleteWorkflowTemplate =
  "Failed to delete workflow blueprint. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorDeleteWorkflowStage =
  "Failed to delete workflow stage. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorDeleteWorkflowStageTemplate =
  "Failed to delete workflow stage blueprint. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorDragWorkflowActionItem =
  "Failed to reorder workflow tasks. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorDragWorkflowStage =
  "Failed to reorder workflow stages. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorDownloadDocument =
  "Failed to download document. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorEditVariantConditions =
  "Failed to edit the blueprint variant conditions. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorEditUser =
  "Failed to edit person. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorEditWorkflow =
  "Failed to edit workflow. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorEditWorkflowTemplate =
  "Failed to edit workflow. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorEndWorkflow =
  "Failed to end workflow. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorExchangingAccessToken = "Failed to exchange access token with authorization code";
export const ErrorExportingBlueprint =
  "Failed to export blueprint. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorFieldNameAlreadyExists = "A field with that name already exists.";
export const ErrorImportingBlueprint =
  "Failed to import blueprint. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorLoadBlueprintForms = "Failed to load blueprint forms";
export const ErrorLoadBlueprintVariants = "Failed to load blueprint variants";
export const ErrorLoadCustomFieldColumns = "Failed to load columns for fields";
export const ErrorLoadDocuments = "Failed to load documents";
export const ErrorLoadHome = "Failed to load home page";
export const ErrorLoadFields = "Failed to load fields";
export const ErrorLoadForms = "Failed to load forms";
export const ErrorLoadIntegrationAutomationForm = "Failed to load the form for this integration. Please try again.";
export const ErrorLoadNotificationSettings = "Failed to load notification settings";
export const ErrorLoadOptions = "Failed to load options";
export const ErrorLoadSearchResultsV2 = "Something went wrong, please try again.";
export const ErrorLoadTags = "Failed to load tags";
export const ErrorLoadTasks = "Failed to load tasks";
export const ErrorLoadTemplates = "Failed to load blueprints";
export const ErrorLoadWorkflowForms = "Failed to load workflow forms";
export const ErrorLoadUsers = "Failed to load people";
export const ErrorLogoutFailed = "Failed to sign out";
export const ErrorPauseWorkflow =
  "Failed to pause workflow. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorPublishForm =
  "Failed to publish the form. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorRejectActionItem =
  "Failed to reject task. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorRestartTask = "Failed to restart task";
export const ErrorReportProblem = "Failed to report problem";
export const ErrorResolveProblem = "Failed to resolve problem";
export const ErrorRestartAutomatedTask = "Failed to restart automated task";
export const ErrorResumeHealthCheck = "Failed to resume health check.";
export const ErrorResumeWorkflow =
  "Failed to resume workflow. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorSaveFields = "Failed to save fields";
export const ErrorSaveForm =
  "Failed to save the form. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorSaveWorkflowPeople =
  "Failed to save workflow people. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorSaveWorkflowOwner =
  "Failed to save workflow owner. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorSignIn = "Failed to sign in";
export const ErrorSignUp = "Failed to sign up, you may already have an account with us";
export const ErrorSaveWorkflowTemplatePeople =
  "Failed to save blueprint people. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorSendEmail = "Failed to send email";
export const ErrorStarActionItem =
  "Failed to star action item. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorStarWorkflow =
  "Failed to star workflow. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorStarWorkflowTemplate =
  "Failed to star blueprint. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorStartActionItemTemplate =
  "Failed to start task. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorStartWorkflow =
  "Failed to start workflow. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorStartWorkflowTimeout =
  "Start workflow timed out. Please ensure there is no long running automation tasks and try again.";
export const ErrorStartWorkflowStage =
  "Failed to start workflow stage. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorSubscribeToActionItem =
  "Failed to subscribe to task. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorSubmitTask = "Failed to submit task";
export const ErrorTriedToSetExternalOwner =
  "Failed to submit task. The workflow owner was set to an external user or team. Please set the owner to an internal user or team and try again.";
export const ErrorCannotSetOwnerFromTask =
  "Failed to submit task. The worklfow owner is controlled by the parent workflow and thus cannot be set by a task. Please contact an admin to remove this task.";
export const ErrorSwitchTenant =
  "Failed to switch workspace. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorTagNameAlreadyExists = "A tag with that name already exists.";
export const ErrorTagTypeNameAlreadyExists = "A tag category with that name already exists.";
export const ErrorResendingInvite = "Error occurred while sending email invite. Please try again later.";
export const ErrorTooManyResendInviteAttempts = "Too many attempts, please try again after 5 minutes.";
export const ErrorUnstarActionItem =
  "Failed to unstar action item. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUnstarWorkflow =
  "Failed to unstar workflow. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUnstarWorkflowTemplate =
  "Failed to unstar blueprint. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdate =
  "Failed to update. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateCorruptDocument =
  "Failed to update. The document supplied appears corrupt. Please attach a new one and try again.";
export const ErrorUpdateDueDateOrderViolation =
  "Task due dates must not be before the due date of the task they start after.";
export const ErrorUpdateActionItemTemplate =
  "Failed to update task. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateAnyNotificationsSetting =
  "Failed to update email notifications setting. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateDailyDigestSetting =
  "Failed to update daily digest setting. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateWeeklyDigestSetting =
  "Failed to update weekly digest setting. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateField =
  "Failed to update field. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateFieldAllowedValues =
  "Failed to update field values. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateNotificationEmailsSetting =
  "Failed to update notification emails setting. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateTag =
  "Failed to update tag. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateTaskAssignees = "Failed to update task assignees";
export const ErrorUpdateTaskCcs = "Failed to update task Cc's";
export const ErrorUpdateTaskDescription = "Failed to update task description";
export const ErrorUpdateTaskDueDate = "Failed to update task due date";
export const ErrorUpdateTaskName = "Failed to update task name";
export const ErrorUpdateTaskTags = "Failed to update task tags";
export const ErrorUpdateTeam =
  "Failed to update team. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateWorkspaceDisplayName = "Failed to rename workspace";
export const ErrorUpdateWorkflowStageDescription =
  "Failed to update workflow stage description. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateWorkflowStageName =
  "Failed to update workflow stage name. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateWorkflowStageStartAt =
  "Failed to update workflow stage start at. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateWorkflowStageStartingConditions =
  "Failed to update workflow stage starting conditions. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateWorkflowStageStartOnWorkflowStart =
  "Failed to update workflow stage to start when the workflow starts. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateWorkflowStageStartAfterWorkflowStageTemplateId =
  "Failed to update workflow stage to start after another workflow stage. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateWorkflowStageTemplateDescription =
  "Failed to update workflow stage blueprint description. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateWorkflowStageTemplateName =
  "Failed to update workflow stage blueprint name. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateWorkflowStageTemplateStartingConditions =
  "Failed to update workflow stage blueprint starting conditions. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateWorkflowStageTemplateStartOnWorkflowStart =
  "Failed to update workflow stage blueprint to start when the workflow starts. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUpdateWorkflowTemplateName =
  "Failed to update workflow blueprint name. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorUserNameCannotBeEmpty = "Name cannot be empty";
export const ErrorWorkflowNameAlreadyExists = "A workflow with that name already exists.";
export const ErrorWorkflowNameMustNotBeEmpty = "Please enter a workflow name.";
export const ErrorWorkflowNameTooLong = "Please enter a shorter name.";
export const ErrorWorkflowTemplateNotFound =
  "Failed to create workflow because this blueprint couldn't be found. This is a problem on our side. If the issue persists, please contact your administrator.";
export const ErrorWorkflowTemplateMustContainStageAndTask =
  "Failed to create workflow because this blueprint must first have at least one stage with one task.";
export const QueryToastMessageError =
  "Unable to fetch data, please try again or contact a Regrello admin if you continue to encounter this problem";
export const InfoProvidedFieldMustBeCompletedFirstBeforeStartingStage =
  "All provided fields must be completed before starting a stage.";
export const InfoProvidedFieldMustBeCompletedFirstBeforeStartingTaskTemplate =
  "All provided fields must be completed before starting a task.";
export const InfoTaskMustHaveAtLeastOneAssignee =
  "Task must be assigned to at least one non-deleted assignee. If the task is assigned to a field, make sure the field is not empty.";
export const InvalidRoleAssignmentMissingScimManager =
  "This role cannot be assigned to the user(s) because they do not have SCIM managers set, yet the role is linked to " +
  "a task that requires their current manager. The action could result in a task being left without an assignee.";
export const InfoTaskTemplateMustBeAssignedBeforeStarting = "Task must be assigned before starting.";
export const InfoTaskTemplateMustBeAssignedBeforeStartingStage = "All tasks must be assigned before starting a stage.";
export const InfoWorkspaceCreationForbidden = "Failed to create workspace because you don't have the permission";

// Non-ideal states
export const NonIdealStateAutomationsTitle = "No integrations";
export const NonIdealStateAutomationsDescription =
  "Third party integrations allow your workspace to connect directly to the data you need and automate tasks within workflows, and more.";
export const NonIdealStateAssignedActionItemsDescriptionInternal =
  "Tasks that you've been assigned and tasks you've assigned to others will appear here.";
export const NonIdealStateAssignedActionItemsDescriptionExternal = "Tasks that you've been assigned will appear here.";
export const NonIdealStateAssignedTeamNotFoundTitle = "Team not found";
export const NonIdealStateAssignedTeamNotFoundDescription = "It may have been removed from this workspace.";
export const NonIdealStateAssignedUserNotFoundTitle = "User not found";
export const NonIdealStateAssignedUserNotFoundDescription = "They may have been removed from this workspace.";
export const NonIdealStateAssignedActionItemsTitle = "No assigned tasks";
export const NonIdealStateBlueprintFormsDescription = "Forms included in the blueprint appear here.";
export const NonIdealStateCcdActionItemsDescription = "Tasks you've been Cc'd on will appear here.";
export const NonIdealStateCcdActionItemsTitle = "No Cc'd tasks";
export const NonIdealStateCustomFieldsAction = "Add fields";
export const NonIdealStateCustomFieldsDescription = "Fields are building blocks of workflows.";
export const NonIdealStateCustomFieldsTitle = "No fields";
export const NonIdealStateDocumentsDescription = "Documents you upload or have access to appear here.";
export const NonIdealStateFormsTitle = "No forms";
export const NonIdealStateWorkflowDocumentsDescription = "Documents included in the workflow appear here.";
export const NonIdealStateWorkflowFormsDescription = "Forms included in the workflow appear here.";
export const NonIdealStateDocumentsTaskViewDescription = "Documents included in the task appear here.";
export const NonIdealStateDocumentsTitle = "No documents";
export const NonIdealStateFetchFailed = "Data fetch failed. Please check your network status and try again.";
export const NonIdealStateHomeDescription = "Home is where you browse and manage the workflows within your workspace.";
export const NonIdealStateHomeDescriptionExternal =
  "Home is where you browse and manage the workflows within your workspace. As a member of an external organization, workflows will appear here only if you've been granted access to them by their owners.";
export const NonIdealStateHomeTitle = "Welcome to Regrello";
export const NonIdealStateHomeEmptyTitle = "This view is empty";
export const NonIdealStateMyWorkflowsTitle = "No workflows";
export const NonIdealStateStarredTitle = "No starred items";
export const NonIdealStateTagsAction = "Add tags";
export const NonIdealStateTagsDescription =
  "Tags help you organize and find content within a workspace. Available tags for use will appear here.";
export const NonIdealStateTagsTitle = "No tags";

export const NonIdealStateTeamsDescription = "Teams make collaboration easier. Any teams you create will appear here.";
export const NonIdealStateTeamsDescriptionExternal =
  "Teams make collaboration easier. Any teams you join will appear here.";
export const NonIdealStateTeamsTitle = "No teams";
export const NonIdealStateUsersDescription = "Any users you invite will appear here.";
export const NonIdealStateUsersTitle = "No users";
export const NonIdealStateWorkflowPeopleTab = "Add people or teams to your workflow";
export const NonIdealStateWorkflowTemplatePeopleTab =
  "Add people or teams here to give them access to any workflow run from this blueprint";
export const NonIdealStateWorkflowPeopleTabDescription =
  "People or teams added will receive access to view and comment or edit the workflow.";
export const NonIdealStateObjectsDescription =
  "Data Sources allow you to sync your master data from external sources (CSV file, REST API, etc). You can then use synced objects in blueprints and workflows to request and share information from the synced object's data.";
export const NonIdealStateObjectsTitle = "No data sources";
export const NonIdealStateLogsTitle = "No logs found";

// Workflow Tabs
export const WorkflowPageTabTitleAbout = "About";
export const WorkflowPageTabTitleAiMonitor = "AI Monitoring";
export const WorkflowPageTabTitleData = "Data";
export const WorkflowPageTabTitleSchedule = "Schedule";
export const WorkflowPageTabTitleStages = "Stages";
export const WorkflowPageTabTitleVariants = "Variants";

// Workflow Collaborators
export const AddWorkflowCollaboratorHelperText = "Adding people to the workflow will allow them to view this workflow";
export const AddWorkflowTemplateCollaboratorsHelperText =
  "Adding people to the blueprint will allow them to view any workflows created from this blueprint";

// Automation tasks
export const AddTask = "Add task";
export const AddApprovalTask = "Add approval task";
export const AddAutomatedTask = "Add automated task";
export const AddAutomation = "Add integration";
export const AutomatedTaskCustomHttp = "HTTP request";
export const Automation = "Automation";
export const AutomationAuthBearerTokenFieldTokenMaskSuffix = AsteriskSymbol.repeat(36);
export const AutomationAuthOAuth2FieldAccessTokenUrlLabel = "Access token URL";
export const AutomationAuthOAuth2FieldClientIdLabel = "Client ID";
export const AutomationAuthOAuth2FieldClientSecretLabel = "Client secret";
export const AutomationAuthOAuth2FieldGrantTypeOptionClientCredentials = "Client credentials";
export const AutomationAuthOAuth2FieldCredentialsLocationBasicAuthHeader = "As Basic Auth Header (default)";
export const AutomationAuthOAuth2FieldCredentialsLocationBody = "In request body";
export const AutomationAuthOAuth2FieldScopeLabel = "Scope";
export const AutomationAuthorizationTypeNone = "None";
export const AutomationAuthorizationTypeBearerToken = "Bearer token";
export const AutomationAuthorizationTypeOAuth2 = "OAuth 2.0";
export const AutomationDocuSignCreateAndSendNameTemplateHelpTooltipText =
  "A naming convention specifies the document name based on field values. Names will then be automatically generated by Regrello.";
export const AutomationDocuSignCreateAndSendWhereToSaveDocumentDescription =
  "Select a field where we should save the document from DocuSign:";
export const AutomationDocuSignCreateAndSendReceiveInfo = "Receive info";
export const AutomationDocuSignCreateAndSendSelectFieldDots = "Select field...";
export const AutomationDocuSignWorkflowFields = "Workflow fields";
export const AutomationDocuSignDocuSignFields = "DocuSign fields";
export const AutomationDocuSignSelectWorkflowFieldsDescription = (
  docusignTemplateName: string,
  learnMoreElement: JSX.Element,
) => (
  <>
    Select workflow fields to send from Regrello into the <strong>{docusignTemplateName}</strong> DocuSign template.{" "}
    {learnMoreElement}
  </>
);
export const AutomationDocuSignCreateAndSendLearnMoreLink =
  "https://regrello.notion.site/DocuSign-Integration-cf82c260c06b47f3ad8b1b395e4a6ef7";
export const AutomationDocuSignCreateAndSendDocumentForSignature = "Create and send document for signature";
export const SendInfo = "Send info";
export const LearnMore = "Learn more";
export const AddAutomatedTaskCustomHttpOutputJsonPathPlaceholder = "Enter a JSON Path expression...";
export const AddAutomatedTaskCustomHttpOutputFieldPlaceholder = "Select a field...";
export const AddAutomatedTaskCustomHttpOutputFieldHelperTextIsAllowedToAppendAllowedValues =
  "If enabled, all API response values are added to the field. If disabled, values missing from the field's options list will be rejected.";
export const AddAutomatedTaskCustomHttpOutputFieldIsAllowedToAppendAllowedValuesSecondaryLabel =
  "Allow unmatched values";
export const AutomationFieldMethodLabel = "Method";
export const AutomationFieldUrlLabel = "URL";
export const AutomationFieldHealthCheckUrlLabel = "Public health check url";
export const AddAutomatedTaskInWorkflowContextCalloutText =
  "They have already been set by the person who configured this automated task.";
export const AddAutomatedTaskInWorkflowContextCalloutTitle = "Some fields cannot be edited.";
export const AddAutomatedTaskErrorLoadingAutomations = "Encountered an error loading automated tasks list.";
export const AddAutomatedTaskNoAutomationsSetUp = "Your workspace has not set up any automated tasks yet.";
export const AddAutomatedTaskAddOne = "Go to the integrations page.";
export const AddAutomatedTaskAddFormData = "Add value";
export const AddAutomatedTaskAddJsonData = "Add value";
export const AddAutomatedTaskAddHeader = "Add header";
export const AddAutomatedTaskAddParameter = "Add parameter";
export const AddAutomatedTaskAddValue = "Add value";
export const AddAutomatedTaskAuthenticationSectionTitle = "Authentication";
export const AddAutomatedTaskAuthenticationSectionInstructions =
  "Configure how the HTTP request will authenticate with the external API when this task triggers.";
export const AddAutomatedTaskHttpRequestSectionTitle = "HTTP Request";
export const AddAutomatedTaskHttpRequestSectionInstructions =
  "Configure the HTTP request that will be sent when this task triggers.";
export const AddAutomatedTaskHttpRequestSyncSectionInstructions =
  "Configure the HTTP request that will be used for data synchronization.";
export const AddAutomatedTaskHttpRequestSectionTitleInWorkflow = "Inputs";
export const AddAutomatedTaskHttpRequestSectionInstructionsInWorkflow =
  "Configure the information that will be sent when this task triggers.";
export const AddAutomatedTaskHttpResponseSectionTitle = "HTTP Response";
export const AddAutomatedTaskHttpResponseSectionInstructions =
  "Configure how to extract data from the HTTP response. Enter a JQ expression describing where the value will be in the response payload and a field to map it to:";
export const AddAutomatedTaskHttpResponseSectionTitleInWorkflow = "Outputs";
export const AddAutomatedTaskHttpResponseSectionInstructionsInWorkflow =
  "When this task is completed, received information will be saved into the following fields:";
export const AddAutomatedTaskFieldUrlPlaceholder = "https://www.myapi.com/example";
export const AddAutomatedTaskFieldFormDataLabel = "Body (form-data)";
export const AddAutomatedTaskFieldJsonDataLabel = "Body (JSON)";
export const AddAutomatedTaskFieldHeadersLabel = "Headers";
export const AddAutomatedTaskFieldPagination = "API pagination";
export const AddAutomatedTaskFieldPaginationTooltip =
  "To sync data from a paginated API, make sure the API response includes pagination details such as: total records, next page, limit, and offset.";
export const AddAutomatedTaskFieldPaginationLimit = "Limit (page size)";
export const AddAutomatedTaskFieldPaginationInitialOffset = "Initial offset";
export const AddAutomatedTaskFieldQueryParametersLabel = "Query parameters";
export const AddAutomatedTaskFieldKeyPlaceholder = "Key";
export const AddAutomatedTaskFieldValuePlaceholder = "Value";
export const AddAutomatedTaskFieldValueCustomFieldFromWorkflow = "from workflow";
export const Connection = "Connection";
export const ConfigureAutomationAddNewConnection = "Add new connection";
export const ConfigureAutomationErrorNotificationHelp =
  "Configure who should be notified in case the health check fails.";
export const ConfigureAutomationNewConnection = "New connection";
export const ConfigureAutomationCreateSuccess = (integrationName: string, actionItemTemplateName: string) =>
  `${integrationName}: ${actionItemTemplateName} added`;
export const ConfigureAutomationEditSuccess = (integrationName: string, actionItemTemplateName: string) =>
  `Changes saved for ${integrationName}: ${actionItemTemplateName}`;
export const ConfigureAutomationHealthCheckFrequency = "Health check";
export const ConfigureAutomationIntegrationBoxTitle = "Box";
export const ConfigureAutomationIntegrationBoxHelperText = "Secure file sharing";
export const ConfigureAutomationIntegrationDocuSignTitle = "DocuSign";
export const ConfigureAutomationIntegrationDocuSignHelperText = "eSignatures and agreements";
export const ConfigureAutomationIntegrationDocuSignDeveloperTitle = "DocuSign (Developer)";
export const ConfigureAutomationIntegrationConnectionIssueTooltip =
  "This integration requires attention from an administrator.";
export const ConfigureAutomationIntegrationGoogleDriveTitle = "Google Drive";
export const ConfigureAutomationIntegrationGoogleDriveHelperText = "File storage and backup";
export const ConfigureAutomationIntegrationNetsuiteTitle = "Netsuite";
export const ConfigureAutomationIntegrationNetsuiteHelperText = "ERP, inventory, and more";
export const ConfigureAutomationIntegrationOracleTitle = "Oracle";
export const ConfigureAutomationIntegrationOracleHelperText = "Cloud applications and platform";
export const ConfigureAutomationIntegrationSapTitle = "SAP";
export const ConfigureAutomationIntegrationSapHelperText = "Business applications";
export const ConfigureAutomationIntegrationSharePointTitle = "SharePoint";
export const ConfigureAutomationIntegrationSharePointHelperText = "Team collaboration";
export const ConfigureAutomationIntegrationCustomTitle = "Custom HTTP";
export const ConfigureAutomationIntegrationCustomHelperText = "Configure your own API request";
export const ConfigureAutomationConnectionCreatedAt = (createdAtDateString: string) =>
  `Created at ${createdAtDateString}`;
export const ConfigureAutomationSelectApplication = "Select application";
export const ConfigureAutomationSelectAutomation = "Select integration";
export const ConfigureAutomationSelectConnectionOrAddConnection = "Select connection or add a new connection";
export const ConfigureAutomationSelectAuthenticationEditConnection = "Edit connection";
export const ConfigureAutomationSetAdditionalDetails = "Set additional details";
export const ConfigureAutomationSyncFrequency = "Sync frequency";
export const ConfigureAutomationSyncRepeatsAt = "Repeats at";
export const ConfigureAutomationSyncRepeatsAtTooltip =
  "Sync will repeat at specified hour every day. May be delayed due to system load.";
export const ConfigureAutomationSyncFailures = "Sync failures";
export const ConfigureAutomationSyncSendAlerts = "Send alerts";
export const ConfigureAutomationSyncNotifyAdmins = "Notify all admins";
export const ConfigureAutomationSyncNotifyParties = "Notify specific people only";
export const ConfigureAutomationSyncIntervalDaily = "Daily";
export const ConfigureAutomationSyncIntervalDisabled = "Disabled";
export const ConfigureAutomationSyncIntervalHourly = "Hourly";
export const ConfigureAutomationSyncIntervalManual = "Manual";
export const ConfigureAutomationStepHealthCheckTitle = "Configure health check";
export const ConfigureAutomationStepHealthCheckHelp =
  "The Health check endpoint will be pinged and after 3 consecutive failed attempts, a connection failure will be recorded and users notified.";
export const ConfigureAutomationEditDocuSignIntegration = "Edit DocuSign integration";
export const ConfigureAutomationSelectAuthenticationEmptyState = (integrationName: string) => (
  <span>Sign in to {integrationName} and give the required permissions to connect this automation.</span>
);
export const ConfigureAutomationSignIntoService = (serviceName: string) => `Sign in to ${serviceName}`;
export const ConfigureCustomIntegration = "Configure custom integration";
export const ConfigureIntegrationAutomationEditSuccess = (actionItemTemplateName: string) =>
  `Changes saved to ${actionItemTemplateName}`;
export const DeleteAutomationConfirm = (integrationName: string, name: string) => (
  <span>
    Are you sure you want to delete{" "}
    <strong>
      {integrationName}: {name}
    </strong>{" "}
    integration?
  </span>
);
export const DeleteAutomationSuccess = (integrationName: string, actionItemTemplateName: string) =>
  `${integrationName}: ${actionItemTemplateName} deleted`;
export const EditAutomatedTask = "Edit automated task";
export const Integration = "Integration";
export const Integrations = "Integrations";
export const AutomatedTaskTestHeadingInputs = "Inputs";
export const AutomatedTaskTestHeadingOutputs = "Outputs";
export const AutomatedTaskTestEmptyStateNoInputs = "No inputs";
export const AutomatedTaskTestEmptyStateOutputs = <span>Click &ldquo;Test&rdquo; to see outputs</span>;
export const AutomatedTaskTestButtonText = "Test";
export const AutomatedTaskTestErrorNoResponseTitle = "No response received";
export const AutomatedTaskTestInputPlaceholder = "Enter an example value...";
export const AutomatedTaskTestOutputLabelMethod = "Method";
export const AutomatedTaskTestOutputLabelUrl = "URL";
export const AutomatedTaskTestOutputLabelStatus = "Status";
export const AutomatedTaskTestOutputLabelNumRetries = "Number of retries";
export const AutomatedTaskTestOutputBody = "Body";
export const AutomatedTaskTestOutputFields = "Extracted fields";
export const AutomatedTaskTestOutputFieldsDescription =
  "These are the fields that would be created in your workflow when this automated task is completed.";

// Integration automation plugins - base
export const IntegrationAutomationFormSectionInputsTitle = (integrationName: string) => `${integrationName} inputs`;
export const IntegrationAutomationFormSectionInputsDescription = (integrationName: string) =>
  `Select custom fields to send to ${integrationName}.`;
export const IntegrationAutomationFormSectionOutputsTitle = (integrationName: string) => `${integrationName} outputs`;
export const IntegrationAutomationFormSectionOutputsDescription = (integrationName: string) =>
  `Select fields to store data received from ${integrationName}.`;
export const IntegrationAutomationFormSectionOutputsHelperText = "We'll save the data in a new instance of this field.";

// Integration automation plugins - specific
export const IntegrationAutomationStorageSaveFileToFolderPluginTitle = "Save file to folder";
export const IntegrationAutomationStorageSaveFileToFolderPluginFolderLabel = "Folder";
export const IntegrationAutomationSharepointSiteName = "Site";

export const IntegrationAutomationDocusignCreateDraftFromTemplatePluginTemplateTitle = "Create draft from template";
export const IntegrationAutomationDocusignCreateDraftFromTemplatePluginTemplateSecionTitle = "DocuSign template";
export const IntegrationAutomationDocusignCreateDraftFromTemplatePluginTemplateSecionDescription =
  "Select a DocuSign template to use.";
export const IntegrationAutomationDocusignCreateDraftFromTemplatePluginTemplateStandaloneLabel = "Template";
export const IntegrationAutomationDocusignCreateDraftFromTemplatePluginTemplateLabel = "DocuSign template";

export const IntegrationAutomationDocusignCreateDraftAndSendForSignatureReceiveSignedPdfPluginTitle =
  "Create and send for signature";
export const IntegrationAutomationDocusignSendForSignatureAndReceiveSignedPdfPluginTitle = "Send for signature";
export const IntegrationAutomationDocusignSendForSignatureAndReceiveSignedPdfPluginDraftFieldLabel = "DocuSign draft";
export const IntegrationAutomationDocusignSendForSignatureAndReceiveSignedPdfPluginDraftFieldHelperText =
  "Select a DocuSign draft from earlier in the workflow.";
export const IntegrationAutomationDocusignSendForSignatureAndReceiveSignedPdfPluginDetailViewCalloutText =
  "It will be completed automatically when all parties finish signing.";
export const IntegrationAutomationDocusignSendForSignatureAndReceiveSignedPdfPluginDetailViewCalloutTitle =
  "This automated task is waiting for signatures via DocuSign.";
export const IntegrationAutomationDocusignSendForSignatureAndReceiveSignedPdfPluginDetailViewCalloutTextProblem =
  "You must go to the task that generates the DocuSign draft and re-run it.";
export const IntegrationAutomationConnectionBroken = "Connection issue. Please set up a different connection.";
export const IntegrationAutomationConnectionIssue =
  "Connection issue. Please contact your system administrator to update the connection.";
export const IntegrationAutomationConnectionNominal = "Connection is operating nominally.";
export const IntegrationAutomationDocusignSendForSignatureAndReceiveSignedPdfPluginDetailViewCalloutTitleProblem =
  "This automated task's problem cannot be resolved manually.";
export const ActionItemViewRequestedInfoRerunDialogCancelButtonText = "Save and complete";
export const ActionItemViewRequestedInfoRerunDialogContent =
  "Restarting will create a new draft from the DocuSign template and will re-send the envelopes for signature.";
export const ActionItemViewRequestedInfoRerunDialogTitle = "Do you want to re-run the automated task?";
export const ActionItemViewRequestedInfoSuggestCompleteDialogText = "Do you want to complete the task?";
export const ActionItemViewRequestedInfoSuggestCompleteDialogTitle = "All required fields have been filled out";

// Storage Folder Panel
export const StorageFolderPanelInitialPanelTitle = "Select a folder";
export const StorageFolderPanelButtonPlaceholder = "Select a folder...";

// OAuth callback page
export const OauthCallbackErrorTitle = (integrationDisplayName: string) =>
  `Failed to authenticate ${integrationDisplayName}`;
export const OauthCallbackErrorDescriptionMissingCodeAndState = "Missing 'code' and 'state' in URL query parameters";
export const OauthCallbackErrorDescriptionMissingCode = "Missing 'code' in URL query parameters";
export const OauthCallbackErrorDescriptionMissingState = "Missing 'state' in URL query parameters";
export const OauthCallbackErrorDescriptionMissingIntegration = (integrationDisplayName: string) =>
  `Could not load or locate Regrello's pre-configured ${integrationDisplayName} integration.`;
export const OauthCallbackSuccessTitle = (integrationDisplayName: string) =>
  `Successfully authenticated with ${integrationDisplayName}`;
export const OauthCallbackSuccessDescription = "You may close this window.";

// Line Items
export const ViewAssignedTasks = "View assigned tasks";

// Task status
export const TaskStatusBarDueDateDueOn = (dueDate: string) => `Due ${dueDate}`;

// Regrello Lite
export const MultiEmailHelperText = "Adding people not currently in this workspace will add them to this workspace.";
export const MultiEmailPlaceholderText = "Emails, comma separated";
export const SingleEmailPlaceholderText = "An email address";
export const YouNoLongerHaveAccessToThisContent = "You no longer have access to this content.";

// Data Grid
export const SortAscending = "Sort ascending";
export const SortDescending = "Sort descending";
export const SortedAscending = "Sorted ascending";
export const SortedDescending = "Sorted descending";
export const FilteringDisabled = "Filtering is disabled for this column";

// Teams
export const AddTeam = "Add team";
export const AddTeams = "Add teams";
export const AtDomain = (domain: string) => `@${domain}`;
export const DeleteDeleteConfirm = (name: string) => (
  <span>
    Are you sure you want to delete the <strong>{name}</strong> team?
  </span>
);
export const EditTeam = "Edit team";
export const ConfirmSwitchTeamTypeInstruction =
  "Switching the team type will clear any people assignments you’ve made.";
export const ErrorAddingToWorkspace = (value: string) => `${value} could not be added to this workspace. Try again.`;
export const ErrorCreateTeamEmailAlreadyExists = (email: string) =>
  `Team could not be created. A team or user with email "${email}" already exists.`;
export const ErrorCreateTeamNameAlreadyExists = (name: string) =>
  `Team could not be created. A team with name "${name}" already exists.`;
export const ErrorRemovingFromTemplate = (name: string) => `${name} could not be removed this workflow. Try again.`;
export const ErrorRemovingFromWorkflow = (name: string) => `${name} could not be removed this workflow. Try again.`;
export const ErrorRemovingFromWorkspace = (name: string) => `${name} could not be removed this workspace. Try again.`;
export const ErrorUpdatingWorkspacePermission = (name: string) =>
  `${name}’s workspace permissions could not be changed. Try again.`;
export const SuccessAddingToWorkspace = (name: string) => `${name} has been added to this workspace.`;
export const SuccessRemovingFromWorkflow = (name: string) => `${name} has been removed from this workflow.`;
export const SuccessRemovingAccessFromWorkflow = (name: string) => `${name} can no longer access this workflow.`;
export const SuccessRemovingFromWorkspace = (name: string) => `${name} has been removed from this workspace.`;
export const SuccessUpdating = (name: string) => `${name} have been updated.`;
export const SuccessUpdatingTemplatePermission = (name: string, valueAfter: string) =>
  `${name}'s blueprint permissions have been changed to "${valueAfter}".`;
export const SuccessUpdatingWorkflowPermission = (name: string, valueAfter: string) =>
  `${name}'s workflow permissions have been changed to "${valueAfter}".`;
export const SuccessUpdatingWorkspacePermission = (name: string, valueAfter: string) =>
  `${name}'s workspace permissions have been changed to "${valueAfter}".`;

export const SyncObjectLearnMoreUrl =
  "https://regrello.notion.site/Synced-Objects-via-CSV-files-162d874d0dd749538ccdc214764e075c";

// Toast Message
export const ToastTitleSuccess = "Success!";
export const ToastTitleError = "There was a problem";

// Workflow Stage Start Conditions
export const SelectStage = "Select stage";

// Filters and conditions
export const ConditionConnectiveIf = "If";
export const ConditionConnectiveAnd = "And";
export const ConditionConnectiveAndIf = "And if";
export const ConditionConnectiveOrIf = "Or if";
export const ConditionAfter = "After";
export const ConditionAfterName = (name: React.ReactNode) => <span>After {name}</span>;
export const AddFilter = "Add filter";
export const AddCondition = "Add condition";
export const AddConditionGroup = "Add condition group";
export const AddConditionRule = "Add rule";
export const ConditionAllHelperText = "All of the following are true";
export const ConditionAnyHelperText = "Any of the following is true";
export const ConditionBefore = "Before";
export const ConditionConditionsMet = "Conditions met";
export const ConditionDocumentFilenamesContain = "Filenames contain";
export const ConditionDocumentFileTypesContain = "Has file type";
export const ConditionThisField = "this field";
export const ConditionThisSection = "this section";
export const ConditionOnOrBefore = "On or before";
export const ConditionIsChecked = "Is checked";
export const ConditionIsNotChecked = "Is not checked";
export const ConditionIsCountEqualTo = "Is count equal to";
export const ConditionIsEmpty = "Is empty";
export const ConditionIsNotEmpty = "Is not empty";
export const ConditionIsGreaterThan = "Is greater than";
export const ConditionIsGreaterThanOrEqual = "Is greater than or equal to";
export const ConditionIsLessThan = "Is less than";
export const ConditionIsLessThanOrEqual = "Is less than or equal to";
export const ConditionIs = "Is";
export const ConditionIsExactly = "Is exactly";
export const ConditionIsAny = "Is any";
export const ConditionIsAnyOf = "Is any of";
export const ConditionIsNoneOf = "Is none of";
export const ConditionIsNot = "Is not";
export const ConditionIsEqual = "Is equal to";
export const ConditionIsNotEqual = "Is not equal to";
export const ConditionIsBetween = "Is between";
export const ConditionIsAfterOrOn = "Is after or on";
export const ConditionIsBeforeOrOn = "Is before or on";
export const ConditionIsOnDate = "Is on same calendar day";
export const ConditionIsNotOnDate = "Is not on same calendar day";
export const ConditionIsIn = "Is in";
export const ConditionIsNotIn = "Is not in";
export const ConditionOnOrAfter = "On or after";
export const ConditionContains = "Contains";
export const ConditionContainsAll = "Contains all of";
export const ConditionContainsAny = "Contains any of";
export const ConditionContainsNone = "Contains none of";
export const ConditionDoesNotContain = "Does not contain";
export const ConditionStartsWith = "Starts with";
export const ConditionEndsWith = "Ends with";
export const ConditionSmartRules = "Smart rules";
export const ConditionSmartRulesInfo = "Show/hide, highlight, or take other actions on this field based on conditions.";
export const ConditionSmartRulesInfoForSection =
  "Show/hide, highlight, or take other actions on this section based on conditions.";
export const ConditionSmartRuleDisabledHint = "Cannot add a rule to the first form element";
export const ConditionSmartRuleInUseTooltip = "Rule of this category is already in use";
export const ConditionSmartRuleSyncedObjectDisallowedTooltip = "Rules cannot be evaluated based on a synced object.";
export const EditConditionRule = "Edit rule";
export const LocalTimeZone = "(local time zone)";

// Skipped Stage Status
export const SkippedStageToggleTooltip = "Double-click to expand/collapse";

// Schedule time form.
export const ScheduleTimeDaysToCompletePlaceholder = "1-365";

export const StageNameFieldPlaceholder = "Untitled stage";
export const SelectCondition = "Select condition";
export const SelectProperty = "Select property";

// Stage Start Conditions Info Banner
export const ConditionBannerStageStartAfterV2 = (stageName: string) => `After ${stageName}`;
export const ConditionBannerStageStartWorkflowStartsV2 = "When workflow starts";
export const ConditionSummaryString = (args: {
  fieldChip: JSX.Element;
  operator: JSX.Element;
  displayValue1: JSX.Element | undefined;
  displayValue2: JSX.Element | undefined;
}) => (
  <>
    {args.fieldChip}
    <>
      <> {args.operator} </>
      {args.displayValue1 != null && args.displayValue1}
      {args.displayValue2 != null && " and "}
      {args.displayValue2 != null && args.displayValue2}
    </>
  </>
);

export const ConditionTooltipAfterTheFollowingStages = "After the following stage(s):";

// `AutomationExtractValueFromJsonErrorCode`s
export const AutomationResponseParserFailedToExtractBooleanDueToTypeMismatch = (values: string) => (
  <>
    Failed to extract values <code>{values}</code> into a checkbox field due to a type mismatch
  </>
);
export const AutomationResponseParserFailedToExtractDateDueToTypeMismatch = (values: string) => (
  <>
    Failed to extract values <code>{values}</code> into a date field due to a type mismatch
  </>
);
export const AutomationResponseParserFailedToExtractDocumentDueToTypeMismatch = (values: string) => (
  <>
    Failed to extract values <code>{values}</code> into a document field due to a type mismatch
  </>
);
export const AutomationResponseParserFailedToExtractNumberDueToTypeMismatch = (values: string) => (
  <>
    Failed to extract values <code>{values}</code> into a number field due to a type mismatch
  </>
);
export const AutomationResponseParserMultipleValuesReceivedForBooleanField = (values: string) => (
  <>
    Received too many values <code>{values}</code> for a number field
  </>
);
export const AutomationResponseParserMultipleValuesReceivedForDateField = (values: string) => (
  <>
    Received too many values <code>{values}</code> for a checkbox field
  </>
);
export const AutomationResponseParserMultipleValuesReceivedForDocumentField = (values: string) => (
  <>
    Received too many values <code>{values}</code> for a checkbox field
  </>
);
export const AutomationResponseParserMultipleValuesReceivedForNumberField = (values: string) => (
  <>
    Received too many values <code>{values}</code> for a checkbox field
  </>
);
export const AutomationResponseParserNoValuesExtractedFromResponse = (_values: string) => (
  <>No matching field values were found to extract from the response</>
);
export const AutomationResponseParserReceivedValueDoesNotExist = (values: string) => (
  <>
    Could not extract values <code>{values}</code> because they do not map to field values in Regrello
  </>
);
export const AutomationResponseParserFailedToExtractRoleMismatch = (values: string, roleName: string) => (
  <>
    Could not extract values <code>{values}</code> because they do not map to the role <code>{roleName}</code> in
    Regrello.
  </>
);

// Bulk actions
export const TableBulkActionsEditAssignees = "Edit assigned to";
export const TableBulkActionsEditCc = "Edit cc";
export const TableBulkActionsEditDueDate = "Edit due date";
export const ButtonBulkActionsButtonDisabled =
  "One or more selected tasks have either ended or you lack the necessary permissions.";
export const BulkEditDateFailure = (dueDate: string | undefined, numSucceeded: number) => {
  const resolvedDueDate = dueDate != null ? `to ${dueDate}` : "";
  return `Successfully updated due date ${resolvedDueDate} for ${numSucceeded} tasks. `;
};
export const BulkEditDateSuccess = (dueDate: string | undefined, numSucceeded: number) => {
  const resolvedDueDate = dueDate != null ? `to ${dueDate}` : "";
  return `Successfully updated due date ${resolvedDueDate} for ${numSucceeded} tasks. `;
};
export const BulkEditPartyFailure = (
  action: "add" | "remove",
  partyType: "assignees" | "ccs",
  numSucceeded: number,
) => {
  const preposition = action === "add" ? "to" : "from";
  return `Failed to ${action} ${partyType} ${preposition} ${numSucceeded} tasks. `;
};
export const BulkEditPartySuccess = (
  action: "added" | "removed",
  partyType: "assignees" | "ccs",
  numSucceeded: number,
) => {
  const preposition = action === "added" ? "to" : "from";
  return `Successfully ${action} ${partyType} ${preposition} ${numSucceeded} tasks. `;
};
export const NoChanges = "No changes were made.";

// Regrello Lite confirmation page
export const SendEmailInvite = "Send email invite";
export const RegrelloLiteAcceptInviteInstruction = (createdBy: string, tenantName: string) => (
  <span>
    <strong>{createdBy}</strong> has invited you to join the Regrello workspace <strong>{`"${tenantName}"`}</strong> by
    assigning you this task. You don’t need to sign in to Regrello to use it. If you would like to sign in to complete
    your work, please click send email invite.
  </span>
);
// Data Sources
export const AddObject = "Add object";
export const AddDataSource = "Add data source";
export const CsvFileSecondaryText = "Import data from a file";
export const DeleteSyncedObjectConfirmation = (name: string) => (
  <>
    <p>
      Data source <strong>{name}</strong> will be removed from the workspace.
    </p>
    <br />
    <p>
      This will not affect the existing workflows using this data source but you will not be able to use this in any new
      blueprint or workflow.
    </p>
  </>
);
export const DeleteSyncedObjectError =
  "Failed to delete synced object. Please try again, or contact a Regrello admin if you continue to see this error.";
export const DeleteSyncedObjectSuccess = (name: string) => `${name} was successfully deleted.`;
export const PrimaryFields = "Primary field(s)";
export const RestApiSecondaryText = "Import data via HTTP";
export const SyncedObjectsViewLog = "View log";
export const SyncedObjectsForceSync = "Force sync";
export const SyncedObjectsForceSyncSuccess = "Synchronization initiated";
export const SyncedObjectsForceSyncError = "Force sync failed";
export const SyncedObjectsStopSync = "Stop sync";
export const SyncedObjectsStopSyncSuccess = "Synchronization schedule paused";
export const SyncedObjectsStopSyncError = "Stop sync failed";
export const SyncedObjectsSync = "Sync";
export const SyncedObjectsResumeSync = "Resume sync";
export const SyncedObjectsResumeSyncSuccess = "Synchronization schedule resumed";
export const SyncedObjectsResumeSyncError = "Resume sync failed";
export const SyncedObjectsAddAndStartSync = "Add and start sync";
export const SyncedObjectsInvalidColumns =
  "Retrieved data does not match currently edited schema. Only matching data can be used.";
export const SyncedObjectsInvalidResponse = "Invalid response";
export const SyncedObjectsInvalidResponseInfo = "Retrieved data does not match expected shape";
export const SyncedObjectsDialogStepAddSyncedObjectsTopInformation =
  "Create a synced field from the columns and records in your CSV file for use in your workflows.";
export const SyncedObjectsDialogStepAddSyncedObjectsTopInformationEditing = (name: string) => (
  <span>
    Update <strong>{name}</strong> object via CSV file.
  </span>
);
export const SyncedObjectsDialogStepAddSyncedObjectsFormattingTipsTitle = "Formatting tips";
export const SyncedObjectsDialogStepAddSyncedObjectsFormattingTipsListItems = [
  "Field names should be located in the header row",
  "Primary field(s) should not contain duplicate or missing values",
  "CSV file should be delimited with comma",
];
export const SyncedObjectsDialogStepAddSyncedObjectsDropZone = (className: string) => (
  <span>
    Drag and drop or <span className={className}>click to select</span> a CSV file from your computer.
  </span>
);
export const SyncedObjectsDialogStepAddSyncedObjectsDropZoneFileTypeError =
  "Only files with the extension .csv can be uploaded.";
export const SyncedObject = "Synced object";
export const DataSources = "Data Sources";
export const SyncedObjectsDialogStepConfigureObjectEditingDisabled =
  "These settings cannot be edited after the initial creation of a Synced Object. They are being shown for reference only.";
export const SyncedObjectsDialogStepConfigureObjectNameDescription =
  "This will be used by workflow and blueprint owners to add the synced object to the blueprint or workflow.";
export const SyncedObjectsDialogStepConfigureObjectFieldsDescription =
  "Select one field that contains unique values or up to 3 fields that create a unique combination. This will be used to update the data source during each sync.";
export const SyncedObjectsDialogStepConfigureObjectAlreadyExistsError = "An object with this name already exists";
export const SyncedObjectsDialogStepConfigureObjectPrimaryFieldNotUniqueError =
  "We found duplicate or empty values in your selected primary field column. Please select another column or a combination of up to 3 columns that have unique values.";
export const SyncedObjectsDialogStepConfigureObjectColumnsMissmatchError =
  "Unrecognized file. Only a CSV file with the same fields as original object can be uploaded.";
export const SyncedObjectsDialogStepConfigureObjectTitle = "Configure your object";
export const SyncedObjectsDialogStepConfigureObjectTooManyPrimaryFields = "Please select no more than 3 columns.";
export const SyncedObjectsDialogStepSelectCsvTitle = "Select CSV file";
export const SyncedObjectsDialogStepUploadSummaryTitle = "Adding object";
export const SyncedObjectsDialogStepUploadSummaryTitleForEditing = "Updating object";
export const SyncedObjectsDialogStepUploadSummaryProcessing = "Uploading your object...";
export const SyncedObjectsDialogStepUploadSummaryProcessingFurther = "Processing and loading your object's data...";
export const SyncedObjectsDialogStepUploadSummaryGenericError =
  "An error occurred while processing this CSV. Please contact us if you continue to have trouble.";
export const SyncedObjectsDialogStepUploadSummaryErrorNotAdded = (objectName: string) => (
  <>
    <strong>{objectName}</strong> object was not added.
  </>
);
export const SyncedObjectsDialogStepUploadSummaryCompleteAdded = (objectName: string) => (
  <>
    <strong>{objectName}</strong> object has been added to the processing queue!
  </>
);
export const SyncedObjectFieldsCount = (fieldsCount: number) => `${fieldsCount} fields`;
export const SyncedObjectsRestApiLearnMore = "Learn more about adding a REST API connection";
export const UpdateObject = "Update object";

// Personalization
export const Personalization = "Personalization";
export const PersonalizationDescription =
  "Customize key elements of the Regrello experience to match your company's unique brand.";
export const PersonalizationFormLabelSidebar = "Sidebar";
export const PersonalizationFormLabelEmail = "Emails";
export const PersonalizationFormLabelLogo = "Logo";
export const PersonalizationFormLabelForegreoundColor = "Text & Icons";
export const PersonalizationFormLabelBackgroundColor = "Background";
export const PersonalizationFormLabelSameAsSidebar = "Same as sidebar";
export const PersonalizationFormSidebarHint =
  "This customizes the app sidebar and the top banner of tasks accessed via email. Contact your Brand team for the correct colors and logo file.";
export const PersonalizationFormEmailHint =
  "This customizes your invite and workflow-related emails. Contact your Brand team for the correct logo file.";
export const PersonalizationFormTips = "Tips:";
export const PersonalizationFormTip = "Tip:";
export const PersonalizationFormImageHint = "Transparent PNG files are recommended. They'll be resized to fit.";
export const PersonalizationFormColorHint = "When requesting colors, make sure to ask for the hexadecimal versions.";
export const PersonalizationFormDropHint = "Click or drop to attach image";
export const PersonalizationFormMutationError =
  "Failed to update workspace personalization settings. Please try again, or contact a Regrello admin if you continue to see this error.";
export const PersonalizationFormResetSidebar = "Reset sidebar";
export const PersonalizationFormResetTaskEmails = "Reset emails";

// Split tasks
export const SplitTaskConfigureAssigneesHelperText = "When the task splits into multiple:";
export const SplitTaskIconTooltipContentSplitAssignees =
  "This task will split into multiple and be separately assigned to each person or team from";
export const SplitTaskIconTooltipContentDoNotSplitAssignees = "This task will split into multiple tasks based on";
export const SplitWorkflowIconTooltipContentDoNotSplitAssignees =
  "This workflow will split into multiple workflows based on";
export const SplitTaskFormSectionSplitterFieldDescription = (
  actionItemType: "linkedWorkflow" | "default" | "standaloneApproval",
) => `Split this ${actionItemType === "linkedWorkflow" ? "workflow" : "task"} into multiple based on`;
export const SplitTaskDoSplitAssigneesLabel = "Assign all tasks separately to each person or team from:";
export const SplitTaskDoNotSplitAssigneesLabel = "Assign all tasks to this task's assignees";
export const SplitTaskDoNotSplitAssigneesIfHasAssigneesLabel = "Assign all tasks to:";
export const SplitTasks = (numSplitTasks: number) => `${numSplitTasks} split task${numSplitTasks === 1 ? "" : "s"}`;
export const SplitterTaskSelectDefaultStartOption =
  "Select a multi-valued field from earlier in the workflow. The field cannot come from a split task.";
export const SplitterTaskSelectLinkedWorkflowStartOption =
  "Select a multi-valued field sent as info to the other workflow.";
export const Via = "Via";

// Admin > settings > SCIM
export const AccessToken = "Access token";
export const AlternateEmails = "Alternate emails";
export const CannotDeleteScimProvisionedUser = "Cannot delete an actively SCIM provisioned user.";
export const CannotEditScimProvisionedUser = "Cannot edit an actively SCIM provisioned user.";
export const CannotEditScimServiceAccountUser = "Cannot edit SCIM service account user.";
export const ConfigureSettingsTitle = "Configure settings";
export const ConfigureAlertsLabel = "Send alerts when the user directory sync fails (default)";
export const ConfigureAlertsHelperText = "Alerts will be sent via email";
export const ConfigureManagePermissionsLabel = "Control user permissions via groups";
export const ConfigureScimManagedPermissionsEnabledHelperText =
  "Editing user permissions via Regrello will be disabled.";
export const ConfigureScimManagedPermissionsDisabledHelperText =
  "Admins can change user permission levels in Regrello.";
export const ConfigureScim = "Configure SCIM";
export const ScimURL = "SCIM URL";
export const ScimUrlHelperText = "The URL of your account’s SCIM 2.0 REST API";
export const ScimAccessTokenHelperText =
  "The access token won’t be accessible after leaving this screen. Copy it into the user active directory or " +
  "regenerate if a new one is required.";
export const ConfigureScimIntegrationHeading = "Configure your SCIM integration:";
export const ScimAuthenticationMethod = "Authentication method";
export const ScimAuthenticationMethodHelperText = "Your preferred method of authenticating the SCIM integration";
export const ScimAuthenticationMethodAccessToken = "Access token (generated by Regrello)";
export const ScimAuthenticationMethodOAuth2 = "OAuth 2.0 (secret tokens are generated by your IdP)";
export const JWKSEndpoint = "JSON Web Key Set (JWKS) endpoint";
export const JWKSEndpointHelperText =
  "Endpoint where we can fetch public keys for verifying the signature of JWT tokens. The endpoint should be HTTPS and provided by the IdP.";
export const AllowedSubClaim = "Allowed sub claim";
export const AllowedSubClaimHelperText = "Used to restrict access to the SCIM endpoints to specific service account.";
export const AllowedIssuerClaim = "Allowed issuer claim";
export const AllowedIssuerClaimHelperText = "Token issuer of the access tokens.";
export const AllowedAudienceClaim = "Allowed audience claim";
export const AllowedAudienceClaimHelperText = "Token audience of the access tokens.";
export const RegisterServiceAccount = "Register service account";

// Admin > settings > SCIM > attribute mapping
export const AddCustomAttribute = "Add custom attribute";
export const CustomRegrelloUserAttribute = "Regrello user attribute";
export const CustomScimAttribute = "Custom SCIM attribute";
export const DefaultScimCustomAttributeDescription = "SCIM custom attribute created from application.";
export const InfoSectionOnAttributeMapping =
  "Make sure the following attributes have been correctly mapped when setting up provisioning in your user directory.";
export const ConfigureScimWarningMessage =
  "Please ensure you copy the SCIM URL and access token and complete the remaining steps of the SCIM " +
  "configuration before registering the application in the user active directory. It may take up to 2 minutes for " +
  "the SCIM connection to become available in the user active directory for setup. If the access token is grayed out and " +
  "you need a new one, please regenerate the credential to copy a new token and invalidate the old one.";
export const LearnMoreAboutSettingUpScimInUserAD =
  "Learn more about setting up user provisioning via SCIM in user active directory";
export const ScimConfigureAlertWarningMessage =
  "After the SCIM connection has been enabled, the user directory will start syncing based on your directory’s sync policies.";
export const LearnMoreAboutSyncingUserDirectory = "Learn more about syncing your user directory";
export const LearnMoreAboutAttributeMapping = "Learn more about attribute mapping in your user directory";
export const RegrelloAlternateEmailAttribute = "Alternate emails";
export const RegrelloUserAttributes = "Regrello user attributes";
export const RegrelloUserEmailAttribute = "Email";
export const RegrelloUserManagerAttribute = "Manager";
export const RegrelloUserNameAttribute = "Name";
export const RegrelloUserScimIdAttribute = "Scim ID";
export const RegrelloUserStatusAttribute = "Status";
export const RegrelloUserTimezoneAttribute = "Timezone";
export const RegrelloUserUsernameAttribute = "Username";
export const RegrelloUserWorkCountryAttribute = "Work Country";
export const ScimActiveAttribute = "active";
export const ScimAttributes = "SCIM attributes";
export const ScimExternalIdAttribute = "externalId";
export const ScimHomeEmailAttribute = `emails[type eq "home"].value`;
export const ScimManagerAttribute = "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User:manager";
export const ScimNameFormattedAttribute = "name.formatted";
export const ScimOtherEmailAttribute = `emails[type eq "other"].value`;
export const ScimTimezoneAttribute = "timezone";
export const ScimUsernameAttribute = "userName";
export const ScimWorkCountryAttribute = `addresses[type eq "work"].country`;
export const ScimWorkEmailAttribute = `emails[type eq "work"].value`;
export const SelectAttributesToSync = "Select attributes to sync";
export const ToastSuccessOnReplaceScimCustomAttributes = "Successfully saved the new custom attribute(s)";
export const ToastFailureOnReplaceScimCustomAttributes = "Failed to save the new custom attribute(s)";

// SCIM party avatar
export const ManagerSuffix = "'s manager";
export const Manager = "manager";
export const ScimIntegration = "SCIM Integration";

export const ClientId = "Client id";
export const ClientSecret = "Client secret";
export const Enabled = "Enabled";
export const ErrorExtractingWorkspaceID = "Failed to extract workspace ID from scimUrl";
export const ErrorGenerateAccessToken = "Failed to generate access token";
export const GenerateScimCredentials = "Generate credentials";
export const LastSyncStatus = "Last sync status";
export const NoScimConnectionText = "There has not been a request from user active directory.";
export const ManualConfigurationOptionLabel = "Manually (default)";
export const ManualConfigurationOptionDescription =
  "Users and teams are manually added, removed, and updated. Please select this option to disconnect from any " +
  "existing user directory application, if applicable, before creating a new connection to another one.";
export const NotActivelyScimProvisionedTooltipText =
  "This user was initially added via SCIM but is not currently being provisioned.";
export const RoleNotActivelyScimProvisionedTooltipText =
  "This role was initially managed via SCIM but is now manually provisioned.";
export const NotifyAll = "Notify all admins";
export const NotifySpecificAdmins = "Notify specific admins only (at least one admin must be selected)";
export const RegenerateScimCredentials = "Regenerate credentials";
export const ScimConfigurationOptionLabel = "Automatically (via SCIM)";
export const ScimConfigurationOptionDescription =
  "Keep people and teams in sync with your company directory via a SCIM integration.";
export const ScimSettingsTitle = "SCIM (User directory sync)";
export const ScimSettingsDescription =
  "Keep the people in your workspace in sync with your company's user directory via SCIM.";
export const TaskInformationOrderSettingTitle = "Task information order";
export const TaskInformationOrderSettingDescription =
  "Show requested information before shared information in workflow tasks.";
export const TaskInformationOrderSettingDisabled = "Requested information will now show last in workflow tasks.";
export const TaskInformationOrderSettingEnabled = "Requested information will now show first in workflow tasks.";
export const Success = "Success";
export const StatusOk = "200 Status OK";
export const ToastErrorOnUpdateScimConfiguration = "Error saving SCIM configuration";
export const ToastErrorOnUpdateScimNoPartyToNotify =
  "At least one admin must be selected to receive SCIM notifications.";
export const ToastSuccessOnUpdateScimConfiguration = "Successfully saved SCIM configuration";
export const WhatIsScim = "What is SCIM?";
export const WhenAnAlertTriggers = "When an alert triggers:";

// People table
export const CapitalTo = "To";
export const User = "User";
export const UserAD = "User AD";
export const ViaScim = "via SCIM";

// RegrelloFileDropzone
export const RegrelloFileDropzoneDropHint = "Click or drop to attach file";

// Error message overrides for the papaparse library. @see RegrelloCsvParser
export const PapaparseErrorMessagesGeneric =
  "The CSV file could not be parsed. Please verify that it is properly structured.";
export const PapaparseErrorMessagesTooFewFields =
  "Some of the rows in this CSV file are missing fields that are defined in the header row.";
export const PapaparseErrorMessagesTooManyFields =
  "Some of the rows in this CSV file have fields that are not defined in the header row.";
export const PapaparseErrorMessagesEmptyColumns = (columns: Array<number | string>) =>
  `Invalid column header detected. Please check the columns at the following indexes: ${columns.join(", ")}`;
export const PapaparseErrorInvalidDelimiter = "CSV file contains invalid delimiter. Only comma delimiter is allowed.";

// Out of office
export const And = "and";
export const ChoosePeopleAndOrTeams = "Choose people and/or teams";
export const DelegateAssignedTasks = "Delegate assigned tasks";
export const EndDateIsBeforeStartDate = "End date is before start date";
export const Ends = "Ends";
export const ErrorEditOutOfOffice =
  "Failed to edit out of office. Please try again, or contact a Regrello admin if you continue to see this error.";
export const ErrorMessageMustAssigneAtLeastOneDelegate = "You must assign at least one delegate for Tasks or Approvals";
export const KeepMeCcdOnDelegatedTasks = "Keep me Cc'd on delegated tasks";
export const KeepTasksAssignedToMe = "Keep tasks assigned to me";
export const Now = "now";
export const OutOfOffice = "Out of office";
export const OutOfOfficeBannerMessage = "You're currently showing as out of office.";
export const OutOfOfficeDelgationTooltip =
  "Choose to keep your assigned tasks or delegate them to people and/or teams while you’re away.";
export const OutOfOfficeHasBeenTurnedOn = "Out of office has been turned on.";
export const OutOfOfficeHasBeenTurnedOff = "Out of office has been turned off.";
export const OutOfOfficeHasBeenSetFrom = "Out of office has been set from";
export const OutOfOfficeHasBeenSetToBegin = "Out of office has been set to begin";
export const OutOfOfficeTaskUpdateTooltip = "Choose to remain Cc’d on your delegated tasks while you’re away.";
export const OutOfOfficeTooltipBullet1 =
  "Setting yourself to ‘Away’ without picking dates immediately turns on OOO. You must turn it off manually.";
export const OutOfOfficeTooltipBullet2 =
  "Setting a start date only will turn it on on that day. You must turn it off manually.";
export const OutOfOfficeTooltipBullet3 =
  "Setting start and end dates will show you as OOO only for those days and turn off automatically.";
export const OutOfOfficeTooltipBullet4 =
  "Setting an end date only will turn on OOO immediately and turn it off automatically.";
export const OutOfOfficeTooltipMainText = "When you'll be out of office";
export const PeopleLower = "people";
export const PersonOrTeam = "Person or team";

export const PersonLower = "person";
export const SetAsAway = "Set as away";
export const Starts = "Starts";
export const StartNow = "Start now";
export const TaskUpdates = "Task updates";
export const TeamLower = "team";
export const TeamsLower = "teams";
export const To = "to";
export const TurnOff = "Turn off";
export const WhenIllBeOut = "When I'll be out";
export const WhileImAway = "While I'm away";
export const WhileYoureAwayAssignedTasksWillBeDelegatedTo = "While you’re away, assigned tasks will be delegated to";

// Workflow status overviews.
export const CompletedWorkflows = "Completed workflows";
export const CompletedWorkflowsTooltip = "The number of workflows that have run from start to finish.";
export const EndedWorkflows = "Ended workflows";
export const EndedWorkflowsTooltip =
  "Workflows that have been ended before completion. Ended workflows cannot be resumed.";
export const InProgressWorkflows = "In progress workflows";
export const InProgressWorkflowsTooltip = "The number of in progress workflows, regardless of status.";
export const NotStartedWorkflows = "Not started workflows";
export const NotStartedWorkflowsTooltip = "The number of workflows that have not yet been started.";
export const OnTrackWorkflows = "On track workflows";
export const OnTrackWorkflowsTooltip = "The number of workflows with no problems reported and nothing overdue.";
export const PausedWorkflows = "Paused workflows";
export const PausedWorkflowsTooltip = "In progress workflows that have been paused.";
export const ProblemsReported = "Problems reported";
export const ProblemsReportedTooltip = "The number of problems reported across all in progress workflows in this view.";
export const ShowWorkflowStatusOverview = "Show workflow status overiew";
export const StatusOverview = "Status overview";
export const StatusOverviewTooltip = "Key metrics that will display above the table for each view";
export const WorkflowsWithProblems = "Workflows with problems";
export const WorkflowsWithProblemsTooltip = "The number of workflows in progress with problems reported in this view.";
export const WorkflowsWithOverdueStages = "Workflows with overdue stages";
export const WorkflowsWithOverdueStagesTooltip =
  "The number of workflows with overdue stages. Stages display as overdue whenever there are tasks within them that are overdue.";

// Notifications
export const NotificationsDialogOptionAnyNotificationDescription =
  "Receive email notifications so you can stay on top of what's going on within this workspace. Turn them off at any time.";
export const NotificationsDialogOptionHeadingWeeklyDigest = "Weekly digest";
export const NotificationsDialogOptionHeadingDailyDigest = "Daily task alerts";
export const NotificationsDialogOptionHeadingAssignedTaskUpdates = "Assigned task updates";
export const NotificationsDialogOptionHeadingCcdTaskUpdates = "Cc’d task updates";
export const NotificationsDialogOptionDescriptionWeeklyDigest =
  "A list of reported problems, overdue tasks, and any on track tasks due in the coming week, delivered every Monday morning.";
export const NotificationsDialogOptionDescriptionDailyDigest =
  "A list of reported problems, overdue tasks, and any on track tasks due that day, delivered every Monday-Friday morning.";
export const NotificationsDialogOptionDescriptionAssignedTaskUpdates =
  "Receive email notifications whenever there’s an update to a task assigned to you.";
export const NotificationsDialogOptionDescriptionCcdTaskUpdates =
  "Receive email notifications whenever there’s an update to a task that you’ve been Cc’d on.";

// AI control center
export const AiGenerated = "AI generated";
export const StageSummaries = "Stage summaries";

// Linked workflows
export const AddFieldToBlueprint = "Add field to blueprint. Adding will make it required when starting the workflow.";
export const AddFieldToParent = (parentName: string) => (
  <span>
    Add field to <strong>{parentName}</strong>. Adding will make it required when starting the workflow.
  </span>
);
export const AddFieldToWorkflow = "Add field to workflow. Adding will make it required when starting the workflow.";
export const AddLinkedWorkflowTask = "Add linked workflow";
export const BlueprintNotFound = "Blueprint not found";
export const Blueprint = "Blueprint";
export const ConfigureFieldHelperText = "This field will be added to the workflow. Please provide a value below.";
export const ConfigureFieldsResolveProblemHelperText =
  "Please provide the information below to resolve the problem and start the linked workflow";
export const CreateNewBlueprint = "Create new blueprint";
export const DelayedBy = "Delayed by";
export const DisabledInheritableFieldRowTooltipAlreadyRequested = "This field is already requested on the task";
export const DisabledInheritableFieldRowTooltipAlreadyMapped = "This field is already passed into the task";
export const DisabledInheritableFieldRowTooltipAlreadyInSpectrumForm = "This field is used by the selected form";
export const DisabledOutputFieldTooltip = "The field is already being used to start the linked workflow";
export const DisabledOutputFieldTooltipFromSplitterTask = "Can't return fields from split tasks to a linked workflow.";
export const DisabledFieldInstanceRowIsSelectedSplitterField = (
  actionItemType: "default" | "linkedWorkflow" | "standaloneApproval",
) => `This field is being used to split the ${actionItemType === "linkedWorkflow" ? "workflow" : "task"}.`;
export const DuplicateLinkedWorkflowTask = "Duplicate linked workflow";
export const EditFieldsDisabledHelperText =
  "Some workflow fields cannot be deleted. These fields are being used to pass information to or are provided from a linked workflow(s).";
export const EditLinkedWorkflowTask = "Edit linked workflow";
export const EditSharepointFolderResolveProblemHelperText = "Please select the folder below to resolve the problem";
export const Ellipses = "...";
export const FieldIsOptionalWarning = "Field is optional but required to start the linked workflow";
export const FieldIsOptionalSpectrumFormWarning = "Field is optional but required by the form";
export const FieldNoLongerAvailable = "Field no longer available";
export const FieldRemovedWarning = "Field removed";
export const InformationUnavailableToStartWarning = "Information not available to start linked workflow";
export const InProgressLinkedWorkflowHelperText = "This task will complete once the linked workflow completes";
export const InputFieldRestrictedTooltipText = (fieldDisplayName: string) =>
  `This ${fieldDisplayName} field cannot be selected as it has a different name and values. Please only select the field with the same name it is being mapped to.`;
export const InvalidCardinalityTooltipText = "Multi-valued fields cannot be mapped to single-valued fields.";
export const MissingInputField = "Missing information";
export const ConnectionIssue = "Connection issue";
export const NotAvailable = "Not available";
export const OpenWorkflow = "Open workflow";
export const ParentWorkflow = "Parent workflow";
export const ProblemReportedOnWorkflow = "Problem reported on workflow";
export const LinkedWorkflowNotFound = "Linked workflow deleted";
export const ParentWorkflowDeletedWarning = (parentName: string) => (
  <>
    The parent workflow <strong>{parentName}</strong> has been deleted
  </>
);
export const ParentWorkflowEndedWarning = (parentName: string) => (
  <>
    The parent workflow <strong>{parentName}</strong> has been ended
  </>
);
export const ParentWorkflowPausedWarning = (parentName: string) => (
  <>
    The parent workflow <strong>{parentName}</strong> has been paused
  </>
);
export const ParentWorkflowUnlinkedWarning = (parentName: string) => (
  <>
    The parent workflow <strong>{parentName}</strong> has been updated and no longer depends on this workflow to
    complete
  </>
);
export const PreviewBlueprint = "Preview blueprint";
export const PercentComplete = (percentComplete: number) => `${percentComplete}% complete`;
export const PublishBlueprintAndLinkedBlueprintsDialogText = (tenantName: string) =>
  `Publish and make available in ${tenantName}'s blueprints? This will also publish any linked blueprints.`;
export const PublishBlueprintAndLinkedBlueprintsDialogTextV2 = (tenantName: string) => (
  <>
    Publishing will make it available within {tenantName}&apos;s blueprints.
    <br />
    If the blueprint contains any non-published linked blueprints, they will also be published.
  </>
);
export const PublishBlueprintAndLinkedBlueprintsDialogTextDefault =
  "Publish and make available in Company blueprints? This will also publish any linked blueprints.";
export const PublishBlueprintAndLinkedBlueprintsDialogTextDefaultV2 =
  "Publish and make available in Published blueprints? This will also publish any linked blueprints.";
export const PublishBlueprintAndLinkedBlueprintsDialogTextDefaultV3 = (
  <>
    Publishing will make it available within published blueprints.
    <br />
    If the blueprint contains any non-published linked blueprints, they will also be published.
  </>
);
export const ReceiveInfo = "Receive info";
export const ResolveRecreate = "Resolve & recreate";
export const RestartConfirmationDialogContentLinkedWorkflows =
  "Additionally, any linked workflows following it could be paused if currently in progress or recreated if currently completed.";
export const Review = "Review";
export const SelectFieldsInputText = (parentName: JSX.Element, linkedName: JSX.Element) => (
  <>
    Select fields to pass from {parentName} to {linkedName}
  </>
);
export const SelectFieldsOutputText = (parentName: JSX.Element, linkedName: JSX.Element) => (
  <>
    Select fields from the linked workflow {linkedName} to return into {parentName}. The fields selected can then be
    shared with future tasks.
  </>
);
export const ThereAreNoFieldsInLinkedWorkflow = (linkedWorkflowName: JSX.Element) => (
  <>There are no fields to select from {linkedWorkflowName}</>
);
export const UnableToStartLinkedWorkflowWarning = "Unable to start linked workflow";
export const UnavailableBlueprintTooltip =
  "This blueprint cannot be selected because it would create a cycle that leads back to the current blueprint.";
export const UnlinkWorkflow = "Unlink workflow";
export const UnlinkWorkflowConfirmation =
  "The linked workflow will not be deleted but will be removed from the parent workflow.";
export const ViewLinkedWorkflowPermissionsWarning =
  "You do not have sufficient permissions to view the linked workflow";
export const WorkflowNotCreated = "Workflow could not be created";
export const WorkflowDeletedNoName = "Workflow has been deleted";
export const WorkflowOwnerTooltipText = "Linked workflow tasks are assigned to the workflow owner by default";

export const AddWorkflowDialogFromScratchTitle = "From scratch";
export const AddWorkflowDialogFromScratchSubtitle = "To build a custom process to use one time";
export const AddWorkflowDialogFromBlueprintTitle = "From a blueprint";
export const AddWorkflowDialogFromBlueprintSubtitle = "To conform to a standard process";
export const AddWorkflowDialogLoadError =
  "Failed to load blueprints. To try again, close and reopen this dialog or refresh the page.";
export const AddWorkflowDialogDisabledOptionTooltip = "Select All Blueprints to enable this option.";
export const AddWorkflowDialogSelectedSort = (byAttribute: string) => `Sort: ${byAttribute}`;

export const SortOrderLabelAZ = "A-Z";
export const SortOrderLabelMostUsed = "Most used";
export const SortOrderLabelRecentlyUpdated = "Recently updated";

// Spectrum
export const About = "About";
export const AcceptedDates = "Accepted dates";
export const AddForm = "Add form";
export const AddSection = "Add section";
export const AllowMultipleSelections = "Allow multiple selections";
export const CreateField = "Create field";
export const CreateForm = "Create form";
export const CreateNewSpectrumFieldEmpty = (name: string) => `Create "${name}"`;
export const CreateNewForm = "Create new form";
export const CreateNewFormNotAllowed = "Please contact an admin if you would like to create a new form";
export const Country = "Country";
export const CannotPopulateSyncedObject =
  "Populating a synced object is disallowed. Only workspace admins can configure connected data sources.";
export const DisallowLink = "Disallow external link";
export const Build = "Build";
export const DateFieldValueConstraintAllDates = "All dates";
export const DateFieldValueConstraintFutureOnly = "Only dates in the future, including today";
export const DateFieldValueConstraintPastOnly = "Only dates in the past, including today";
export const DeleteSection = "Delete section?";
export const DeleteSectionConfirmation = "This will remove the section and all of its fields.";
export const DeleteTheForm = "Delete this form?";
export const DeleteForm = "Delete form";
export const DeleteFormNamed = (name: string) => (
  <span>
    Deleting form <strong>{name}</strong> will remove it from this workspace, but it will continue to appear in any
    workflows or blueprints that are already using it. Deleting this form cannot be undone.
  </span>
);
export const Drag = "Drag";

export const FieldNameAlreadyInUse = (field: string) => `Field name ${field} is already in use`;
export const FileExtensions = "File extensions";
export const FileExtensionHelperText = "List of accepted file extensions for this field.";
export const Form = "Form";
export const Forms = "Forms";
export const FormAndFieldsCannotBeProvidedAtTheSameTime = "Form and fields cannot be provided at the same time.";
export const FormPreviewSectionsInvalid = "All form sections should have at least one field or instruction.";
export const FormDisabledBecauseOfOccupiedFields =
  "This form contains fields that are already passed to this task as shared fields.";
export const FormDisabledBecauseOfOccupiedFieldsList =
  "This form contains fields that are already passed to this task as shared fields. To add the form, please remove the following fields:";
export const FormDisabledBecauseOfExcludedFields =
  "This form contains fields that have already been used in this blueprint.";
export const FormDisabledBecauseOfExcludedFieldsList =
  "This form contains fields that have already been used in this blueprint. To add the form, please remove the following fields:";
export const HelperText = "Helper text";
export const InternalName = "Internal name";
export const DataFormat = "Data format";
export const DNDFieldsFromTheLeftSidebarToThisArea = "Drag fields from the left sidebar into this section.";
export const Layout = "Layout";
export const Length = "Length";
export const InstructionText = "Instruction text";
export const EditFormDetails = "Edit form details";
export const EditInstructionText = "Edit instruction text";
export const EditName = "Edit name";
export const EditSection = "Edit section";
export const ErrorCreatingForm = "Unable to create a new form";
export const ErrorUpdatingForm = "Unable to update the form";
export const ErrorAddingSectionToForm = "Unable to add section to form";
export const ErrorAddingInstructionToForm = "Unable to add instruction to form";
export const ErrorAddingFieldToSection = "Unable to add field to section";
export const ErrorAttachingFormToTask = "Error attaching form to task";
export const ErrorAttachingFormToTaskTemplate = "Error attaching form to task template";
export const ErrorRemovingFieldFromSection = "Unable to remove field from section";
export const ErrorRemovingSectionFromForm = "Unable to remove section from form";
export const ErrorUpdatingFields = "Error updating fields";
export const ErrorUpdatingSection = "Error updating section";
export const ErrorUpdatingFieldPassingSetting = "Failed to update field passing setting";
export const ErrorUpdatingFieldIsMultiValuedSetting = "Failed to update field allow multiple selections setting";
export const MessagesAndDocumentsTooltipHide = "Hide messages & documents";
export const MessagesAndDocumentsTooltipShow = "Show messages & documents";
export const MyForms = "My forms";
export const NumberOfFields = (count: number) => `${count} fields`;
export const NoFormsZeroStateDescription = "Forms that you create will appear here.";
export const NoPublishedFormsZeroStateDescription = "Forms that you and others publish will appear here.";
export const NoFormsZeroStateTitle = "No forms";
export const NonIdealStateFormNotFound = "Form not found";
export const FieldAlreadyUsedInFormTooltip = "This field has already been added to this form.";
export const Phone = "Phone";
export const PrepopulateTooltipText =
  "Fill in this field with a non-editable value. This value can be configured when adding the form to a blueprint.";
export const PrepopulateWithData = "Pre-populate with data";
export const PreviousStages = "Previous stages";
export const PublishForm = "Publish form";
export const PublishFormConfirmationText =
  "Publishing this form will freeze it on its current version and make it available to everyone in this workspace. Are you sure you want to continue?";
export const PublishedForms = "Published forms";
export const RegrelloWebformTaskActivityHide = "Hide task activity";
export const RegrelloWebformTaskActivityShow = "Show task activity";
export const REGRELLO_SIGNATURE_HOW_TO_URL =
  "https://www.notion.so/regrello/Signature-Fields-a80486d054944472a4695fdb0cc7d62e";
export const SavedAt = (time: string) => `Saved at ${time}`;
export const SavingEllipses = "Saving...";
export const SpectrumFormBuilderFieldsTitleBlueprintFieldsLink = <span>Blueprints &rarr; Fields</span>;
export const SpectrumFormBuilderFieldsTitleTooltip = (fieldsLink: React.ReactNode) => (
  <p>This list shows all fields in your workspace. You can create new fields here or manage fields in {fieldsLink}.</p>
);
export const SpectrumFormBuilderClickOrDragTooltip = "Click or drag to add";
export const SpectrumFormBuilderPublishButtonTooltip = "Publish this form draft for everyone in this workspace to use.";
export const SpectrumFormBuilderPublishButtonTooltipWhenEmptySections =
  "Add content to all empty sections in order to publish this form.";
export const SpectrumFormBuilderPublishButtonTooltipWhenNoSections = "Add a section in order to publish this form.";
export const SpectrumFormBuilderSaveButtonTooltip = "Copy as new draft in My forms";
export const TaskDetailMessageBoxPlaceholder = "Message this task's participants";
export const UntitledForm = "Untitled form";
export const UntitledSection = "Untitled section";
export const ValueConstraintMaxLength = "Max length (characters)";
export const ValueConstraintMaxValue = "Maximum value";
export const ValueConstraintMinLength = "Min length (characters)";
export const ValueConstraintMinValue = "Minimum value";
export const ViewOptions = "View options";
export const ViewForm = "View form";
export const ViewFormDisabledReasonCreatedBySomeoneElse = "You cannot view someone else's non-published form.";
export const ViewFormDisabledReasonFormDeleted = "You cannot view a deleted form.";
export const Signature = "Signature";
export const SuccessToastRemove = "Form successfully removed";
export const SuccessToastPublish = (name: string | JSX.Element) => <span>Form {name} successfully published</span>;
export const SuccessToastSaveToMyForms = (name: string | JSX.Element) => (
  <span>Form {name} successfully saved to My Forms</span>
);
export const SuccessToastUpdateField = "Field successfully updated";

// Workflow ID
export const WorkflowReferenceID = "Workflow Reference ID";
// to be used where WorkflowReferenceID is too long to fit
export const WorkflowReferenceIDAbbreviated = "Reference ID";
export const WorkflowReferenceIdDefaultText =
  "Workflows created from this blueprint will not have a Workflow Reference ID.";
export const WorkflowReferenceIdCustomPrefix = "Custom prefix";
export const WorkflowReferenceIdCustomPrefixText = `Workflows created from this blueprint will have a reference ID with a custom prefix and an auto-incrementing number. IDs are only generated for workflows created from published blueprints and will automatically be added to the workflow's naming convention.`;
export const WorkflowReferenceIdTooltipText =
  "An ID will be assigned to workflows created from Company blueprints if the feature is set up.";
export const WorkflowReferenceIdTooltipTextV2 =
  "An ID will be assigned to workflows created from Published blueprints if the feature is set up.";
export const WorkflowReferenceIdPrefixValidationText =
  "Prefixes must only consist of letters, numbers, periods, underscores, or dashes, and cannot contain spaces or end with special characters.";

export const WorkspaceChangeNotification = (workspaceName: string) => (
  <span>
    You&apos;re currently in the <strong>{workspaceName}</strong> workspace.
  </span>
);

// Device verification
export const DeviceVerificationToCompleteYourWorkCTA = (
  <span>
    <b>To complete your work, click the “verify” link in the email we just sent.</b>
  </span>
);
export const DeviceVerificationDontSeeAnEmail = (
  <span>
    <b>Don&apos;t see any email?</b> Check your Junk folder.
  </span>
);
export const DeviceVerificationStillDidntGetIt = (
  <span>
    <b>Still didn’t get it?</b> Only people who have been assigned to or cc’d to this task will be able to access the
    task. If you are not the assigned or cc’d person, you won’t be able to confirm.
  </span>
);
export const DeviceVerificationClientError = "Something seemed to go wrong while connecting to our servers.";
export const DeviceVerificationServerError = "Our servers appear to be having trouble.";
export const DeviceVerificationVerifyYourDevice = "Verify your device";
export const DeviceVerificationVerifyToUse =
  "Regrello needs to verify your device for use with the Regrello webform. Click the button below to proceed with email verification.";
export const DeviceVerificationInstructionsListItemOne =
  "If you have access to the task, you will receive an email to verify your device.";
export const DeviceVerificationInstructionsListItemTwo = (
  <span>
    <b>Who can access this task?</b> Only people who have been assigned to or cc’d to this task will be able to access
    the task. You can request the person who shared the task link or email to assign or cc you to the task for access.
  </span>
);
export const DeviceVerificationVerifyMyDevice = "Verify my device";
export const DeviceVerificationAnEmailCouldNotBeSent = "An email could not be sent.";
export const DeviceVerificationAnErrorOccurred = "An error occurred";
export const DeviceVerificationNeedsEmail =
  "This task was assigned to a team. Enter the email address associated with your account associated with this team.";
export const DeviceVerificationEnterYourEmail = "Enter your email";
export const DeviceVerificationExampleEmail = "example@example.com";

// Create workflow via email
export const BlueprintEmail = "Blueprint email";
export const ConfigureBlueprintEmailDialogHelperText =
  "The sender does not need to be a member of this workspace; please choose the address accordingly. And if the default workflow owner is a team, then a team admin will be displayed as the workflow creator.";
export const ConfigureDialogRadioOptionLabel = (name: string, label: string) => (
  <span>
    <strong>{name}</strong>. {label}
  </span>
);
export const ConfirmRemoveFieldsDialogContent =
  "This feature cannot be used with fields that require manual input in order to start the workflow. Removing these fields may impact aspects of the blueprint that rely on them, such as naming convention or stage start conditions. Do you want to continue?";
export const ConfirmRemoveFieldsDialogTitle = "Remove existing workflow fields?";
export const ContactEmail = (username: string, domain: string) => `${username}@${domain}`;
export const CreateViaEmail = "Create via email";
export const CreateViaEmailOptionLabel =
  "Once published, sending an email to the designated address will create a workflow from this blueprint.";
export const CreateWorkflowViaEmail = "Create workflow via email";
export const CreateWorkflowsViaEmail = "Create workflows via email";
export const CreateWorkflowViaEmailSettingDescription =
  "Allow users to create a workflow via email once this blueprint has been published.";
export const DefaultBlueprintContactEmailOptionLabel =
  "Users will only be able to create a workflow from this blueprint in the application.";
export const EditFieldsButtonDisabledTooltipText =
  "Fields cannot be added to a blueprint when users can create workflows via email.";
export const EmailCopied = CopiedToClipboard("email");
export const EmailFieldChipTooltipText = (fieldName: string) =>
  `${fieldName} will only be included if the workflow was created via email.`;
export const EmailFieldInfoIconTooltip =
  "This field will be automatically filled out if a workflow is created via email.";
export const ErrorLoadingEmailDomainTooltip =
  "There was an error loading this workspace's email domain. Please contact a Regrello admin for assistance.";
export const EmailNotAvailableErrorMessage = "This email is not available";
export const EmailUsedInCompanyBlueprintsWarningMessage =
  "This email is currently being used by a Company blueprint. If you publish, the email address will be associated to this blueprint instead.";
export const EmailUsedInPublishedBlueprintsWarningMessage =
  "This email is currently being used by a Published blueprint. If you publish, the email address will be associated to this blueprint instead.";
export const InvalidEmailErrorMessage = "Email can only include letters (a-z), numbers, and periods.";
export const PublishBlueprintWithSameEmailConfirmationDialogContent = (blueprintLink: ReactNode) => (
  <>
    {blueprintLink} already uses the blueprint email address that you chose. If you proceed with publishing, the email
    address will be reassigned to this blueprint instead. Do you want to continue?
  </>
);
export const UnableToPublish = "Unable to publish";
export const UnableToPublishDialogContent = (email: string) =>
  `The email associated with this blueprint (${email}) is no longer available. Please choose another email in order to publish.`;
export const WorkflowOwnerMustBeConfiguredTooltipText =
  "A default workflow owner must be configured in order for workflows to be created via email.";
export const WorkflowReferenceIdDisabledTooltipText = "Insufficient permissions to set up workflow reference ID.";
export const WorkflowLockedApprovalsDisabledTooltipText = "Insufficient permissions for setting locked approvals.";
export const WorkflowLockedWorkflowsDisabledTooltipText = "Insufficient permissions for setting locked workflows.";
export const WorkflowOwnerRequiredErrorMessage =
  "A workflow owner must be configured when users are allowed to kick off workflows via email.";
export const WorkflowFieldsHint = "Fields that need to be completed prior to starting the workflow.";

// Privacy acknowledgement.
export const EmployeePrivacyNotice = "Employee Privacy Notice";
export const PrivacyAcknowledgementBannerMessage = (link: ReactNode) => (
  <>
    By using Regrello, you acknowledge that the tool administrators will use your personal data (name, email, and DSID)
    for authentication and notifications, in accordance with the {link != null ? link : EmployeePrivacyNotice}.
  </>
);

// App generation
export const BlueprintRedirectMessage = (
  <span>
    Taking you to <strong>My Blueprints</strong> now
  </span>
);
export const FailedToGenerate = "Failed to generate.";
export const FromScratch = "From scratch";
export const FromScratchSubtitle = "Start with an empty blueprint.";
export const FormFromScratchSubtitle = "Start with an empty form.";
export const FormGenerationSuccessMessage = (formName: string) => (
  <span>
    Successfully generated <strong>{formName}</strong>
  </span>
);
export const FormRedirectMessage = "Taking you to your forms page now";
export const Generate = "Generate";
export const GenerateBlueprint = "Generate blueprint";
export const GenerateForm = "Generate form";
export const GenerateForms = "Generate forms";
export const GenerationSuccessMessage = (blueprintName: string) => (
  <span>
    Successfully generated <strong>{blueprintName}</strong>
  </span>
);
export const GenerationFailedMessage = (blueprintName: string) => (
  <span>
    AI Tailoring is unable to generate {blueprintName}. Please try using a more descriptive process name or check that
    the right industry was used.
  </span>
);
export const GenerateWithAI = "Generate with AI";
export const GenerateWithAISubtitle = "Enter a prompt and let AI build the blueprint.";
export const FormGenerateWithAISubtitle = "Upload your assets to generate a form.";
export const GeneratingBlueprint = "Generating blueprint";
export const GeneratingForm = "Generating form";
export const GeneratingFormDescription =
  "We're working on the form right now. Sit tight and we'll update this page when it's finished.";
export const GeneratingFormNOfN = (currentFormIndex: number, formCount: number) =>
  `Generating form ${currentFormIndex + 1} of ${formCount}`;
export const ProvideInputs = "Provide inputs";
export const Summary = "Summary";
export const UploadFiles = "Upload file(s)";
export const UploadFilesHelperText = "Upload any PDF, PNG, or JPEG file(s) to help tune the AI.";
export const UploadFilesHelperTextFormGen = "Upload any PDF, image, or CSV file to help generate the form with AI.";

// Blueprint transfer
export const CompatibleFieldsInWorkspace = "Compatible fields in workspace";
export const ExportBlueprintTooltip = "Export this blueprint as a .rex file that can be imported in other workspaces";
export const ExportBlueprintDisabledTooltip =
  "Exporting blueprints with any of the following is not supported: Automations & integrations, Synced objects, Linked workflows, and Documents";
export const ExportToAnotherWorkspace = "Export to another workspace";
export const FieldsInImport = "Fields in import";
export const Finish = "Finish";
export const Import = "Import";
export const ImportBlueprint = "Import blueprint";
export const ImportBlueprintFileUploadHelperText = `To export a blueprint from another workspace, find a blueprint on the Blueprints page and click “Export as .rex".`;
export const ImportBlueprintFileValidationError =
  "File does not match the expected format. Please re-export the blueprint and try importing again.";
export const ImportBlueprintTitle = "Import .rex file";
export const ImportBlueprintSubtitle = "Import a blueprint that was downloaded from another workspace. ";
export const ImportingBlueprintEllipses = "Importing blueprint...";
export const ImportSuccessMessage = "Successfully imported blueprint";
export const ImportSuccessMessageWithName = (blueprintName: string) => (
  <span>
    Successfully imported <strong>{blueprintName}</strong>
  </span>
);
export const ImportSuccessRedirectMessage = "Taking you to the imported blueprint now";
export const ManageFields = "Manage fields";
export const NamedNewField = (newFieldName: string) => `(New) ${newFieldName}`;
export const ReviewFieldMappings = "Review field mappings";
export const ValidatingBlueprintEllipses = "Validating blueprint for import...";
export const UploadFile = "Upload file";

// Permissions V2
export const DraftBlueprint = "Draft blueprint";
export const Role = "Role";
export const AddRole = "Create role";
export const EditRole = "Edit role";
export const AllowInvitingPeopleToTheWorkspace = "Allow inviting people to the workspace";
export const CreatedByPermissionCellSuffix = "(Creator)";
export const FailedToUpdateRolesToUser = "Failed to update user's roles.";
export const AutomaticallyGranted = "Automatically granted";

export const GrantSharedWorkflowTemplateAccessToExternalUserDisabledTooltipText =
  "External users cannot access shared blueprints.";
export const GrantSharedWorkflowTemplateViewAccessToDraftEditDisabledTooltipText =
  "Draft editors automatically receive view access.";
export const GrantSharedWorkflowTemplateAccessToCreatorDisabledTooltipText =
  "Blueprint creators automatically receive view access.";
export const GrantWorkflowTemplateAccess = "Grant blueprint access";
export const GrantWorkflowAccess = "Grant workflow access";
export const PublishedAccess = "Published access";
export const DraftAccess = "Draft access";
export const FailedToGrantWorkflowTemplateAccess = "Failed to grant blueprint access.";
export const BlueprintAccessGranted = "Blueprint access granted.";
export const BlueprintAccessControlDescription =
  "Control access to this blueprint before and after it is published with the workspace.";
export const ConfigureAccess = "Configure access";
export const ConfigureWorkflowAccessHelperText = "Control access to this workflow";
export const ConfigureWorkflowInTemplateAccessHelperText = "Control access to workflows created from this blueprint";
export const DraftAccessOptionNone = "No access to the draft blueprint";
export const DraftAccessOptionView = "Can view but not edit the draft blueprint";
export const DraftAccessOptionEdit = "Can view and edit the draft blueprint";
export const SharedAccessOptionView = "Can open and view the full details of the published blueprint.";
export const SharedAccessOptionStartWorkflows = "Can start workflows from the published blueprint.";
export const CanViewLower = "can view";
export const CanEditLower = "can view and edit";
export const CannotAccessLower = "cannot access";
export const SharedAccessPhraseBothLower = "can view and start workflows from";
export const SharedAccessPhraseCanStartWorkflowsFromLower = "can start workflows from";
export const ThisDraftBlueprintLower = "this draft blueprint";
export const ThisBlueprintWhenSharedLower = "this blueprint when published";
export const SelectPeopleToSeeSummary = "Select people to see a summary.";
export const PublishedWorkflowTemplate = "Published blueprint";

export const AddUser = "Add user";
export const NoneAssigned = "None assigned";
export const RoleAssignment = "Role assignment";

export const CollaborationDialogGrantTo = "Grant to";
export const CollaborationDialogSelectPerson = "Select person, role, or team";
export const CollaborationBlueprintRemoveWarning = "This will remove the access granted to this blueprint.";
export const CollaborationWorkflowRemoveWarning = "This will remove the access granted to this workflow.";
export const CreateBlueprintRelationErrorMessage = "Failed to add relation to blueprint.";
export const CreateWorkflowRelationErrorMessage = "Failed to add relation to workflow.";
export const RegrelloFormFieldPartySelectWithRoleFilterSingleRole = (
  roleName: string,
  subject: string,
  icon: JSX.Element,
) => (
  <>
    Showing {subject} who have the {icon} <span className="font-bold">{roleName}</span> role.
  </>
);
export const RegrelloFormFieldPartySelectWithRoleFilterMultipleRoles = (subject: string, icon: JSX.Element) => (
  <>
    {icon} Showing {subject} with multiple roles.
  </>
);
export const ShowingAllSubjectInTheWorkspace = (subject: string) => `Showing all ${subject} in the workspace.`;

export const InternalToExternalChangeWarning =
  "Changing a user from internal to external is a destructive change. The user will have any workspace-level role removed from their profile. Ensure this is what you want to do before saving.";

export const RoleInUseDeleteButtonDisabled = (blueprintCount: number) => (
  <>
    This role cannot be deleted because it is being used in <strong>{blueprintCount}</strong>{" "}
    {blueprintCount === 1 ? "blueprint" : "blueprints"}.
  </>
);
export const ScimRoleDeleteButtonDisabled = "This role cannot be deleted because it is being provisioned via SCIM.";
export const RoleDeleteDialogUsageWarning = (name: string) => (
  <>
    <p>
      <strong>{name}</strong> may be used in active tasks and workflows.
    </p>
    <br />
    <p>
      People with this role will not able to access active and completed workflows, and tasks assigned to this role will
      need to be reassigned.
    </p>
  </>
);

// Locked workflows
export const LockWorkflows = "Lock workflows";
export const LockWorkflowsHelperText =
  "Prevent deleting and editing for workflows created from this blueprint. Approval tasks will also be locked with the workflow owner unable to complete.";
export const LockWorkflowsLearnMoreLink =
  "https://regrello.notion.site/Locked-Workflows-c065d0ec24874797b82e232539a9a4a9?pvs=4";
export const LockedBlueprintLockTooltipText =
  "Workflows created from this blueprint cannot be deleted and certain edit options are not available.";
export const LockedWorkflowAdvancedSettingsTooltip = "Advanced settings cannot be edited";
export const LockedWorkflowExistingAssigneeTooltip = "This assignee cannot be removed";
export const LockedWorkflowLockTooltipText =
  "This workflow has been locked from the blueprint and cannot be edited or deleted.";
export const LockedWorkflowNamingConventionTooltip = "The naming convention cannot be edited";
export const LockedWorkflowReceiveInfoTooltip = "Fields received from the workflow cannot be edited";
export const LockedWorkflowReceiveInfoSharePointTooltip = "Fields received from SharePoint cannot be edited";
export const LockedWorkflowRejectActionTooltip = "Reject actions cannot be edited";
export const LockedWorkflowSendInfoTooltip = "Fields passed to the workflow cannot be edited";
export const LockedWorkflowSendInfoDocuSignTooltip = "Fields passed to DocuSign cannot be edited";
export const LockedWorkflowSendInfoSharePointTooltip = "Fields passed to SharePoint cannot be edited";
export const LockedWorkflowSharedInfoTooltip = "Shared information cannot be edited";

// Auto adjusting due dates + Business calendar
export const BusinessCalendarTitle = "Business calendar";
export const BusinessCalendarDescription =
  "Enable precise task scheduling by aligning due dates with your team's working days and/or hours.";
export const BusinessCalendarDefaultConfigTitle = "Default.";
export const BusinessCalendarDefaultConfigDescription = "Tasks can be due anytime, including weekends and at any hour.";
export const BusinessCalendarBizCalConfigTitle = "Business calendar.";
export const BusinessCalendarBizCalConfigDescription =
  'Due dates align with organization\'s business hours/days, applicable only to tasks from blueprints with a set "Time to Complete".';
export const BusinessCalendarBizCalOnlyWeekdaysTitle = "Only weekdays.";
export const BusinessCalendarBizCalOnlyWeekdaysDescription =
  "Saturdays and Sundays will not count towards due date calculations.";
export const BusinessCalendarBizCalOnlyWorkingHoursTitle = "Only working hours.";
export const BusinessCalendarBizCalOnlyWorkingHoursDescription =
  "Due dates are set only during 8 AM to 5 PM, excluding hours outside this range.";
export const AutoAdjustDueOnTitle = "Dynamic due dates";
export const AutoAdjustDueOnDescription =
  "Auto-adjust due dates if previous tasks are completed earlier or later than expected.";
export const AutoAdjustDueOnDefaultOptionTitle = "Default.";
export const AutoAdjustDueOnDefaultOptionDescription =
  "Keep due dates static even if previous tasks are completed earlier or later than expected.";
export const AutoAdjustDueOnDynamicOptionTitle = "Dynamic due dates.";
export const AutoAdjustDueOnDynamicOptionDescription =
  "Automatically adjust due dates if previous tasks are completed earlier or later than expected.";
export const AutoAdjustDueOnAboutDueDatesTitle = "Due dates";
export const AutoAdjustDueOnAboutDueDatesDescription = "Dynamic";
export const AutoAdjustDueOnAboutDueDatesOnHover =
  "Due dates will automatically adjust if tasks are completed earlier or later than expected.";
export const AutoAdjustDueOnFailureToast = "Error configuring dynamic due date settings";
export const AutoAdjustDueOnSuccessToast = "Updated dynamic due date settings";
export const BusinessCalendarBizCalInformation =
  "For tasks assigned to multiple people, due dates will be calculated based on the user with the earliest timezone.";

// Integration authentication
export const IntegrationAuthenticationDisabledDeleteButtonTooltipText = (
  integrationsUsingAuthentication: number | undefined,
) =>
  `This connection cannot be deleted because it is still being used in ${integrationsUsingAuthentication} existing integration(s).`;
export const IntegrationDisabledDeleteButtonTooltipText =
  "This integration cannot be deleted because it is still being used in existing company blueprints.";

// AI Monitor.
export const AiMonitor = "AI Monitor";
export const AiSummary = "Summary";
export const AiSummarySubtitle = "Generated summary of the workflow.";
export const PredictiveProblems = "Predictive problems";
export const PredictiveProblemsSubtitle =
  "Potential issues that can cause delays in completing tasks on time and quality.";
export const PredictiveDataRisk = "Predictive data risk";
export const PredictiveDataRiskSubtitle = "Potential errors and risk around data in the workflow.";
export const NoProblemsDetected = "No problems detected";
export const AiSummaryUnavailable = "AI summary has not yet been generated for this workflow. Check back later.";
export const PrefixAgo = (prefix: string) => `${prefix} ago`;
