import { MEDIA_QUERY, useMediaQuery } from "@regrello/core-utils";

import { RegrelloFormFieldLabelPlacement } from "../../ui/molecules/formFields/_internal/RegrelloFormFieldBaseProps";

/**
 * Returns the label placement that we should use for form-field labels based on the current screen
 * size. TL;DR. On smaller screens, show labels above the fields.
 */
export function useMediaQueryDependentFormFieldLabelPlacement(
  preferredLabelPlacement = RegrelloFormFieldLabelPlacement.START,
): RegrelloFormFieldLabelPlacement {
  const isSmOrBigger = useMediaQuery(MEDIA_QUERY.SM);
  return isSmOrBigger ? preferredLabelPlacement : RegrelloFormFieldLabelPlacement.TOP;
}
