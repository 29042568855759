import {
  clsx,
  EMPTY_STRING,
  getWouldKeyboardEventTypeOneCharacter,
  KeyNames,
  mergeRefs,
  useOnClose,
  useOnOpen,
  WithDataTestId,
} from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { LoadingEllipses, NoResults } from "@regrello/ui-strings";
import groupBy from "lodash/groupBy";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDeepCompareEffect } from "react-use";

import { useKeepMenuItemInView } from "./_internal/useKeepMenuItemInView";
import {
  createCustomEvent,
  menuItemVariants,
  RegrelloButton,
  RegrelloChipProps,
  RegrelloIcon,
  RegrelloIconName,
  RegrelloIntentV2,
  RegrelloSize,
  RegrelloSpinner,
  RegrelloTypography,
} from "../..";
import { RegrelloChipInput } from "../chipInput/RegrelloChipInput";
import { getInputV2ButtonSizeForInputSize } from "../input/inputV2Utils";
import { RegrelloMenuV2Group, RegrelloMenuV2Item, RegrelloMenuV2ItemContent } from "../menuV2/RegrelloMenuV2";
import { RegrelloPopover } from "../popover/RegrelloPopover";

// A special highlighted index that indicates that no item is selected.
const NO_SELECTION = -1;

// The white space to include around the menu items.
const CSS_CLASS_MENU_WHITESPACE = "p-1";

// When we auto-scroll to an item, include some nice visual margin around it. For pristine pixelism,
// this should be equal to the two values above.
const CSS_CLASS_SCROLL_ITEM_WHITESPACE = "scroll-m-y-1";

// A sentinel value to represent the "+ Create" item in items arrays.
const CREATE_ITEM_SYMBOL = Symbol("create-item");
type TCreateItem = typeof CREATE_ITEM_SYMBOL;

// An intermediate representation of an item that facilitates rendering of grouped items when
// `getItemGroup` is provided.
type IntermediateItem<T> = { item: T | TCreateItem; groupName?: string };

export interface RegrelloMultiSelectProps<T>
  extends WithDataTestId,
    Pick<React.InputHTMLAttributes<HTMLInputElement>, "autoFocus" | "name" | "placeholder" | "id"> {
  /**
   * Whether to close the menu when an item is selected.
   * @default false
   */
  closeOnSelect?: boolean;

  /**
   * Providing `createItemOptions` will show a "+ Create" button at the bottom of the popover menu.
   */
  createItemOptions?: {
    /**
     * Callback that determines if the "+ Create" item should be disabled and un-selectable. By
     * default, the "+ Create" item will be undisabled and therefore selectable.
     */
    getCreateItemDisabled?: () => boolean;

    /**
     * Callback invoked when the "+ Create" item is selected. Receives the current content of the
     * query input (this allows you to change the item text to "+ Create [query]" if you wish).
     */
    onCreateItem: (query: string) => void;

    /**
     * Callback that renders the "+ Create" menu item. Receives the current content of the query
     * input (this allows you to change the item text to "+ Create [query]" if you wish). This will
     * always be shown as the bottom-most item in the menu.
     */
    renderCreateItem: (
      query: string,
    ) => WithDataTestId &
      Pick<React.ComponentProps<typeof RegrelloMenuV2Item>, "icon" | "secondaryText" | "shortcut" | "text" | "tooltip">;
  };

  /**
   * Whether the component is non-interactive.
   *
   * @default false
   */
  disabled?: boolean;

  /**
   * Whether to __not__ trigger `onSearch` when the user focuses the input with a query already
   * present. This behavior can be useful to ensure the items are pre-filtered when you re-open the
   * popover after querying and blurring the input.
   *
   * This behavior may be useful to disable if you're loading items asynchronously, because it can
   * be undesirable to trigger a search on every focus.
   *
   * @default false
   */
  disableSearchOnFocus?: boolean;

  /**
   * If `true`, the button will take up the full width of its container.
   */
  fullWidth?: boolean;

  /**
   * While `true`, the menu will show a "Loading..." message instead of items. Use this when
   * loading items asynchronously.
   */
  loading?: boolean;

  /**
   * Callback that determines if a particular item should be disabled and un-selectable. By
   * default, all items will be undisabled and therefore selectable.
   */
  getItemDisabled?: (item: T) => boolean;

  /**
   * Callback that returns the name of a menu group the item should appear in. If provided, then
   * all items must be assigned to some group.
   */
  getItemGroup?: (item: T) => string;

  /**
   * Callback that returns custom props for the chip that represents the provided selected `item`.
   * If provided, will be invoked once for each selected item on each render.
   */
  getSelectedItemProps: (
    item: T,
    index: number,
  ) => RegrelloChipProps & {
    /** If provided, shows a tooltip with this content when this chip is hovered. */
    tooltip?: React.ReactNode;
  };

  /**
   * Callback that provides custom logic for sorting groups. By default, groups will be sorted
   * alphabetically by name.
   */
  groupSortComparator?: (groupNameA: string, groupNameB: string) => number;

  /** A ref to pass to the input element. */
  inputRef?: React.Ref<HTMLInputElement>;

  /**
   * A semantic intent color to apply to the component.
   *
   * @default "neutral"
   */
  intent?: Extract<RegrelloIntentV2, "neutral" | "warning" | "danger">;

  /**
   * Callback for determining whether two items are the same item. Defaults to shallow equality
   * (`===`) if not provided.
   */
  isItemsEqual?: (itemA: T, itemB: T) => boolean;

  /**
   * Callback that determines if the provided item matches the curernt query.
   *
   * This must be provided so that searching works by default in some basic way without extra
   * boilerplate from the caller. However, you can also listen for `onSearch` and perform custom
   * logic if you need to do something more complex, such as fetching new results when the query
   * changes.
   */
  itemPredicate: (query: string, item: T) => boolean;

  /** A list of items from which the user can select. */
  items: readonly T[];

  /** A data-testid to pass to the suggestions menu popover. */
  menuDataTestId?: string;

  /**
   * An element to show when there are no search results matching the current query. By default,
   * shows "No results".
   */
  noResults?: React.ReactNode;

  /**
   * Callback invoked when the input loses focus. Interactions within the popover do not count as
   * blurring the input field.
   */
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;

  /**
   * Callback invoked when the user clicks the "clear" ('x') button in the input. This should
   * remove all non-disabled selected values.
   */
  onClear: () => void;

  /**
   * Callback invoked when an item is deselected, either via (1) clicking an 'x' on a
   * selected-value chip or (2) clicking an already-selected menu item.
   */
  onItemDeselect: (item: T, index: number) => void;

  /**
   * Callback invoked when an item is selected. It is up to the caller to combine this item with
   * any already-selected items and pass in a new `selectedItems` array.
   */
  onItemSelect: (item: T, index: number) => void;

  /** Callback invoked when the popover menu opens or closes. */
  onOpenChange?: (nextIsOpen: boolean) => void;

  /**
   * Callback invoked when the menu scrolls. This is useful for using infinite scrolling to reduce
   * data requested.
   */
  onScroll?: ({ currentTarget }: { currentTarget: HTMLElement }) => Promise<void>;

  /**
   * Callback invoked when the user types in a new search query. Will be invoked on every key
   * stroke; it is up to the caller to implement any desired debouncing or throttling if needed.
   *
   * After this is invoked, the caller may pass in new `items` that match the provided `query`
   * according to the caller's preferred criteria. This approach can provide richer filtering
   * experiences than the default `itemPredicate` experience enables.
   */
  onSearch?: (query: string) => void;

  /**
   * Custom content to show below the list of items in the popover. A common use case here for
   * Regrello would be a custom disclaimer message.
   *
   * ___Note:___ By default, clicks in the popover automatically refocus the chip input outside of
   * the popover. You can override this by invoking `event.preventDefault()` in your mouse- and
   * keyboard-event handlers on this custom element, if you wish.
   */
  popoverCustomBottomContent?: React.ReactNode;

  /**
   * Custom content to show above the list of items in the popover. A common use case here for
   * Regrello would be tabs.
   *
   * ___Note:___ By default, clicks in the popover automatically refocus the chip input outside of
   * the popover. You can override this by invoking `event.preventDefault()` in your mouse- and
   * keyboard-event handlers on this custom element, if you wish.
   */
  popoverCustomTopContent?: React.ReactNode;

  /** Callback that renders a particular item for display in the menu. */
  renderItem: (
    item: T,
  ) => { key: React.Key } & WithDataTestId &
    Pick<
      React.ComponentProps<typeof RegrelloMenuV2Item>,
      "icon" | "inset" | "intent" | "secondaryText" | "shortcut" | "text" | "tooltip"
    >;

  /** A controlled list of items that are currently selected. */
  selectedItems: readonly T[];

  /**
   * The size of the input.
   * @default "large"
   */
  size?: RegrelloSize;

  /** An optional icon to display within the input. */
  startIcon?: RegrelloIconName;
}

export const RegrelloMultiSelectInternal = function RegrelloMultiSelectFn<T>({
  autoFocus = false,
  closeOnSelect: isCloseOnSelectEnabled = false,
  createItemOptions,
  dataTestId,
  disabled: isDisabled = false,
  disableSearchOnFocus: isSearchOnFocusDisabled = false,
  fullWidth: isFullWidth = false,
  getItemDisabled,
  getItemGroup,
  getSelectedItemProps,
  groupSortComparator = compareItemGroupsAlphabetically,
  id,
  inputRef: propsInputRef,
  intent = "neutral",
  isItemsEqual = isItemsShallowEqual,
  itemPredicate,
  items: propsItems,
  loading: isLoading = false,
  menuDataTestId,
  name,
  noResults,
  onBlur,
  onClear,
  onItemDeselect,
  onItemSelect,
  onOpenChange,
  onScroll,
  onSearch,
  placeholder,
  popoverCustomBottomContent,
  popoverCustomTopContent,
  renderItem,
  selectedItems,
  size = "large",
  startIcon,
}: RegrelloMultiSelectProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const [chipInputValue, setChipInputValue] = useState(EMPTY_STRING);
  const [highlightedItemIndex, setHighlightedItemIndex] = useState(NO_SELECTION);
  const [items, setItems] = useState(propsItems);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const popoverContentRef = useRef<HTMLDivElement | null>(null);
  const didPopoverCloseBecauseOfMouseDownOutsideRef = useRef<boolean>(false);
  const shouldKeepPopoverClosedOnNextInputFocus = useRef<boolean>(false);

  const handleSearchDefault = useCallback(
    (query: string) => {
      setItems(propsItems.filter((item) => itemPredicate(query, item)));
    },
    [itemPredicate, propsItems],
  );

  const handleSearch = onSearch ?? handleSearchDefault;

  // Keep internal items in sync with the item props when the items change.
  useEffect(() => {
    setItems(propsItems.filter((item) => itemPredicate(chipInputValue, item)));
  }, [chipInputValue, itemPredicate, propsItems]);

  // Clears the selection when the menu opens.
  useOnOpen(isOpen, () => setHighlightedItemIndex(NO_SELECTION));

  // Invoke onOpenChange when the menu opens or closes.
  // (clewis): Passing the callback directly to PopoverV2 doesn't work correctly for some reason.
  useOnOpen(isOpen, () => onOpenChange?.(true));
  useOnClose(isOpen, () => onOpenChange?.(false));

  // Clear the selection when the items change.
  useDeepCompareEffect(() => {
    if (chipInputValue === EMPTY_STRING) {
      setHighlightedItemIndex(NO_SELECTION);
    } else {
      setHighlightedItemIndex(0);
    }
  }, [items, chipInputValue]);

  // Keep the highlighted item in view when the user navigates via keyboard.
  useKeepMenuItemInView({
    itemIndex: highlightedItemIndex,
    scrollContainerRef: popoverContentRef,
  });

  // Closing the popover menu should transfer focus to the root so the user can Tab onward.
  const closePopover = useCallback(() => {
    setIsOpen(false);
  }, []);

  const clearInput = useCallback(() => {
    if (inputRef.current != null) {
      setChipInputValue(EMPTY_STRING);
      handleSearch(EMPTY_STRING);
    }
  }, [handleSearch]);

  const focusInput = useCallback(
    (
      options: {
        /** Whether to focus without trigger side effects (e.g., without opening the popover). */
        silent?: boolean;
      } = {},
    ) => {
      if (options.silent) {
        shouldKeepPopoverClosedOnNextInputFocus.current = true;
      }
      inputRef.current?.focus();
    },
    [],
  );

  // If the items are grouped, the display order for incrementing will be different.
  // Create this intermediate representation to handle that.
  const intermediateItems: Array<IntermediateItem<T>> = useMemo(() => {
    if (getItemGroup == null) {
      let itemsToReturn: Array<T | TCreateItem> = [...items]; // Must spread because items is readonly
      if (createItemOptions != null) {
        itemsToReturn = [...items, CREATE_ITEM_SYMBOL];
      }
      return itemsToReturn.map((item) => ({ item }));
    }

    const itemsByGroupName = groupBy(items, getItemGroup);

    const sortedGroups: Array<{ groupName: string; groupItems: T[] }> = Object.entries(itemsByGroupName)
      .map(([groupName, groupItems]) => ({ groupName, groupItems }))
      .sort((a, b) => groupSortComparator(a.groupName, b.groupName));

    const intermediateItemsWithGroups = sortedGroups.reduce(
      (agg: Array<IntermediateItem<T>>, { groupName, groupItems }) => {
        const groupIntermediateItems = groupItems.map((item) => ({ item, groupName }));
        return agg.concat(groupIntermediateItems);
      },
      [],
    );

    if (createItemOptions != null) {
      return [...intermediateItemsWithGroups, { item: CREATE_ITEM_SYMBOL }];
    } else {
      return intermediateItemsWithGroups;
    }
  }, [createItemOptions, getItemGroup, groupSortComparator, items]);

  const disabledIndices = useDisabledMenuItemsCache({
    getCreateItemDisabled: createItemOptions?.getCreateItemDisabled,
    getItemDisabled,
    intermediateItems,
  });

  const onItemDeselectInternal = useCallback(
    (item: T, index: number) => {
      onItemDeselect(item, index);
      // (dosipiuk): This is necessary to trigger events in spectrum form which works `onBlur`
      onBlur?.(
        new FocusEvent("blur", {
          relatedTarget: inputRef.current,
        }) as unknown as React.FocusEvent<HTMLInputElement>,
      );
    },
    [onBlur, onItemDeselect],
  );

  const onClearInternal = useCallback(() => {
    onClear();
    // (dosipiuk): This is necessary to trigger events in spectrum form which works `onBlur`
    onBlur?.(
      new FocusEvent("blur", {
        relatedTarget: inputRef.current,
      }) as unknown as React.FocusEvent<HTMLInputElement>,
    );
  }, [onBlur, onClear]);

  const incrementSelectedMenuItem = useCallback(
    (direction: -1 | 1) => {
      // Avoid an infinite loop when all items are disabled.
      if (disabledIndices.size === intermediateItems.length) {
        return;
      }

      // Skip over disabled items.
      let nextHighlightedItemIndex = highlightedItemIndex;
      do {
        nextHighlightedItemIndex = incrementIndexCyclic(nextHighlightedItemIndex, intermediateItems.length, direction);
      } while (disabledIndices.has(nextHighlightedItemIndex));
      setHighlightedItemIndex(nextHighlightedItemIndex);
    },
    [disabledIndices, intermediateItems.length, highlightedItemIndex],
  );

  const handleItemSelect = useCallback(
    (item: T | undefined, itemIndex: number) => {
      if (item == null || disabledIndices.has(itemIndex)) {
        return;
      }
      const isItemSelected = selectedItems.find((selectedItem) => isItemsEqual(selectedItem, item)) != null;
      if (isItemSelected) {
        onItemDeselect(item, itemIndex);
      } else {
        onItemSelect(item, itemIndex);
      }

      // User feedback suggests that it's better to clear the input and reset the list after
      // selection, than to leave the query in the input and not modify the list of results.
      clearInput();

      if (isCloseOnSelectEnabled) {
        closePopover();
        focusInput({ silent: true });
      }
    },
    [
      clearInput,
      closePopover,
      disabledIndices,
      focusInput,
      isCloseOnSelectEnabled,
      isItemsEqual,
      onItemDeselect,
      onItemSelect,
      selectedItems,
    ],
  );

  const handleChipInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChipInputValue(event.target.value);
      handleSearch(event.target.value);
    },
    [handleSearch],
  );

  const handleChipInputFocus = useCallback(() => {
    if (!shouldKeepPopoverClosedOnNextInputFocus.current) {
      setIsOpen(true);

      if (chipInputValue.length > 0 && !isSearchOnFocusDisabled) {
        handleSearch(chipInputValue);
      }
    }
    shouldKeepPopoverClosedOnNextInputFocus.current = false;
  }, [chipInputValue, handleSearch, isSearchOnFocusDisabled]);

  const handleChipInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      // Close if the input loses focus and the next active element isn't in the popover (i.e., keep
      // the popover open while the user is clicking on something inside of the popover).
      //
      // Don't use closeAndRefocusRoot here, because we don't want to interfere with the user's wish
      // to progress focus backward or forward in the document.
      if (!popoverContentRef.current?.contains(event.relatedTarget as HTMLElement)) {
        setIsOpen(false);

        // Reset this ref to prevent it from remaining in a stale state.
        shouldKeepPopoverClosedOnNextInputFocus.current = false;

        // Also clear the input value to avoid making the user feel like the typed text is going to
        // be included in the selection. (Came up in user testing.)
        setChipInputValue(EMPTY_STRING);

        // And inform the parent to reset the search, but wait for popover to animate closed so that
        // we don't see a glimpse of the updated results during the closing animation.
        setTimeout(() => handleSearch(EMPTY_STRING), 250);

        // It's important that we invoke onBlur only if both the input *and* the popover no longer
        // have focus, otherwise form validation can be triggered prematurely.
        onBlur?.(event);
      }
    },
    [handleSearch, onBlur],
  );

  const handleChipInputMouseDown = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      // Simply invoking focusInput() doesn't work reliably here.
      setIsOpen(true);
      focusInput({ silent: true });
    }
  }, [focusInput, isOpen]);

  const handleChipInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.ctrlKey || event.altKey || event.metaKey) {
        // Ignore the keystroke. This ensures the user can submit the form with Cmd+Enter and
        // generally prevents surprising side effects.
        return;
      }
      if (!isOpen) {
        if (getWouldKeyboardEventTypeOneCharacter(event) || event.key === KeyNames.ENTER) {
          setIsOpen(true);
        }
        return;
      }
      switch (event.key) {
        case KeyNames.ARROW_UP:
          incrementSelectedMenuItem(-1);
          // Don't move the cursor to the beginning of the input.
          event.preventDefault();
          break;
        case KeyNames.ARROW_DOWN:
          incrementSelectedMenuItem(1);
          // Don't move the cursor to the end of the input.
          event.preventDefault();
          break;
        case KeyNames.ENTER: {
          if (highlightedItemIndex === NO_SELECTION) {
            clearInput();
            closePopover();
          } else if (isLoading) {
            // Don't select anything while items aren't visible.
            return;
          } else {
            const highlightedItem = intermediateItems[highlightedItemIndex].item;
            if (highlightedItem === CREATE_ITEM_SYMBOL) {
              createItemOptions?.onCreateItem(chipInputValue);
              clearInput();
              closePopover();
            } else {
              handleItemSelect(highlightedItem, highlightedItemIndex);
            }
          }
          // Prevent propagation to outer components that might cause unintended side effects.
          event.preventDefault();
          break;
        }
        case KeyNames.ESCAPE:
          closePopover();
          // Prevent a parent dialog from closing in response to this keypress.
          event.stopPropagation();
          break;
        default:
          break;
      }
    },
    [
      incrementSelectedMenuItem,
      closePopover,
      isLoading,
      isOpen,
      intermediateItems,
      highlightedItemIndex,
      createItemOptions,
      chipInputValue,
      clearInput,
      handleItemSelect,
    ],
  );

  const handlePopoverInteractOutside = useCallback(
    (event: CustomEvent<{ originalEvent: PointerEvent }> | CustomEvent<{ originalEvent: FocusEvent }>) => {
      // Close the popover when the user clicks outside of the popover or the input.
      if (
        isOpen &&
        // (clewis): Material UI dialogs do a lot of "magic" focus management under the hood that
        // can cause this component's popover to open and then immediately (erroneously) close. A
        // fix is to only heed events that explicitly correlate with a user mouse-down. For this,
        // check for "dismissableLayer.pointerDownOutside".
        event.type === "dismissableLayer.pointerDownOutside" &&
        // (clewis): Stay open if the user clicks the input, the chips' delete buttons, the clear
        // button, or anywhere else in the trigger.
        triggerRef.current != null &&
        !triggerRef.current.contains(event.target as Node)
      ) {
        // (clewis): We need to inform MUI dialogs *on mouse down* to prevent weird flickers if the
        // mousedown will open another popover (e.g., by clicking a button). See triggerProps and
        // onCloseAutoSelect in this file for additional details.
        document.dispatchEvent(createCustomEvent("regrelloPopoverStateChange", { detail: false }));
        didPopoverCloseBecauseOfMouseDownOutsideRef.current = true;
        closePopover();
      }
    },
    [closePopover, isOpen],
  );

  const handlePopoverKeyDown = useCallback((event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === KeyNames.ENTER) {
      // Stay open after selection. We manage elsewhere when the popover should close.
      event.preventDefault();
    }
  }, []);

  const handlePopoverClick = useCallback(() => {
    // Move focus immediately back to the input so the user can always type-to-filter easily.
    focusInput();
  }, [focusInput]);

  const internalRenderGenericItem = useCallback(
    (
      genericItemProps: ReturnType<RegrelloMultiSelectProps<T>["renderItem"]> & {
        key: React.Key;
        isDisabled: true | undefined; // `false` doesn't work as expected, because the presence of a defined value still disables the item.
        isSelected: boolean;
        itemIndex: number;
        onClick: (event: React.MouseEvent<HTMLElement>) => void;
      },
    ) => {
      const {
        dataTestId: genericItemDataTestId,
        isDisabled: isGenericItemDisabled,
        isSelected: isGenericItemSelected,
        itemIndex: genericItemIndex,
        key: genericItemKey,
        onClick: onGenericItemClick,
        ...menuItemProps
      } = genericItemProps;
      const isGenericItemHighlighted = genericItemIndex === highlightedItemIndex;
      return (
        <div
          key={genericItemKey}
          aria-disabled={isGenericItemDisabled}
          aria-selected={isGenericItemSelected}
          className={clsx(
            menuItemVariants({
              intent: menuItemProps.intent ?? "neutral",
              isDisabled: isGenericItemDisabled,
              isSelected: isGenericItemHighlighted,
            }),
            CSS_CLASS_SCROLL_ITEM_WHITESPACE,
          )}
          data-disabled={isGenericItemDisabled}
          data-testid={genericItemDataTestId}
          onClick={(event) => {
            if (isCloseOnSelectEnabled) {
              // Sneaky! This is to prevent the popover from staying open after selecting an item
              // with the mouse.
              event.stopPropagation();
            }
            onGenericItemClick(event);
          }}
          // This is the correct role, not "menuitem".
          // See: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/option_role
          role="option"
        >
          <RegrelloMenuV2ItemContent
            {...menuItemProps}
            endElement={isGenericItemSelected && <RegrelloIcon iconName="selected" size="small" />}
          />
        </div>
      );
    },
    [highlightedItemIndex, isCloseOnSelectEnabled],
  );

  const internalRenderItem = useCallback(
    (item: T, itemIndex: number) => {
      return internalRenderGenericItem({
        isDisabled: getItemDisabled?.(item) ? true : undefined,
        isSelected: selectedItems.find((selectedItem) => isItemsEqual(selectedItem, item)) != null,
        itemIndex,
        onClick: () => handleItemSelect(item, itemIndex),
        ...renderItem(item),
      });
    },
    [getItemDisabled, handleItemSelect, internalRenderGenericItem, isItemsEqual, renderItem, selectedItems],
  );

  const internalRenderCreateItem = useCallback(
    (itemIndex: number) => {
      if (createItemOptions == null) {
        console.error(
          "[RegrelloMultiSelect] internalRenderCreateItem was called with createItemOptions == null. " +
            "This is probably a developer error.",
        );
        return null;
      }

      return internalRenderGenericItem({
        isDisabled: createItemOptions.getCreateItemDisabled?.() ? true : undefined,
        isSelected: false,
        itemIndex,
        key: "__REGRELLO_MULTI_SELECT_CREATE_ITEM__",
        onClick: (event) => {
          if (createItemOptions.getCreateItemDisabled?.()) {
            return;
          }
          createItemOptions.onCreateItem(chipInputValue);
          clearInput();
          closePopover();
          // Don't propagate this click to the popover content, because it will immediately
          // refocus the input and reopen the popover.
          event.stopPropagation();
        },
        icon: "add",
        intent: "primary",
        ...createItemOptions.renderCreateItem(chipInputValue),
      });
    },
    [chipInputValue, clearInput, closePopover, createItemOptions, internalRenderGenericItem],
  );

  // No need to memoize this, since it'll be run on every render anyway.
  const renderMenuContent = () => {
    if (isLoading) {
      return renderMessage(LoadingEllipses, <RegrelloSpinner size="small" />);
    }
    if (intermediateItems.length === 0) {
      return noResults ?? renderMessage(NoResults);
    }

    if (getItemGroup == null) {
      // Show a flat list of items.
      return intermediateItems.map(({ item }, itemIndex) => {
        if (item === CREATE_ITEM_SYMBOL) {
          return internalRenderCreateItem(itemIndex);
        } else {
          return internalRenderItem(item, itemIndex);
        }
      });
    }

    // Show items in named groups, all groups being rendered in one linear pass through the items.
    const renderedGroups: JSX.Element[] = [];

    let currentGroupName: string | undefined = undefined;
    let currentGroupItems: Array<{ item: IntermediateItem<T>["item"]; itemIndex: number }> = [];
    let currentGroupIndex = 0;

    const pushCurrentGroup = () => {
      renderedGroups.push(
        // Group names are guaranteed to be unique (and thus appropriate for a key), since we used
        // Lodash's groupBy when generating intermediate items in the first place.
        <RegrelloMenuV2Group key={currentGroupName} showSeparator={currentGroupIndex > 0} title={currentGroupName}>
          {currentGroupItems.map(({ item: itemInternal, itemIndex: itemInternalIndex }) => {
            if (itemInternal === CREATE_ITEM_SYMBOL) {
              return internalRenderCreateItem(itemInternalIndex);
            } else {
              return internalRenderItem(itemInternal, itemInternalIndex);
            }
          })}
        </RegrelloMenuV2Group>,
      );
    };

    for (let itemIndex = 0; itemIndex < intermediateItems.length; itemIndex += 1) {
      const item = intermediateItems[itemIndex].item;
      const groupName = item === CREATE_ITEM_SYMBOL ? undefined : intermediateItems[itemIndex].groupName;

      if (currentGroupName == null || groupName === currentGroupName) {
        currentGroupName = groupName;
        currentGroupItems.push({ item, itemIndex });
      } else {
        pushCurrentGroup();

        // Configure the next group.
        currentGroupName = groupName;
        currentGroupItems = [{ item, itemIndex }];
        currentGroupIndex += 1;
      }
    }

    // Add the last group.
    pushCurrentGroup();

    return renderedGroups;
  };

  return (
    <RegrelloPopover
      align="start"
      content={
        <div
          className="flex flex-col"
          style={{
            // Cap the max popover height at a little less than the available space, because it
            // looks cleaner than being flush with the edge of the viewport.
            maxHeight: "min(300px, calc(var(--radix-popper-available-height) - 16px))",
          }}
        >
          {popoverCustomTopContent != null && <div className="flex-none">{popoverCustomTopContent}</div>}
          <div className={clsx("flex-1 flex flex-col overflow-y-auto", CSS_CLASS_MENU_WHITESPACE)} onScroll={onScroll}>
            {renderMenuContent()}
          </div>
          {popoverCustomBottomContent != null && (
            <div className="flex-none" data-testid={DataTestIds.POPOVER_CUSTOM_BOTTOM_CONTENT}>
              {popoverCustomBottomContent}
            </div>
          )}
        </div>
      }
      contentProps={{
        className: "p-0", // Eliminate the default padding.
        collisionPadding: 50, // Increase to fix BUG-384 (https://app.regrello.com/workspace/xvtjtX7XRuo/workflow/4683)
        "data-testid": menuDataTestId,
        onCloseAutoFocus: () => {
          // If the popover closed due to a mouse click outside, then we shouldn't re-emit this
          // custom event, otherwise other popovers may fail to open for a short time after.
          if (didPopoverCloseBecauseOfMouseDownOutsideRef.current) {
            didPopoverCloseBecauseOfMouseDownOutsideRef.current = false;
            return;
          }
          document.dispatchEvent(createCustomEvent("regrelloPopoverStateChange", { detail: false }));
        },
        onInteractOutside: handlePopoverInteractOutside,
        onClick: handlePopoverClick,
        onKeyDown: handlePopoverKeyDown,
        onOpenAutoFocus: (event) => event.preventDefault(), // Prevent auto-focusing the popover on open.
        ref: popoverContentRef,
        role: "listbox",
        style: {
          width: "calc(var(--radix-popover-trigger-width)", // Keep the popover the same width as the chip input.
        },
      }}
      open={isOpen}
      triggerProps={{
        onMouseDown: () => {
          // (clewis): RegrelloPopover has to do some acrobatics to work elegantly within Material
          // UI's dialogs, which aggressively steal focus. Waiting until onClick to inform the
          // dialog that a RegrelloMultiSelect has opened inside of it, causes the multi-select
          // popover to flicker open and closed the first time it is opened within a dialog.
          // Informing the dialog onMouseDown that our popover is *about* to open fixes this.
          //
          // (Note: attempting to change all RegrelloPopover's to emit this onMouseDown breaks
          // other things, e.g., the ability for a popover to be automatically opened via a fake
          // click immediately after another button is clicked.)
          document.dispatchEvent(createCustomEvent("regrelloPopoverStateChange", { detail: true }));
        },
      }}
    >
      <div
        className={clsx("flex", "focus:outline-none", "group", {
          "w-min": !isFullWidth,
        })}
        data-testid={dataTestId}
        role="combobox"
      >
        <RegrelloChipInput
          chipProps={getSelectedItemProps}
          className={clsx("cursor-text", "group-focus-visible:box-inset-2", {
            "box-inset-2": isOpen, // Mimic a focused state when the popover is open.

            "shadow-primary-solid": isOpen && intent === "neutral",
            "shadow-warning-solid": isOpen && intent === "warning",
            "shadow-danger-solid": isOpen && intent === "danger",

            "group-focus:box-inset-2 group-focus:shadow-primary-solid": intent === "neutral",
            "group-focus:box-inset-2 group-focus:shadow-warning-solid": intent === "warning",
            "group-focus:box-inset-2 group-focus:shadow-danger-solid": intent === "danger",
          })}
          disabled={isDisabled}
          endElement={
            <RegrelloButton
              aria-label="Toggle menu"
              disabled={isDisabled}
              iconOnly={true}
              onClick={() => setIsOpen(!isOpen)}
              size={getInputV2ButtonSizeForInputSize(size)}
              startIcon={isOpen ? "expand-less" : "expand-more"}
              variant="ghost"
            />
          }
          fullWidth={isFullWidth}
          inputProps={{
            autoFocus,
            id,
            name,
            onBlur: handleChipInputBlur,
            onChange: handleChipInputChange,
            onFocus: handleChipInputFocus,
            onMouseDown: handleChipInputMouseDown,
            onKeyDown: handleChipInputKeyDown,
            placeholder,
            value: chipInputValue,
          }}
          inputRef={mergeRefs(inputRef, propsInputRef)}
          intent={intent}
          onClear={onClearInternal}
          onRemove={onItemDeselectInternal}
          renderChipText={(item, index) => getSelectedItemProps(item, index).children}
          rootRef={triggerRef}
          size={size}
          startIcon={startIcon}
          values={selectedItems}
        />
      </div>
    </RegrelloPopover>
  );
};

/**
 * A form input that allows the user to select one or more items from a menu of options. The menu is
 * triggered by a chip input. The selected items are displayed in the button.
 */
export const RegrelloMultiSelect = React.memo(RegrelloMultiSelectInternal) as typeof RegrelloMultiSelectInternal;

function compareItemGroupsAlphabetically(a: string, b: string): number {
  return a.localeCompare(b);
}

function isItemsShallowEqual<T>(itemA: T, itemB: T) {
  return itemA === itemB;
}

function incrementIndexCyclic(currentIndex: number, maxIndex: number, direction: -1 | 1) {
  if (direction === -1 && (currentIndex === NO_SELECTION || currentIndex === 0)) {
    // Cycle to the last item.
    return maxIndex - 1;
  }
  if (direction === 1 && (currentIndex === NO_SELECTION || currentIndex === maxIndex - 1)) {
    // Cycle to the first item.
    return 0;
  }
  return currentIndex + direction;
}

function renderMessage(message: string, icon?: React.ReactNode) {
  return (
    // (clewis): I think this results in 2 menu items' worth of height for this element.
    <div className="p-6.5 flex flex-row justify-center items-center gap-2">
      {icon}
      <RegrelloTypography muted={true}>{message}</RegrelloTypography>
    </div>
  );
}

/**
 * Returns the indexes of all disabled items in `items`, as determined by `getItemDisabled`. Will be
 * recomputed when the `items` change.
 */
function useDisabledMenuItemsCache<T>({
  getCreateItemDisabled,
  getItemDisabled,
  intermediateItems,
}: {
  getCreateItemDisabled?: () => boolean;
  getItemDisabled?: (item: T) => boolean;
  intermediateItems: Array<IntermediateItem<T>>;
}): Set<number> {
  const disabledIndicesRef = useRef<Set<number>>(new Set());

  useEffect(() => {
    disabledIndicesRef.current.clear();
    for (let itemIndex = 0; itemIndex < intermediateItems.length; itemIndex += 1) {
      const item = intermediateItems[itemIndex].item;
      if (item === CREATE_ITEM_SYMBOL) {
        if (getCreateItemDisabled?.()) {
          disabledIndicesRef.current.add(itemIndex);
        }
      } else if (getItemDisabled?.(item)) {
        disabledIndicesRef.current.add(itemIndex);
      }
    }
  }, [getCreateItemDisabled, getItemDisabled, intermediateItems]);

  return disabledIndicesRef.current;
}
