import { clsx, EMPTY_ARRAY, getKeys, isTextInput, KeyNames, WithClassName } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import { RegrelloCheckbox, RegrelloIcon, RegrelloIconName, RegrelloShape, RegrelloSize } from "@regrello/ui-core";
import {
  Cell,
  CellContext,
  ColumnDef,
  ColumnFiltersState,
  ColumnOrderState,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  HeaderContext,
  OnChangeFn,
  Row,
  RowSelectionState,
  SortingState,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCounter } from "react-use";

import { RegrelloDataGridCellButton } from "./RegrelloDataGridCellButton";
import { RegrelloDataGridColumnHeader } from "./RegrelloDataGridColumnHeader";
import {
  bulkEditColumnId,
  DATA_GRID_BULK_EDIT_CELL_WIDTH,
  DATA_GRID_BULK_EDIT_WITH_SORT_CELL_WIDTH,
} from "./regrelloDataGridConstants";
import { FeatureFlagService } from "../../../services/FeatureFlagService";

// Re-export since onRowClick handler expects a type composed with Row<> type.
export type { Row } from "@tanstack/react-table";

export interface RegrelloDataGridData<TData> extends Record<string, unknown> {
  /** A unique row identifier for use with bulk actions. */
  rowId?: string;

  /** If defined on an individual row, this makes the row expandable. */
  subRows?: TData[] | undefined;
}

/** Standard render function to define custom rendering functionality for an individual column. */
export type RegrelloDataGridRenderer<TData extends RegrelloDataGridData<TData>, K extends keyof TData> = (
  value: TData[K],
) => React.ReactChild | React.ReactNode;

/** Standard comparison function to define custom sorting functionality for an individual column. */
export type RegrelloDataGridComparator<TData extends RegrelloDataGridData<TData>, K extends keyof TData> = (
  valueA: TData[K],
  valueB: TData[K],
) => number;

/** Standard definition for a {@link RegrelloDataGrid} column. */
export interface RegrelloDataGridColumnDef<TData extends RegrelloDataGridData<TData>, K extends keyof TData> {
  /**
   * Alignment of content in the column's data cells. Behavior is undefined if both this is set and
   * the cell content already has its own content alignment.
   */
  alignContent?: "left" | "center" | "right";

  /** Alignment of content in the column's header. */
  alignHeader?: "left" | "center" | "right";

  /** Unique ID for the column. This must be a key in the overall data structure. */
  columnId: K;

  /**
   * Whether the column contains the row expand icon for rows that are expandable (i.e., they have a
   * `subRows` property defined). This icon will be rendered after content returned by
   * {@link renderCell}.
   */
  containsRowExpandIcon?: boolean;

  /**
   * Icon to display in the column header. If both this and {@link displayName} are set, the icon
   * will be rendered before the name.
   */
  displayIcon?: RegrelloIconName | JSX.Element;

  /**
   * Display name to show in the column header. If both {@link displayIcon} and this are set, the
   * icon will be rendered before the name.
   */
  displayName?: string;

  /**
   * If `type` is `enable`, makes the column render in the datagrid only when the specified feature flag
   * evaluates to `true`.
   *
   * If `type` is `disable`, the table will not render the column when the specified feature flag evaluates
   * to `true`.
   *
   * If undefined, the column will always be rendered.
   */
  visibilityController?: {
    /** @default "enable" */
    type?: "disable" | "enable";
    keyOrVariable: FeatureFlagKey | (() => boolean);
  };

  /**
   * Whether the column is able to filtered. Determines visibility of the filter controls.
   */
  filterable?: boolean;

  /**
   * Whether the column is allowed to be resized. If false, {@link maxWidth} and {@link minWidth}
   * will be ignored.
   */
  resizable?: boolean;

  /** Whether the column can be sorted. If false, {@link sort} will be ignored. */
  sortable?: boolean;

  /** Maximum width the column is allowed to be resized to. */
  maxWidth?: number;

  /**
   * Minimum width the column is allowed to be resized to. @default 50 so that column level controls
   * are always visible.
   */
  minWidth?: number;

  /** Render function to return custom cell content for the column given the base cell data. */
  renderCell: RegrelloDataGridRenderer<TData, K>;

  /**
   * Sort function to sort the data in the column. Either use the built-in alphanumeric sort
   * function, which handles strings with numbers and `null` values, or a custom sort function for
   * more control over sorting behavior.
   */
  sort?: "alphanumeric" | RegrelloDataGridComparator<TData, K>;

  /**
   * Title to use for column-level tooltips and drag 'n' drop preview. If not provided,
   * {@link displayName} will be used, then {@link columnId} if displayName is not provided.
   */
  title?: string;

  /**
   * Text to display in a {@link RegrelloTooltippedInfoIcon} next to the column header. If unspecified,
   * no accessory component is drawn.
   */
  tooltipText?: string;

  /** Starting width for the column. @default 150 */
  width?: number;
}

export type ResolvedColumnDef<TData extends RegrelloDataGridData<TData>, TValue> = ColumnDef<TData, TValue> & {
  // (zstanik): make id a required property to avoid always checking for null value when performing
  // operations involving the id. In RegrelloDataGrid, every column should have an id.
  id: string;
} & Pick<
    RegrelloDataGridColumnDef<TData, keyof TData>,
    | "alignContent"
    | "alignHeader"
    | "containsRowExpandIcon"
    | "displayIcon"
    | "displayName"
    | "title"
    | "tooltipText"
    | "visibilityController"
  >;

/**
 * Additional useful column info from `RegrelloColumnDef` not included in `react-table`'s base
 * column definition.
 */
export type ResolvedColumnInfo = {
  alignContent: "left" | "center" | "right";
  alignHeader: "left" | "center" | "right";
  containsRowExpandIcon?: boolean;
  displayIcon?: RegrelloIconName | JSX.Element;
  displayName?: string;
  tooltip?: string;
};

export interface RegrelloDataGridProps<TData extends RegrelloDataGridData<TData>> extends WithClassName {
  /**
   * Context-specific data test ID for column headers so they can be identified in various column
   * interaction tests.
   */
  columnDataTestId?: string;

  /**
   * The set of currently filtered columns. Must be provided along with `onColumnFiltersChange`.
   */
  columnFilters?: ColumnFiltersState;

  /**
   * The columns to display in the data grid. Use `regrelloDataGridColumnHelper` to convert a
   * `RegrelloColumnDef` column to a `ResolvedColumnDef` column.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Array<ResolvedColumnDef<TData, any>>;

  /**
   * The rows of data to display in the data grid. Must conform to the data structure established
   * by the {@link columns}.
   */
  data: TData[];

  /**
   * Column ID to sort data grid by default when all other sorting is cleared. The column will be
   * sorted in ascending order. If this is not set, the default data grid sort will be determined by
   * the order of rows in {@link data}.
   */
  defaultColumnSort?: keyof TData;

  /**
   * The default sort direction to use for `defaultColumnSort`. Ignored if `defaultColumnSort` is
   * not provided.
   */
  defaultColumnSortDirection?: "asc" | "desc";

  /**
   * Adds an emphasized style to the row, but is not for selecting rows. Used to draw attention to
   * specific rows.
   */
  emphasizedRows?: RowSelectionState;

  /**
   * Whether to allow rows to be expanded to reveal child rows. In addition to setting this to
   * `true` for the entire table, a row must also declare its own `subRows` property in order to
   * become expandable.
   *
   * @default true
   */
  expandable?: boolean;

  /**
   * Whether to allow columns to be filtered and display the column-level filter controls. Setting
   * this to `true` will not affect individual columns with `filterable` set to `false`.
   *
   * @default true
   */
  filterable?: boolean;

  /**
   * Whether the table should show as full width. Ignored if {@link resizable} is `true`, because
   * resizing behaves oddly on full-width tables.
   *
   * @default false
   */
  fullWidth?: boolean;

  /**
   * Whether the table's data is loaded in the parent component. This is mostly for providing test
   * backwards-compatibility with old tables. When true, will apply the class `.isLoaded` to the
   * `<table>` element.
   */
  isLoaded?: boolean;

  /**
   * Whether the table should skip sorting data because sorting is handled externally.
   */
  isManuallySorted?: boolean;

  /**
   * Callback invoked when the user's mouse enters a cell.
   */
  onCellMouseEnter?: (row: Row<TData>, columnId: keyof TData, event: React.MouseEvent<HTMLElement>) => void;

  /**
   * Callback invoked when the user's mouse leaves a cell.
   */
  onCellMouseLeave?: (row: Row<TData>, columnId: keyof TData, event: React.MouseEvent<HTMLElement>) => void;

  /** Callback to handle when an individual row is clicked. */
  onRowClick?: (row: Row<TData>) => void;

  /**
   * Callback invoked when the order of the table columns is changed.
   */
  onColumnOrderChange?: (orderState: ColumnOrderState) => void;

  /**
   * Callback invoked when column filters change. Must be provided along with `columnFilters`.
   */
  onColumnFiltersChange?: OnChangeFn<ColumnFiltersState>;

  /**
   * Callback invoked when the width of a column is changed.
   */
  onColumnResize?: (columnSizes: Record<string, number>) => void;

  /**
   * Callback invoked when the user selects or deselects one or more rows. Must be provided along with `rowSelection` to enable bulk editing.
   */
  onRowSelectionChange?: OnChangeFn<RowSelectionState>;

  /**
   * Callback when sort is updated for use with manual sorting.
   */
  onSortChange?: OnChangeFn<SortingState>;

  /**
   * Whether to allow column reordering via drag 'n' drop. The initial coulumn order is determined
   * by order of elements in {@link columns}.
   *
   * @default true
   */
  reorderable?: boolean;

  /**
   * Whether to allow columns in the data grid to be resized. Setting this to `true` won't affect
   * individual columns with `resizable` set to `false`.
   *
   * @default true
   */
  resizable?: boolean;

  /**
   * The set of currently selected rows. This is controlled by the parent so that the parent can
   * clear the selection if needed. Must be provided along with `onRowSelectionChange` to enable
   * bulk editing.
   */
  rowSelection?: RowSelectionState;

  /** Highlight rows upon hover, even if onRowClick isn't provided.  */
  showRowHighlight?: boolean;

  /**
   * Whether to allow columns to be sorted. Setting this to `true` won't affect individual columns
   * with `sortable` set to `false`.
   *
   * @default true
   */
  sortable?: boolean;

  /**
   * The sort state managed outside of this component for use with manual sorting.
   */
  sortState?: SortingState;

  /** Array of column IDs that are visible. If not set, column visibility will not be mutable. */
  visibleColumns?: Array<keyof TData>;
}

const RegrelloDataGridInternal = function RegrelloDataGridFn<TData extends RegrelloDataGridData<TData>>({
  className,
  columnDataTestId,
  columnFilters,
  columns: columnsWithFeatureFlag,
  data,
  defaultColumnSort,
  defaultColumnSortDirection,
  emphasizedRows,
  expandable: isExpandable = true,
  filterable: isFilterable = true,
  fullWidth: isFullWidth = false,
  isLoaded,
  isManuallySorted = false,
  onCellMouseEnter,
  onCellMouseLeave,
  onColumnFiltersChange,
  onColumnResize,
  onRowClick,
  onColumnOrderChange,
  onRowSelectionChange,
  onSortChange,
  reorderable: isReorderable = true,
  resizable: isResizable = true,
  rowSelection,
  showRowHighlight = false,
  sortable: isSortable = true,
  sortState = [{ id: defaultColumnSort as string, desc: defaultColumnSortDirection === "desc" }],
  visibleColumns,
}: RegrelloDataGridProps<TData>) {
  const shouldRenderColumn = useCallback((column: ResolvedColumnDef<TData, unknown>) => {
    if (column.visibilityController?.keyOrVariable == null) {
      return true;
    }
    const keyOrVariable = column.visibilityController.keyOrVariable;

    const evaluateFeatureFlagOrFunction = () =>
      typeof keyOrVariable === "function" ? keyOrVariable() : FeatureFlagService.isEnabled(keyOrVariable);

    if (column.visibilityController.type === "disable") {
      return !evaluateFeatureFlagOrFunction();
    }
    return evaluateFeatureFlagOrFunction();
  }, []);
  const columns = useMemo(
    () => columnsWithFeatureFlag.filter(shouldRenderColumn),
    [columnsWithFeatureFlag, shouldRenderColumn],
  );

  const tableBodyElementRef = useRef<HTMLTableSectionElement | null>(null);

  const isBulkEditEnabled = onRowSelectionChange != null && rowSelection != null;

  // (wsheehan): If no onRowClick callback has been provided, but bulk editing is in use, make row
  // click behavior select the clicked row.
  const resolvedOnCellClick = useCallback(
    (cell: Cell<TData, keyof TData>) => {
      if (onRowClick == null && isBulkEditEnabled && cell.column.id === bulkEditColumnId) {
        return cell.row.toggleSelected();
      } else if (onRowClick != null) {
        return onRowClick(cell.row);
      }
    },
    [isBulkEditEnabled, onRowClick],
  );

  const handleRowKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTableRowElement>, row: Row<TData>) => {
      // (anthony): Allow entering text into an input inside a table row, if the focused element is
      // a text input.
      if (isTextInput(document.activeElement)) {
        onRowClick?.(row);
        return;
      }

      // (clewis): Prevent this from firing unless a row is truly focused. (Without this focus
      // check, the space bar ceases to work in <input>s within the row's "Edit" dialog).
      if (
        tableBodyElementRef.current?.contains(document.activeElement) &&
        (event.key === KeyNames.SPACE || event.key === KeyNames.ENTER)
      ) {
        // (zstanik): Prior to preventing default behavior here, pressing `space` while the row is
        // in focus would scroll the page down. This ensures the click behavior will be fired as
        // expected.
        event.preventDefault();
        onRowClick?.(row);
      }
    },
    [onRowClick],
  );

  /** Derive a unique ID for any given row that is related to the row data. */
  const getRowId = useCallback((originalRow: TData, index: number) => {
    return originalRow.rowId ?? `${index}`;
  }, []);

  const handleBulkEditCellKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTableCellElement>, row: Row<TData>, columnId: string) => {
      if (
        resolvedOnCellClick != null &&
        tableBodyElementRef.current?.contains(document.activeElement) &&
        (event.key === KeyNames.SPACE || event.key === KeyNames.ENTER) &&
        isBulkEditEnabled &&
        columnId === bulkEditColumnId
      ) {
        event.preventDefault();
        // (wsheehan): Prevent checkbox click from triggering resolvedOnRowClick.
        event.stopPropagation();
        row.toggleSelected();
      }
    },
    [isBulkEditEnabled, resolvedOnCellClick],
  );

  const [bulkEditHeaderForceRerenderKey, { inc: incrementBulkEditHeaderForceRerenderKey }] = useCounter(0);

  const filteredRowSelection: RowSelectionState = useMemo(() => {
    const result: RowSelectionState = {};
    if (isBulkEditEnabled) {
      data.forEach((rowData: TData, index: number) => {
        const rowDataRowId = getRowId(rowData, index);
        if (getKeys(rowSelection).find((rowId) => rowDataRowId === rowId)) {
          result[rowDataRowId] = true;
        }
      });
      incrementBulkEditHeaderForceRerenderKey();
    }
    return result;
  }, [data, getRowId, incrementBulkEditHeaderForceRerenderKey, isBulkEditEnabled, rowSelection]);

  const renderBulkEditHeader = useCallback(
    ({ table: parentTable }: HeaderContext<TData, unknown>) => {
      return (
        <RegrelloCheckbox
          // (wsheehan): Force the header to re-render whenever the row selection changes. Otherwise, it
          // will only change its appearance on hover.
          key={bulkEditHeaderForceRerenderKey}
          checked={parentTable.getIsSomeRowsSelected() ? "indeterminate" : parentTable.getIsAllRowsSelected()}
          onCheckedChange={(nextChecked) => {
            if (nextChecked) {
              parentTable.toggleAllRowsSelected();
            } else {
              parentTable.resetRowSelection();
            }
          }}
          onClick={(event) => {
            // (dosipiuk): Prevent checkbox click from switching sort direction.
            event.stopPropagation();
          }}
        />
      );
    },
    [bulkEditHeaderForceRerenderKey],
  );

  const renderBulkEditCell = useCallback(({ row }: CellContext<TData, unknown>) => {
    return (
      <RegrelloCheckbox
        checked={row.getIsSelected()}
        onCheckedChange={() => {
          row.toggleSelected();
        }}
        onClick={(event) => {
          // (wsheehan): Prevent checkbox click from triggering resolvedOnRowClick.
          event.stopPropagation();
        }}
      />
    );
  }, []);

  const bulkEditColumn: ResolvedColumnDef<TData, unknown> = useMemo(() => {
    // (dosipiuk): we need to merge this column definition with the one passed via props to enable overrides
    const bulkEditColumnDefinition = columns.find((column) => column.id === bulkEditColumnId);

    return {
      id: bulkEditColumnId,
      header: renderBulkEditHeader,
      cell: renderBulkEditCell,
      displayName: "Multi select",
      enableColumnFilter: isFilterable,
      enableSorting: false,
      enableResizing: false,
      tooltipText: bulkEditColumnDefinition?.tooltipText,
      alignContent: "left",
      alignHeader: "left",
      size: bulkEditColumnDefinition?.enableSorting
        ? DATA_GRID_BULK_EDIT_WITH_SORT_CELL_WIDTH
        : DATA_GRID_BULK_EDIT_CELL_WIDTH,
      ...bulkEditColumnDefinition,
    };
  }, [columns, isFilterable, renderBulkEditCell, renderBulkEditHeader]);

  const resolvedColumns = useMemo(() => {
    const columnsWithoutBulkEdit = columns.filter((column) => column.id !== bulkEditColumnId);

    return isBulkEditEnabled && columns.length > 0
      ? [bulkEditColumn, ...columnsWithoutBulkEdit]
      : columnsWithoutBulkEdit;
  }, [bulkEditColumn, columns, isBulkEditEnabled]);

  const resolvedVisibleColumns: Array<keyof TData | typeof bulkEditColumnId> | undefined = useMemo(() => {
    return isBulkEditEnabled ? [bulkEditColumnId, ...(visibleColumns ?? EMPTY_ARRAY)] : visibleColumns;
  }, [isBulkEditEnabled, visibleColumns]);

  // (zstanik): Create secondary objects with the column information not included in react-table's
  // column definition.
  const [resolvedColumnInfo, columnTitles, columnTooltips] = useMemo(() => {
    const columnInfo: Record<string, ResolvedColumnInfo> = {};
    const titles: Record<string, string> = {};
    const tooltips: Record<string, string | undefined> = {};
    resolvedColumns.forEach((column) => {
      const alignContent = column.alignContent ?? "left";
      const alignHeader = column.alignHeader ?? "left";
      const containsRowExpandIcon = column.containsRowExpandIcon ?? false;
      const displayIcon = column.displayIcon;
      const displayName = column.displayName;
      const title = column.title ?? column.displayName ?? column.id;
      columnInfo[column.id] = {
        alignContent,
        alignHeader,
        containsRowExpandIcon,
        displayIcon,
        displayName,
      };
      titles[column.id] = title;
      tooltips[column.id] = column.tooltipText;
    });

    return [columnInfo, titles, tooltips];
  }, [resolvedColumns]);

  // (zstanik): Callback used by `RegrelloDataGridColumnHeaderDragPreview` to get the appropriate
  // title to display.
  const getTitle = useCallback(
    (columnId: string) => {
      return columnTitles[columnId];
    },
    [columnTitles],
  );

  const getTooltip = useCallback(
    (columnId: string) => {
      return columnTooltips[columnId];
    },
    [columnTooltips],
  );

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    resolvedColumns.map((column) => column.id as string),
  );
  const onReorderColumns = useCallback(
    (draggedColumnId: string, targetColumnId: string): void => {
      if (draggedColumnId === bulkEditColumnId || targetColumnId === bulkEditColumnId) {
        return;
      }
      columnOrder.splice(
        columnOrder.indexOf(targetColumnId),
        0,
        columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0],
      );
      setColumnOrder([...columnOrder]);
      onColumnOrderChange?.(columnOrder);
    },
    [columnOrder, onColumnOrderChange],
  );

  const initialColumnVisibility: VisibilityState = useMemo(() => {
    const result: VisibilityState = {};
    resolvedColumns.forEach((column) => {
      result[column.id] = resolvedVisibleColumns == null || resolvedVisibleColumns.includes(column.id);
    });
    return result;
  }, [resolvedColumns, resolvedVisibleColumns]);

  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(initialColumnVisibility);

  // Only used if `isManuallySorted` = false
  const [internalSortState, setInternalSortState] = useState([
    { id: defaultColumnSort as string, desc: defaultColumnSortDirection === "desc" },
  ]);

  // (zstanik): No need to specify `columnResizeMode` here since I had to override the default
  // column sizing interaction with a custom interaction that only supports changing the column size
  // at the end of the user interaction for performance gains.
  const table = useReactTable<TData>({
    columns: resolvedColumns,
    data,
    enableSorting: isSortable,
    enableMultiSort: false,
    enableMultiRowSelection: isBulkEditEnabled,
    enableRowSelection: isBulkEditEnabled,
    enableSortingRemoval: false,
    enableSubRowSelection: false,
    enableExpanding: isExpandable,
    enableFilters: isFilterable,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowId: getRowId,
    getSortedRowModel: getSortedRowModel(),
    getSubRows: (row) => row.subRows,
    onColumnOrderChange: setColumnOrder,
    onColumnFiltersChange,
    manualSorting: isManuallySorted,
    onExpandedChange: setExpanded,
    onSortingChange: isManuallySorted ? onSortChange : setInternalSortState,
    onRowSelectionChange,
    sortDescFirst: true,
    state: {
      columnOrder,
      columnFilters,
      rowSelection: filteredRowSelection,
      columnVisibility,
      expanded,
      sorting: isManuallySorted ? sortState : internalSortState,
    },
  });

  useEffect(() => {
    const newColumnVisibility: VisibilityState = {};
    resolvedColumns.forEach((column) => {
      newColumnVisibility[column.id] = resolvedVisibleColumns == null || resolvedVisibleColumns.includes(column.id);
    });
    setColumnVisibility(newColumnVisibility);
  }, [resolvedColumns, resolvedVisibleColumns]);

  // (zstanik): Necessary to merge newly enabled columns into the column order state without
  // overwriting any changes to the column order a user may have introduced after the initial order
  // was set. The original expectation was that the `columns` prop would contain all data grid
  // columns, visible and hidden, from the start, so column order would always contain all the
  // columns. This original assumption turned out to be contrary to how column states are stored on
  // the BE.
  useEffect(() => {
    const newColumnOrder = [...columnOrder];
    // (wsheehan): Previously, column ordering was not consistent when multiple new columns were
    // added at once. I introduced this boolean to only update the state once at the end, instead of
    // modifying it in place during the for loop.
    let modified = false;
    resolvedColumns.forEach(({ id }, index) => {
      if (!columnOrder.includes(id)) {
        // (zstanik): `splice()` is a bit opaque to read and understand, but this is cleaner than
        // other methods. This essentially inserts the column `id` at `index` while deleting 0
        // items.
        newColumnOrder.splice(index, 0, id);
        modified = true;
      }
    });
    if (modified) {
      setColumnOrder(newColumnOrder);
    }
  }, [resolvedColumns, columnOrder]);

  const columnSizeData = table.getState().columnSizing;
  useEffect(() => onColumnResize?.(columnSizeData), [columnSizeData, onColumnResize]);

  const tableRootRef = useRef<HTMLDivElement>(null);
  const allRows = table.getRowModel().rows;

  return (
    <div
      ref={tableRootRef}
      className={clsx(
        "h-full bg-background border overflow-x-auto rounded",
        "max-w-full", // (clewis): maxWidth rather than width so we don't get weird column-resize behavior when the table is < 100% width.
        {
          "w-full": isFullWidth && !isResizable, // (clewis): Ignore isFullWidth if the table is resizable, because resizing behaves weirdly in this scenario.
        },
        className,
      )}
      data-testid={DataTestIds.TABLE}
    >
      <table
        className={clsx(
          "border-separate border-spacing-0 min-w-full table-fixed text-xs isolate",
          "[&_tr]:w-fit",
          "[&_th]:relative [&_th]:p-0",
          "[&_td]:p-2",
          {
            isLoaded,
          },
        )}
        style={isFullWidth ? undefined : { width: table.getTotalSize() }}
      >
        <thead className="bg-backgroundSoft sticky top-0 z-2">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <RegrelloDataGridColumnHeader<TData>
                  key={header.id}
                  alignment={resolvedColumnInfo[header.column.id].alignHeader}
                  dataGridResizable={isResizable}
                  dataTestId={columnDataTestId}
                  filterable={isFilterable}
                  header={header}
                  onReorderColumns={isReorderable ? onReorderColumns : undefined}
                  sortState={header.column.getIsSorted()}
                  table={table}
                  title={getTitle(header.column.id)}
                  tooltipText={getTooltip(header.column.id)}
                />
              ))}
            </tr>
          ))}
        </thead>

        <tbody
          ref={tableBodyElementRef}
          className="border-b [&_tr:last-child_td]:border-b-0 [&_tr:focus-visible]:outline-2"
        >
          {allRows.map((row) => {
            const isClickableRow = onRowClick != null;
            const isHighlightableRow = isClickableRow || showRowHighlight;
            const isEmphasizedRow = emphasizedRows?.[row.id] ?? false;

            return (
              <tr
                key={row.id}
                className={clsx({
                  "bg-primary-transparent": row.depth > 0,
                  "cursor-pointer": isClickableRow,
                  "hover:bg-neutral-softHovered": isHighlightableRow,
                  "bg-primary-soft":
                    (isBulkEditEnabled && row.getIsSelected()) || isEmphasizedRow || row.getIsExpanded(),
                })}
                data-testid={DataTestIds.TABLE_ROW}
                onKeyDown={(e) => handleRowKeyDown(e, row)}
                tabIndex={onRowClick != null ? 0 : undefined}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={clsx("border-b", { "bulk-edit-cell": cell.column.id === bulkEditColumnId })}
                    data-testid={DataTestIds.TABLE_CELL}
                    onClick={() => resolvedOnCellClick?.(cell)}
                    onKeyDown={(e) => handleBulkEditCellKeyDown(e, row, cell.column.id)}
                    onMouseEnter={(event) => onCellMouseEnter?.(row, cell.column.id, event)}
                    onMouseLeave={(event) => onCellMouseLeave?.(row, cell.column.id, event)}
                    style={{ width: cell.column.getSize() }}
                  >
                    <div
                      className={clsx("flex items-center w-full", {
                        "justify-start": resolvedColumnInfo[cell.column.id].alignContent === "left",
                        "justify-center": resolvedColumnInfo[cell.column.id].alignContent === "center",
                        "justify-end": resolvedColumnInfo[cell.column.id].alignContent === "right",
                        "pr-5": cell.column.getCanSort() && resolvedColumnInfo[cell.column.id].alignContent !== "left",
                      })}
                      data-testid={cell.column.id}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      {row.getCanExpand() && resolvedColumnInfo[cell.column.id].containsRowExpandIcon && (
                        <RegrelloDataGridCellButton
                          className="z-1 ml-0.5 hover:bg-primary-soft active:bg-primary-softPressed"
                          iconOnly={true}
                          onClick={row.getToggleExpandedHandler()}
                          shape={RegrelloShape.CIRCLE}
                          size={RegrelloSize.X_SMALL}
                          startIcon={
                            <RegrelloIcon
                              className="text-primary-icon"
                              iconName={row.getIsExpanded() ? "expand-less" : "expand-more"}
                            />
                          }
                          variant="ghost"
                        />
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

/**
 * Standard data grid to use across the Regrello system. Use this instead of RegrelloBaseXGrid
 * moving forward.
 */
export const RegrelloDataGrid = React.memo(RegrelloDataGridInternal) as typeof RegrelloDataGridInternal;
