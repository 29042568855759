import { EMPTY_ARRAY, EMPTY_STRING } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import {
  FieldFields,
  FieldInstanceFields,
  FieldInstanceFieldsWithBaseValues,
  FieldInstanceValueInputType,
  TagFields,
} from "@regrello/graphql-api";
import {
  Description,
  EditFieldsDisabledHelperText,
  EmailFieldChipTooltipText,
  Name,
  People,
  Tags,
  VersionNotes,
  WorkflowReferenceIdTooltipText,
  WorkflowReferenceIdTooltipTextV2,
} from "@regrello/ui-strings";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";

import { ConfigureVariantBlueprintConditionsFormSection } from "./_internal/ConfigureVariantBlueprintConditionsFormSection";
import {
  EMAIL_SYSTEM_FIELD_NAMES,
  ValidationRules,
  WORKFLOW_REFERENCE_ID_FIELD_NAME,
} from "../../../../../constants/globalConstants";
import { FeatureFlagService } from "../../../../../services/FeatureFlagService";
import { retainFieldInstancesByInputType } from "../../../../../utils/customFields/customFieldInputTypeUtils";
import { isDocumentFieldInstance } from "../../../../../utils/customFields/customFieldTypeUtils";
import { useDocumentUploadStatus } from "../../../../../utils/hooks/useDocumentUploadStatus";
import { useMediaQueryDependentFormFieldLabelPlacement } from "../../../../../utils/hooks/useMediaQueryDependentFormFieldLabelPlacement";
import { PartyTypeUnion } from "../../../../../utils/parties/PartyTypeUnion";
import { useUser } from "../../../../app/authentication/userContextUtils";
import { RegrelloFormDialog, RegrelloFormDialogProps } from "../../../../atoms/dialog/RegrelloFormDialog";
import { CustomFieldPluginRegistrar } from "../../../../molecules/customFields/plugins/registry/customFieldPluginRegistrar";
import {
  RegrelloCompleteCustomFieldsForm,
  RegrelloCompleteCustomFieldsFormFields,
} from "../../../../molecules/formDialogs/RegrelloCompleteCustomFieldsForm";
import { RegrelloFormFieldLabelPlacement } from "../../../../molecules/formFields/_internal/RegrelloFormFieldBaseProps";
import {
  RegrelloControlledFormFieldPartySelect,
  RegrelloControlledFormFieldTagSelect,
  RegrelloControlledFormFieldTextMultiline,
} from "../../../../molecules/formFields/controlled/regrelloControlledFormFields";
import { RegrelloControlledFormFieldText } from "../../../../molecules/formFields/controlled/RegrelloControlledFormFieldText";
import { RegrelloNameTemplateFormSection } from "../../../../molecules/nameTemplate/RegrelloNameTemplateFormSection";
import { NameTemplateFieldDisplayValue } from "../../../../molecules/nameTemplate/RegrelloNameTemplatePreviewWrapper";
import { RegrelloNameTemplateSuffixFormField } from "../../../../molecules/nameTemplate/RegrelloNameTemplateSuffixFormField";
import { FrontendNameTemplate, NameTemplateHandle } from "../../../../molecules/nameTemplate/utils/nameTemplateUtils";
import { RegrelloConfigureCustomFieldsForm } from "../customFields/RegrelloConfigureCustomFieldsForm";
import { RegrelloConfigureSpectrumFormsFormFields } from "../customFields/useConfigureSpectrumForms";

const LABEL_WIDTH = 108;

export interface RegrelloConfigureWorkflowFormFieldValues {
  name: string;
  description: string;
  tags: TagFields[];
  collaborations: PartyTypeUnion[];
  defaultInstanceFieldValues?: string[];
  versionNotes: string;
}

export enum ConfigureWorkflowFormNativeFieldKeys {
  COLLABORATIONS = "collaborations",
  DESCRIPTION = "description",
  TAGS = "tags",
  VERSION_NOTES = "versionNotes",
}

export interface ConfigureWorkflowFormDialogProps {
  defaultDescription?: string;
  defaultInteractionType?: FieldInstanceValueInputType.REQUESTED | FieldInstanceValueInputType.PROVIDED;
  defaultName?: string;
  defaultTags?: TagFields[];
  defaultVersionNotes?: string;

  /**
   * Props for the Collaborations (People) field. If this prop is undefined, the People field won't
   * render in the dialog.
   */
  addCollaborationsFieldProps?: {
    helperText: string;
    defaultCollaborations: PartyTypeUnion[];
  };

  addCustomFieldProps?: {
    isDisabled?: boolean;
    addCustomFieldFormV2: UseFormReturn<RegrelloConfigureCustomFieldsForm.Fields>;

    /** If defined, the add field button will be disabled with the provided tooltip text. */
    addFieldButtonDisabledTooltipText?: string;

    /**
     * A banner rendered above the add custom fields form. Useful for adding additional context and
     * actions to the form.
     */
    customFieldsBanner?: JSX.Element;
    disallowSelectInputType: boolean;
    disallowEditFieldValue: boolean;
    fieldInstances?: Array<FieldInstanceFields | FieldInstanceFieldsWithBaseValues>;
    customFieldFormHelperText?: string;
    allowSelectWorkflowOwner?: boolean;

    /**
     * Title that is displayed above the 'Add fields' section and helper text.
     * @default "Fields"
     */
    customFieldFormTitle?: string;
    customFieldValueHelperText?: string;
    isCustomFieldSectionHidden?: boolean;
    isDeleteFieldsDisabled?: boolean;

    /**
     * Whether to disabled editing existing fields on the workflow (i.e., fields that came from the
     * workflow template the workflow was created from).
     */
    isEditingPreexistingFieldsDisabled?: boolean;
  };

  /**
   * Whether to allow fields to have empty values.
   * @default false
   */
  allowEmptyValues?: boolean;

  completeCustomFieldProps?: {
    allowCreateUsers: boolean;
    completeCustomFieldFormV2: UseFormReturn<RegrelloCompleteCustomFieldsFormFields>;
    fieldInstances: Array<FieldInstanceFields | FieldInstanceFieldsWithBaseValues>;
  };

  /**
   * Props for configuring variant blueprint conditions, if a variant is being created or updated.
   * If not defined, the conditions form section won't be rendered.
   */
  configureVariantBlueprintConditionsProps?: ConfigureVariantBlueprintConditionsFormSection.Props;

  /**
   * List of native fields to hide in the dialog. As of Jul 2023, our configure workflow dialogs
   * were significantly updated and more combinations of visible native fields were possible.
   */
  hiddenNativeFields?: ConfigureWorkflowFormNativeFieldKeys[];

  isAddFieldsButtonHidden?: boolean;

  isNativeFieldHidden?: boolean;

  /**
   * Whether the required instruction at the bottom of the dialog is hidden. Updated designs for
   * some of our dialogs no longer show the required instruction.
   *
   * @default false
   */
  isRequiredInstructionHidden?: boolean;

  /**
   * Props for the review workflow button, which will be rendered as a secondary submit button. As
   * of Jul 2023 when creating a workflow from a blueprint, it is now possible to choose between
   * starting a new workflow immediately or reviewing before starting.
   *
   * @default false
   */
  reviewWorkflowButtonProps?: {
    onClick: (data: RegrelloConfigureWorkflowFormFieldValues) => Promise<boolean>;
    text: string;
  };

  /** Props for rendering a name template form field in the configure workflow form. */
  nameTemplateProps?: {
    nameTemplateFormFieldRef: React.RefObject<NameTemplateHandle>;
    context: "workflow" | "blueprint";
    defaultNameTemplate?: FrontendNameTemplate;

    /**
     * Whether the suffix form field is in an error state. Will be rendered with a red bar on the
     * left, a "Field is required" error below and a red border on focus.
     */
    hasError?: boolean;

    /** Whether the suffix form field is required. */
    isRequired?: boolean;

    /** Callback invoked when the suffix form field is blurred. */
    onBlur?: () => void;

    /**
     * If supplied, restricts the fields that can be used in the name template to the given, and
     * prevents the "Add field" button from displaying.
     */
    preloadedFields?: FieldFields[];
    workflowOrTemplateId?: number;
  };

  defaultInstanceValues?: { [key: string]: string };

  submitButtonText?: string;
}

/**
 * This interface is the same as `RegrelloFormDialogProps`, except `defaultValues` is optional
 * (defaulting to empty strings), and `children` and `showRequiredInstructions` are not needed.
 */
export type RegrelloConfigureWorkflowFormDialogProps = Partial<
  Pick<RegrelloFormDialogProps<RegrelloConfigureWorkflowFormFieldValues>, "defaultValues">
> &
  Omit<
    RegrelloFormDialogProps<RegrelloConfigureWorkflowFormFieldValues>,
    "children" | "defaultValues" | "showRequiredInstruction"
  > &
  ConfigureWorkflowFormDialogProps;

/**
 * A dialog that shows name, description, tags, and custom fields for creating and editing workflows
 * and workflow templates.
 */
export const RegrelloConfigureWorkflowFormDialog = React.memo(function RegrelloConfigureWorkflowFormDialogFn({
  allowEmptyValues = false,
  defaultInstanceValues,
  description,
  hiddenNativeFields,
  isAddFieldsButtonHidden = false,
  isOpen,
  isRequiredInstructionHidden = false,
  nameTemplateProps,
  onClose,
  onSubmit,
  reviewWorkflowButtonProps,
  ...formDialogProps
}: RegrelloConfigureWorkflowFormDialogProps) {
  const { currentUser } = useUser();
  const isAdmin = currentUser.isAdmin;

  const isPermissionsV2Enabled = FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01);
  const isBlueprintVersionsEnabled = FeatureFlagService.isEnabled(FeatureFlagKey.BLUEPRINT_VERSIONS_2024_07);

  const preferredLabelPlacement =
    formDialogProps.completeCustomFieldProps?.completeCustomFieldFormV2 != null
      ? // Prefer top label placement when filling out custom fields, because the labels can be long.
        RegrelloFormFieldLabelPlacement.TOP
      : RegrelloFormFieldLabelPlacement.START;
  const labelPlacement = useMediaQueryDependentFormFieldLabelPlacement(preferredLabelPlacement);

  const defaultValues: RegrelloConfigureWorkflowFormFieldValues = useMemo(
    () => ({
      name: formDialogProps.defaultName ?? EMPTY_STRING,
      description: formDialogProps.defaultDescription ?? EMPTY_STRING,
      tags: formDialogProps.defaultTags ?? EMPTY_ARRAY,
      collaborations: formDialogProps.addCollaborationsFieldProps?.defaultCollaborations ?? EMPTY_ARRAY,
      versionNotes: formDialogProps.defaultVersionNotes ?? EMPTY_STRING,
    }),
    [
      formDialogProps.addCollaborationsFieldProps?.defaultCollaborations,
      formDialogProps.defaultDescription,
      formDialogProps.defaultName,
      formDialogProps.defaultTags,
      formDialogProps.defaultVersionNotes,
    ],
  );

  // (hchen): Spectrum form is not supported by this component, but must be initialized so for
  // `RegrelloConfigureCustomFieldsForm.Component` to work
  const spectrumFormManager = useForm<RegrelloConfigureSpectrumFormsFormFields>({
    mode: "onChange",
    defaultValues: {
      forms: EMPTY_ARRAY,
    },
  });

  const initialFieldInstancesForAddForm = useMemo(
    () => formDialogProps.addCustomFieldProps?.fieldInstances ?? EMPTY_ARRAY,
    [formDialogProps.addCustomFieldProps?.fieldInstances],
  );

  const [isDirty, setIsDirty] = useState(false);
  const addFormCustomFieldInstances = useMemo(
    () => formDialogProps.addCustomFieldProps?.fieldInstances ?? EMPTY_ARRAY,
    [formDialogProps.addCustomFieldProps?.fieldInstances],
  );

  const fieldInstancesToComplete = useMemo(() => {
    return retainFieldInstancesByInputType(formDialogProps.completeCustomFieldProps?.fieldInstances ?? EMPTY_ARRAY, [
      FieldInstanceValueInputType.REQUESTED,
      FieldInstanceValueInputType.OPTIONAL,
    ]);
  }, [formDialogProps.completeCustomFieldProps?.fieldInstances]);

  const documentFieldIds = useMemo(
    () => [
      ...addFormCustomFieldInstances
        .filter(isDocumentFieldInstance)
        .map((customFieldInstance) => customFieldInstance.field.id),
      ...fieldInstancesToComplete
        .filter(isDocumentFieldInstance)
        .map((customFieldInstance) => customFieldInstance.field.id),
    ],
    [addFormCustomFieldInstances, fieldInstancesToComplete],
  );

  const {
    isUploading,
    onUploadFinish,
    onUploadStart: onUploadStartBase,
  } = useDocumentUploadStatus({
    documentKeys: documentFieldIds,
  });

  const onUploadStart = useCallback(
    (args: typeof onUploadStartBase.arguments) => {
      onUploadStartBase(args);
      setIsDirty(true);
    },
    [onUploadStartBase],
  );

  const fieldBaseProps = {
    labelPlacement,
    labelWidth: LABEL_WIDTH,
  };

  const [nameTemplateFields, setNameTemplateFields] = useState(nameTemplateProps?.defaultNameTemplate?.fields);
  useEffect(() => {
    if (isOpen) {
      setNameTemplateFields(nameTemplateProps?.defaultNameTemplate?.fields);
    }
  }, [isOpen, nameTemplateProps?.defaultNameTemplate?.fields]);

  const [nameTemplateFieldDisplayValues, setnameTemplateFieldDisplayValues] =
    useState<NameTemplateFieldDisplayValue[]>(EMPTY_ARRAY);

  const handleSubmit = useCallback(
    async (data: RegrelloConfigureWorkflowFormFieldValues) => {
      const submitResult = await onSubmit(data);
      return submitResult;
    },
    [onSubmit],
  );

  const getDisabledInputsByFieldInstance = useCallback(
    (fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues) => {
      const customFieldPlugin = CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance);
      const maybeCrossWorkflowSourceFieldInstance =
        customFieldPlugin.getCrossWorkflowSourceFieldInstanceIdFromValue(fieldInstance);
      const crossWorkflowSinksFieldInstanceIds = customFieldPlugin.getCrossWorkflowSinksFieldInstanceIds(fieldInstance);

      // (zstanik): If the field instance is being passed into a linked workflow (i.e., at least one
      // of its sink field instances has cross workflow sink field instances), disable everything
      // but the form field input. If it's being inherited from a parent workflow, also disable the
      // form field input.
      return [
        ...(maybeCrossWorkflowSourceFieldInstance != null || crossWorkflowSinksFieldInstanceIds.length > 0
          ? [
              RegrelloConfigureCustomFieldsForm.InputKeys.CUSTOM_FIELD_SELECT,
              RegrelloConfigureCustomFieldsForm.InputKeys.DELETE_BUTTON,
              RegrelloConfigureCustomFieldsForm.InputKeys.EDIT_REGRELLO_OBJECT_BUTTON,
              RegrelloConfigureCustomFieldsForm.InputKeys.INPUT_TYPE_SWITCH,
            ]
          : EMPTY_ARRAY),
        ...(maybeCrossWorkflowSourceFieldInstance != null
          ? [RegrelloConfigureCustomFieldsForm.InputKeys.CUSTOM_FIELD_FORM_FIELD]
          : EMPTY_ARRAY),
      ];
    },
    [],
  );

  const isAnyInitialFieldInstanceDisabled = useMemo(() => {
    return initialFieldInstancesForAddForm.some(
      (initialFieldInstance) => getDisabledInputsByFieldInstance(initialFieldInstance).length > 0,
    );
  }, [getDisabledInputsByFieldInstance, initialFieldInstancesForAddForm]);

  const isWorkflowOwnerM2Enabled = FeatureFlagService.isEnabled(FeatureFlagKey.WORKLFOW_OWNER_M2_2024_04);

  return (
    <RegrelloFormDialog<RegrelloConfigureWorkflowFormFieldValues>
      {...formDialogProps}
      alwaysAvoidLosingEdits={isDirty}
      defaultValues={defaultValues}
      description={isAnyInitialFieldInstanceDisabled ? EditFieldsDisabledHelperText : description}
      isOpen={isOpen}
      isSubmitDisabled={isUploading || formDialogProps.isSubmitDisabled}
      onClose={onClose}
      onSubmit={handleSubmit}
      secondarySubmitButtonProps={reviewWorkflowButtonProps}
      showRequiredInstruction={!isRequiredInstructionHidden}
    >
      {(form) => (
        <>
          {/* Name (or Name Template, if configured) */}
          {!formDialogProps.isNativeFieldHidden && (
            <>
              {nameTemplateProps != null &&
              nameTemplateProps.context === "workflow" &&
              nameTemplateProps.defaultNameTemplate != null ? (
                <RegrelloNameTemplateSuffixFormField
                  ref={nameTemplateProps.nameTemplateFormFieldRef}
                  defaultNameTemplate={nameTemplateProps.defaultNameTemplate}
                  hasError={nameTemplateProps.hasError}
                  isRequired={nameTemplateProps.isRequired}
                  label={Name}
                  labelPlacement={fieldBaseProps.labelPlacement}
                  labelWidth={fieldBaseProps.labelWidth}
                  nameTemplateFieldDisplayValues={
                    nameTemplateProps.defaultNameTemplate?.nameTemplateFieldDisplayValues ??
                    nameTemplateFieldDisplayValues
                  }
                  onBlur={nameTemplateProps.onBlur}
                />
              ) : (
                <RegrelloControlledFormFieldText
                  {...fieldBaseProps}
                  autoFocus={true}
                  controllerProps={{
                    control: form.control,
                    name: "name",
                    rules: ValidationRules.REQUIRED,
                  }}
                  dataTestId={DataTestIds.WORKFLOW_FORM_DIALOG_FIELD_NAME}
                  isRequiredAsteriskShown={true}
                  label={Name}
                  labelPlacement={fieldBaseProps.labelPlacement}
                />
              )}

              {nameTemplateProps != null && nameTemplateProps.context === "blueprint" && (
                <RegrelloNameTemplateFormSection
                  allowCreateFields={true}
                  context={{
                    isInWorkflowTemplate: true,
                    workflowOrTemplateId: nameTemplateProps.workflowOrTemplateId,
                    workflowStageOrTemplateId: undefined,
                    dependingOnActionItemTemplateId: undefined,
                  }}
                  defaultNameTemplate={nameTemplateProps.defaultNameTemplate}
                  getFieldChipInfoStateTooltipText={(fieldName) =>
                    fieldName === WORKFLOW_REFERENCE_ID_FIELD_NAME
                      ? isPermissionsV2Enabled
                        ? WorkflowReferenceIdTooltipTextV2
                        : WorkflowReferenceIdTooltipText
                      : EMAIL_SYSTEM_FIELD_NAMES.includes(fieldName)
                        ? EmailFieldChipTooltipText(fieldName)
                        : undefined
                  }
                  nameTemplateFormFieldRef={nameTemplateProps.nameTemplateFormFieldRef}
                  onFieldsUpdate={(fields: FieldFields[]) => setNameTemplateFields(fields)}
                  preloadedFields={nameTemplateProps.preloadedFields}
                />
              )}

              {hiddenNativeFields?.find(
                (nativeField) => nativeField === ConfigureWorkflowFormNativeFieldKeys.DESCRIPTION,
              ) == null && (
                <RegrelloControlledFormFieldTextMultiline
                  {...fieldBaseProps}
                  controllerProps={{
                    control: form.control,
                    name: "description",
                  }}
                  dataTestId={DataTestIds.WORKFLOW_FORM_DIALOG_FIELD_DESCRIPTION}
                  label={Description}
                  labelPlacement={fieldBaseProps.labelPlacement}
                />
              )}

              {isBlueprintVersionsEnabled &&
                hiddenNativeFields?.find(
                  (nativeField) => nativeField === ConfigureWorkflowFormNativeFieldKeys.VERSION_NOTES,
                ) == null && (
                  <RegrelloControlledFormFieldTextMultiline
                    {...fieldBaseProps}
                    controllerProps={{
                      control: form.control,
                      name: "versionNotes",
                    }}
                    label={VersionNotes}
                    labelPlacement={fieldBaseProps.labelPlacement}
                  />
                )}

              {hiddenNativeFields?.find((nativeField) => nativeField === ConfigureWorkflowFormNativeFieldKeys.TAGS) ==
                null && (
                <RegrelloControlledFormFieldTagSelect
                  {...fieldBaseProps}
                  allowCreateTags={isAdmin}
                  controllerProps={{
                    control: form.control,
                    name: "tags",
                  }}
                  dataTestId={DataTestIds.WORKFLOW_FORM_DIALOG_FIELD_TAGS}
                  label={Tags}
                  labelPlacement={fieldBaseProps.labelPlacement}
                />
              )}
            </>
          )}

          {formDialogProps.addCollaborationsFieldProps != null &&
            hiddenNativeFields?.find(
              (nativeField) => nativeField === ConfigureWorkflowFormNativeFieldKeys.COLLABORATIONS,
            ) == null && (
              <RegrelloControlledFormFieldPartySelect
                controllerProps={{
                  name: "collaborations",
                  control: form.control,
                }}
                {...fieldBaseProps}
                allowCreate={{ teams: isAdmin, users: isAdmin }}
                dataTestId={DataTestIds.WORKFLOW_FORM_DIALOG_FIELD_COLLABORATORS}
                helperText={formDialogProps.addCollaborationsFieldProps.helperText}
                hiddenTabs={{ teams: false, users: false }}
                label={People}
                labelPlacement={fieldBaseProps.labelPlacement}
              />
            )}

          {/* Form section in which the user provides a value for each existing field instance. */}
          {formDialogProps.completeCustomFieldProps?.completeCustomFieldFormV2 != null && (
            <RegrelloCompleteCustomFieldsForm
              allowCreateUsers={formDialogProps.completeCustomFieldProps?.allowCreateUsers ?? false}
              defaultValues={defaultInstanceValues}
              fieldInstances={fieldInstancesToComplete}
              form={formDialogProps.completeCustomFieldProps?.completeCustomFieldFormV2}
              labelPlacement={fieldBaseProps.labelPlacement}
              onNameTemplateFieldDisplayValuesChange={(fieldDisplayValues) =>
                setnameTemplateFieldDisplayValues(fieldDisplayValues)
              }
              onValueChangeFinish={onUploadFinish}
              onValueChangeStart={onUploadStart}
            />
          )}

          {/** Form section in which the user configures the conditions for selecting a blueprint variant. */}
          {formDialogProps.configureVariantBlueprintConditionsProps != null && (
            <ConfigureVariantBlueprintConditionsFormSection.Component
              {...formDialogProps.configureVariantBlueprintConditionsProps}
            />
          )}

          {/* Form section in which the user adds new field instances. */}
          {isOpen &&
            !formDialogProps.addCustomFieldProps?.isCustomFieldSectionHidden &&
            formDialogProps.addCustomFieldProps?.addCustomFieldFormV2 != null && (
              <RegrelloConfigureCustomFieldsForm.Component
                addFieldButtonDisabledTooltipText={
                  formDialogProps.addCustomFieldProps.addFieldButtonDisabledTooltipText
                }
                allowCreateUsers={currentUser.isAllowedToCreateUsers}
                allowEmptyValues={allowEmptyValues}
                allowSelectWorkflowOwner={
                  formDialogProps.addCustomFieldProps?.allowSelectWorkflowOwner && isWorkflowOwnerM2Enabled
                }
                customFieldsBanner={formDialogProps.addCustomFieldProps.customFieldsBanner}
                defaultInputType={formDialogProps.defaultInteractionType}
                disallowDeleteFields={formDialogProps.addCustomFieldProps?.isDeleteFieldsDisabled}
                disallowEditFieldValue={formDialogProps.addCustomFieldProps?.disallowEditFieldValue}
                disallowSelectInputType={formDialogProps.addCustomFieldProps?.disallowSelectInputType}
                form={formDialogProps.addCustomFieldProps.addCustomFieldFormV2}
                formSectionTitle={formDialogProps.addCustomFieldProps.customFieldFormTitle}
                getDisabledInputsByFieldInstance={getDisabledInputsByFieldInstance}
                helperTextForFormSection={formDialogProps.addCustomFieldProps?.customFieldFormHelperText}
                helperTextForValuePlaceholder={formDialogProps.addCustomFieldProps?.customFieldValueHelperText}
                initialFieldInstances={initialFieldInstancesForAddForm}
                isAddFieldsButtonHidden={isAddFieldsButtonHidden}
                isAddFormButtonHidden={true}
                isCustomFieldSectionHidden={formDialogProps.addCustomFieldProps?.isCustomFieldSectionHidden}
                isDisabled={formDialogProps.addCustomFieldProps.isDisabled}
                isDragEnabled={true}
                isEditingPreexistingFieldsDisabled={
                  formDialogProps.addCustomFieldProps.isEditingPreexistingFieldsDisabled
                }
                isStandaloneFormSection={formDialogProps.isNativeFieldHidden}
                nameTemplateFields={nameTemplateFields}
                onRowAdd={() => setIsDirty(true)}
                onValueChangeFinish={onUploadFinish}
                onValueChangeStart={onUploadStart}
                spectrumConfigurationFormProps={{
                  spectrumFormManager,
                  disallowAddForms: true,
                }}
              />
            )}
        </>
      )}
    </RegrelloFormDialog>
  );
});
