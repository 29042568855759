// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { NameTemplateFields } from './NameTemplateFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { WorkflowStageTemplateFields } from './WorkflowStageTemplateFields.generated';
import { StartAfterWorkflowStageTemplateFields } from './StartAfterWorkflowStageTemplateFields.generated';
import { WorkflowStageTemplateActionItemTemplateFields } from './WorkflowStageTemplateActionItemTemplateFields.generated';
import { ActionItemTemplateFields } from './ActionItemTemplateFields.generated';
import { ActionItemTemplateActionItemFields } from './ActionItemTemplateActionItemFields.generated';
import { TagFields } from './TagFields.generated';
import { TagTypeFields } from './TagTypeFields.generated';
import { DocumentFields } from './DocumentFields.generated';
import { DocumentVersionFields } from './DocumentVersionFields.generated';
import { WorkflowPermissionsFields } from './WorkflowPermissionsFields.generated';
import { AutomationRequestFields } from './AutomationRequestFields.generated';
import { AutomationOAuth2ConfigFields } from './AutomationOAuth2ConfigFields.generated';
import { AutomationRequestParamFields } from './AutomationRequestParamFields.generated';
import { FieldInstanceFields } from './FieldInstanceFields.generated';
import { FieldInstanceBaseFields } from './FieldInstanceBaseFields.generated';
import { FieldFields } from './FieldFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { FieldUnitFields } from './FieldUnitFields.generated';
import { RegrelloObjectFields } from './RegrelloObjectFields.generated';
import { RegrelloObjectPropertyFields } from './RegrelloObjectPropertyFields.generated';
import { FieldInstanceSpectrumMetadataFields } from './FieldInstanceSpectrumMetadataFields.generated';
import { FieldInstanceSpectrumFields } from './FieldInstanceSpectrumFields.generated';
import { SpectrumFieldVersionFields } from './SpectrumFieldVersionFields.generated';
import { SpectrumFieldFields } from './SpectrumFieldFields.generated';
import { SpectrumFieldValidationTypeFields } from './SpectrumFieldValidationTypeFields.generated';
import { SpectrumFieldConstraintFields } from './SpectrumFieldConstraintFields.generated';
import { SpectrumValueConstraintFields } from './SpectrumValueConstraintFields.generated';
import { SpectrumFieldAllowedValueFields } from './SpectrumFieldAllowedValueFields.generated';
import { FieldInstanceValueFieldsFieldInstanceMultiValueDocument, FieldInstanceValueFieldsFieldInstanceMultiValueFloat, FieldInstanceValueFieldsFieldInstanceMultiValueInt, FieldInstanceValueFieldsFieldInstanceMultiValueParty, FieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance, FieldInstanceValueFieldsFieldInstanceMultiValueString, FieldInstanceValueFieldsFieldInstanceMultiValueTime, FieldInstanceValueFieldsFieldInstanceValueBoolean, FieldInstanceValueFieldsFieldInstanceValueFloat, FieldInstanceValueFieldsFieldInstanceValueInt, FieldInstanceValueFieldsFieldInstanceValueParty, FieldInstanceValueFieldsFieldInstanceValueString, FieldInstanceValueFieldsFieldInstanceValueTime } from './FieldInstanceValueFields.generated';
import { FieldInstanceValueStringFields } from './FieldInstanceValueStringFields.generated';
import { FieldInstanceValueStringBaseFields } from './FieldInstanceValueStringBaseFields.generated';
import { AcyclicFieldInstanceValueStringFields } from './AcyclicFieldInstanceValueStringFields.generated';
import { FieldInstanceMultiValueStringFields } from './FieldInstanceMultiValueStringFields.generated';
import { FieldInstanceMultiValueStringBaseFields } from './FieldInstanceMultiValueStringBaseFields.generated';
import { AcyclicFieldInstanceMultiValueStringFields } from './AcyclicFieldInstanceMultiValueStringFields.generated';
import { FieldInstanceValueIntFields } from './FieldInstanceValueIntFields.generated';
import { FieldInstanceValueIntBaseFields } from './FieldInstanceValueIntBaseFields.generated';
import { AcyclicFieldInstanceValueIntFields } from './AcyclicFieldInstanceValueIntFields.generated';
import { FieldInstanceValueFloatFields } from './FieldInstanceValueFloatFields.generated';
import { FieldInstanceValueFloatBaseFields } from './FieldInstanceValueFloatBaseFields.generated';
import { AcyclicFieldInstanceValueFloatFields } from './AcyclicFieldInstanceValueFloatFields.generated';
import { FieldInstanceValueBooleanFields } from './FieldInstanceValueBooleanFields.generated';
import { FieldInstanceValueBooleanBaseFields } from './FieldInstanceValueBooleanBaseFields.generated';
import { AcyclicFieldInstanceValueBooleanFields } from './AcyclicFieldInstanceValueBooleanFields.generated';
import { FieldInstanceValueTimeFields } from './FieldInstanceValueTimeFields.generated';
import { FieldInstanceValueTimeBaseFields } from './FieldInstanceValueTimeBaseFields.generated';
import { AcyclicFieldInstanceValueTimeFields } from './AcyclicFieldInstanceValueTimeFields.generated';
import { AcyclicFieldInstanceFields } from './AcyclicFieldInstanceFields.generated';
import { AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueDocument, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueString, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueTime, AcyclicFieldInstanceValueFieldsFieldInstanceValueBoolean, AcyclicFieldInstanceValueFieldsFieldInstanceValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceValueString, AcyclicFieldInstanceValueFieldsFieldInstanceValueTime } from './AcyclicFieldInstanceValueFields.generated';
import { AcyclicFieldInstanceValuePartyFields } from './AcyclicFieldInstanceValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValuePartyFields } from './AcyclicFieldInstanceMultiValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValueDocumentFields } from './AcyclicFieldInstanceMultiValueDocumentFields.generated';
import { DocumentBaseFields } from './DocumentBaseFields.generated';
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from './AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { RegrelloObjectInstanceFields } from './RegrelloObjectInstanceFields.generated';
import { AcyclicFieldInstanceMultiValueIntFields } from './AcyclicFieldInstanceMultiValueIntFields.generated';
import { AcyclicFieldInstanceMultiValueFloatFields } from './AcyclicFieldInstanceMultiValueFloatFields.generated';
import { AcyclicFieldInstanceMultiValueTimeFields } from './AcyclicFieldInstanceMultiValueTimeFields.generated';
import { FieldInstanceValuePartyFields } from './FieldInstanceValuePartyFields.generated';
import { FieldInstanceMultiValuePartyFields } from './FieldInstanceMultiValuePartyFields.generated';
import { FieldInstanceMultiValueDocumentFields } from './FieldInstanceMultiValueDocumentFields.generated';
import { FieldInstanceMultiValueRegrelloObjectInstanceFields } from './FieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { FieldInstanceMultiValueIntFields } from './FieldInstanceMultiValueIntFields.generated';
import { FieldInstanceMultiValueIntBaseFields } from './FieldInstanceMultiValueIntBaseFields.generated';
import { FieldInstanceMultiValueFloatFields } from './FieldInstanceMultiValueFloatFields.generated';
import { FieldInstanceMultiValueFloatBaseFields } from './FieldInstanceMultiValueFloatBaseFields.generated';
import { FieldInstanceMultiValueTimeFields } from './FieldInstanceMultiValueTimeFields.generated';
import { FieldInstanceMultiValueTimeBaseFields } from './FieldInstanceMultiValueTimeBaseFields.generated';
import { ActionItemApprovalFields } from './ActionItemApprovalFields.generated';
import { ActionItemAuditHistoryFields } from './ActionItemAuditHistoryFields.generated';
import { ActionItemAuditHistoryDeltaFields } from './ActionItemAuditHistoryDeltaFields.generated';
import { ActionItemAuditHistoryDocumentRelatedRecordFields } from './ActionItemAuditHistoryDocumentRelatedRecordFields.generated';
import { ActionItemAuditHistoryFieldInstanceRelatedRecordFields } from './ActionItemAuditHistoryFieldInstanceRelatedRecordFields.generated';
import { RootCommentFields } from './RootCommentFields.generated';
import { CommentFields } from './CommentFields.generated';
import { IntegrationAutomationInstanceFields } from './IntegrationAutomationInstanceFields.generated';
import { IntegrationAutomationSummaryFields } from './IntegrationAutomationSummaryFields.generated';
import { IntegrationAuthenticationFields } from './IntegrationAuthenticationFields.generated';
import { IntegrationAutomationInputFields } from './IntegrationAutomationInputFields.generated';
import { IntegrationAutomationOutputFields } from './IntegrationAutomationOutputFields.generated';
import { ActionItemPermissionsFields } from './ActionItemPermissionsFields.generated';
import { LineFields } from './LineFields.generated';
import { ActionItemTemplatePermissionsFields } from './ActionItemTemplatePermissionsFields.generated';
import { ActionItemTemplateStartAfterActionItemTemplateChain } from './ActionItemTemplateStartAfterActionItemTemplateChain.generated';
import { ActionItemTemplateStartAfterActionItemTemplateFields } from './ActionItemTemplateStartAfterActionItemTemplateFields.generated';
import { RejectActionFields } from './RejectActionFields.generated';
import { ActionItemTemplateActionItemWithLinkedWorkflowFields } from './ActionItemTemplateActionItemWithLinkedWorkflowFields.generated';
import { ApprovalActionItemTemplateWithActionItemFields } from './ApprovalActionItemTemplateWithActionItemFields.generated';
import { ConditionalExpressionGroupFields } from './ConditionalExpressionGroupFields.generated';
import { FieldInstanceConditionalExpressionFields } from './FieldInstanceConditionalExpressionFields.generated';
import { FieldInstanceFieldsWithBaseValues } from './FieldInstanceFieldsWithBaseValues.generated';
import { FieldInstanceValueBaseFieldsFieldInstanceMultiValueDocument, FieldInstanceValueBaseFieldsFieldInstanceMultiValueFloat, FieldInstanceValueBaseFieldsFieldInstanceMultiValueInt, FieldInstanceValueBaseFieldsFieldInstanceMultiValueParty, FieldInstanceValueBaseFieldsFieldInstanceMultiValueRegrelloObjectInstance, FieldInstanceValueBaseFieldsFieldInstanceMultiValueString, FieldInstanceValueBaseFieldsFieldInstanceMultiValueTime, FieldInstanceValueBaseFieldsFieldInstanceValueBoolean, FieldInstanceValueBaseFieldsFieldInstanceValueFloat, FieldInstanceValueBaseFieldsFieldInstanceValueInt, FieldInstanceValueBaseFieldsFieldInstanceValueParty, FieldInstanceValueBaseFieldsFieldInstanceValueString, FieldInstanceValueBaseFieldsFieldInstanceValueTime } from './FieldInstanceValueBaseFields.generated';
import { FieldInstanceValuePartyBaseFields } from './FieldInstanceValuePartyBaseFields.generated';
import { FieldInstanceMultiValuePartyBaseFields } from './FieldInstanceMultiValuePartyBaseFields.generated';
import { FieldInstanceMultiValueDocumentBaseFields } from './FieldInstanceMultiValueDocumentBaseFields.generated';
import { WorkflowTemplatePermissionsFields } from './WorkflowTemplatePermissionsFields.generated';
import { WorkflowTemplateCollaborationFields } from './WorkflowTemplateCollaborationFields.generated';
import { PartyFields } from './PartyFields.generated';
import { UserFields } from './UserFields.generated';
import { UserFieldsWithoutRoles } from './UserFieldsWithoutRoles.generated';
import { AccessRoleFields } from './AccessRoleFields.generated';
import { StarWithoutEntityFields } from './StarWithoutEntityFields.generated';
import { WorkflowGraphFields } from './WorkflowGraphFields.generated';
import { WorkflowGraphStageFields } from './WorkflowGraphStageFields.generated';
import { GenericWorkflowStageFields } from './GenericWorkflowStageFields.generated';
import { GenericWorkflowActionItemFields } from './GenericWorkflowActionItemFields.generated';
import { WorkflowGraphActionItemFields } from './WorkflowGraphActionItemFields.generated';
import { gql } from '@apollo/client';
import { FieldInstanceValueFields } from './FieldInstanceValueFields.generated';
import { AcyclicFieldInstanceValueFields } from './AcyclicFieldInstanceValueFields.generated';
import { FieldInstanceValueBaseFields } from './FieldInstanceValueBaseFields.generated';
export type WorkflowTemplateFields = { id: number, blueprintUuid: string, name: string, createdAt: string, deletedAt?: string | null, updatedAt: string, description?: string | null, isValidToUse: boolean, isEditingWorkflowApprovalsRestricted: boolean, isEditingWorkflowsRestricted: boolean, type: Types.WorkflowTemplateType, referenceNumberPrefix?: string | null, referenceNumberStartingValue?: number | null, isCreateViaEmailEnabled: boolean, createViaEmailContactEmail?: string | null, autoAdjustDueOn?: boolean | null, versionNotes?: string | null, versionNumber?: number | null, nameTemplate?: NameTemplateFields | null, createdBy: UserBaseFields, publishedBy?: PartyBaseFields | null, updatedBy: PartyBaseFields, stageTemplates: Array<WorkflowStageTemplateFields>, tags: Array<TagFields>, fieldInstances: Array<FieldInstanceFieldsWithBaseValues>, collaborators: Array<PartyBaseFields>, permissions?: WorkflowTemplatePermissionsFields | null, collaborations: Array<WorkflowTemplateCollaborationFields>, currentUserStar?: StarWithoutEntityFields | null, workflowOwnerParty?: PartyBaseFields | null, graph: WorkflowGraphFields, draftVersionWorkflowTemplate?: { id: number, permissions: { canRead: boolean } } | null, variantData?: { isCompliant: boolean, standardBlueprint: { id: number, name: string, permissions: { canRead: boolean }, fieldInstances: Array<FieldInstanceFieldsWithBaseValues> }, conditionGroup?: ConditionalExpressionGroupFields | null } | null };

export const WorkflowTemplateFields = gql`
    fragment WorkflowTemplateFields on WorkflowTemplate {
  id
  blueprintUuid
  name
  nameTemplate {
    ...NameTemplateFields
  }
  createdBy {
    ...UserBaseFields
  }
  createdAt
  publishedBy {
    ...PartyBaseFields
  }
  deletedAt
  updatedAt
  updatedBy {
    ...PartyBaseFields
  }
  description
  isValidToUse
  isEditingWorkflowApprovalsRestricted
  isEditingWorkflowsRestricted
  type
  stageTemplates {
    ...WorkflowStageTemplateFields
  }
  tags {
    ...TagFields
  }
  fieldInstances {
    ...FieldInstanceFieldsWithBaseValues
  }
  collaborators {
    ...PartyBaseFields
  }
  permissions {
    ...WorkflowTemplatePermissionsFields
  }
  collaborations {
    ...WorkflowTemplateCollaborationFields
  }
  currentUserStar {
    ...StarWithoutEntityFields
  }
  workflowOwnerParty {
    ...PartyBaseFields
  }
  referenceNumberPrefix
  referenceNumberStartingValue
  graph {
    ...WorkflowGraphFields
  }
  isCreateViaEmailEnabled
  createViaEmailContactEmail
  autoAdjustDueOn
  draftVersionWorkflowTemplate {
    id
    permissions {
      canRead
    }
  }
  versionNotes
  versionNumber
  variantData {
    standardBlueprint {
      id
      name
      permissions {
        canRead
      }
      fieldInstances {
        ...FieldInstanceFieldsWithBaseValues
      }
    }
    conditionGroup {
      ...ConditionalExpressionGroupFields
    }
    isCompliant
  }
}
    `;