import { clsx, swallowChildMouseEvent } from "@regrello/core-utils";
import { useCallback, useMemo, useState } from "react";
import { ConnectDragSource, ConnectDropTarget, useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useMount } from "react-use";

import { RegrelloDragPreviewPositioner } from "./RegrelloDragPreviewPositioner";

export interface DndReorderableItem {
  /** The unique ID of this item. */
  id: React.Key;
}

export namespace useDndReorder {
  export interface Args<TItem extends DndReorderableItem, TItemType extends string> {
    /** A ref to the drop zone's HTML element. */
    dropElementRef: React.MutableRefObject<HTMLElement | null>;

    /**
     * Whether drag-and-drop is enabled. If `true`, you'll also need to pass a defined value for
     * `item`; otherwise, dragging won't work.
     *
     * @default false
     */
    isDragEnabled?: boolean;

    /**
     * An object describing the list item to be dragged. You can pass `undefined` if
     * `isDragEnabled`. In order for dragging to work, you need to pass a defined value here and
     * also set `isDragEnabled=true`.
     */
    item: TItem | undefined;

    /**
     * The type of item being dragged. This will be provided to React DND internally to ensure that
     * the drop target accepts the type of entity being dragged.
     */
    itemType: TItemType;

    /**
     * Whether the droparea can accept the dragged item.
     */
    canDrop?: (draggedItem: TItem) => boolean;

    /**
     * Whether hovering over a nested child is handled differently.
     */
    canHoverOverNestedChild?: boolean;

    /**
     * Callback invoked when a list item is successfully dropped on another list item, which should
     * trigger a reorder.
     */
    onDrop: (draggedItem: TItem, droppedOverItem: TItem, side: "above" | "below") => void;
  }

  export interface Return {
    /** A ref that must be passed to the drag-handle element via its `ref` attribute. */
    dragRef: ConnectDragSource;

    /** A ref that can be passed to each list element via its `ref` attribute. */
    dropRef: ConnectDropTarget;

    /**
     * The current state of the drop target. The caller is responsible for showing appropriate drop
     * previews on the drop target (e.g., via CSS).
     */
    dropState: {
      isDraggingOver: boolean;
      isDraggingOverFromAbove: boolean;
      isDraggingOverFromBelow: boolean;
      dropSide: "top" | "bottom" | undefined;
    };

    /**
     * An `onMouseDown` callback to provide to the drag handle element. This will cache the initial
     * cursor offset within the drag handle so that we can position the drag preview _exactly_ in
     * the right spot under the cursor.
     */
    onDragHandleMouseDown: <T extends HTMLElement>(event: React.MouseEvent<T>) => void;

    /**
     * A wrapper function that renders the provided `dragPreviewElement` at the proper position
     * under the mouse cursor once a drag has begun.
     */
    renderDragPreviewUnderCursorIfDragging: (renderDragPreview: React.ReactNode, className?: string) => React.ReactNode;
  }
}

/**
 * A hook that provides basic functionality for reordering list elements via drag and drop. Uses
 * React DND under the hood (for greppability: `react-dnd`).
 */
export function useDndReorder<TItem extends DndReorderableItem, TItemType extends string>({
  dropElementRef,
  isDragEnabled,
  item,
  itemType,
  canDrop,
  canHoverOverNestedChild,
  onDrop,
}: useDndReorder.Args<TItem, TItemType>): useDndReorder.Return {
  const [initialOffsetWithinDragHandle, setInitialOffsetWithinDragHandle] = useState<
    { y: number; x: number } | undefined
  >();

  // (clewis): DND will be de-facto disabled if dndOptions is omitted, because we won't render the
  // drag handle in the first place. So no need to set canDrop explicitly.
  const [, dragRef, dragPreview] = useDrag(
    () => ({
      canDrag: isDragEnabled,
      type: itemType,
      item,
    }),
    [isDragEnabled, itemType, item],
  );

  const [dropSide, setDropSide] = useState<"top" | "bottom" | undefined>(undefined);

  const [dropState, dropRef] = useDrop(
    () => ({
      accept: itemType,

      collect: (monitor) => {
        const draggedItem = monitor.getItem<TItem | null>() ?? undefined;
        const delta = monitor.getDifferenceFromInitialOffset();
        const isDroppingOverSameItem = draggedItem != null && item != null && draggedItem.id === item.id;

        return {
          isDraggingOver: monitor.isOver(),
          isDraggingOverFromAbove: monitor.isOver() && !isDroppingOverSameItem && delta != null && delta.y < 0,
          isDraggingOverFromBelow: monitor.isOver() && !isDroppingOverSameItem && delta != null && delta.y > 0,
        };
      },

      canDrop: canDrop,

      hover: (draggedItem: TItem, monitor) => {
        const clientOffset = monitor.getClientOffset();
        const isOverNestedChild = !canHoverOverNestedChild && !monitor.isOver({ shallow: true });

        if (
          (canDrop != null && !canDrop(draggedItem)) ||
          isOverNestedChild ||
          dropElementRef.current == null ||
          item == null ||
          item.id === draggedItem.id ||
          clientOffset == null
        ) {
          setDropSide(undefined);
          return;
        }

        const dropTargetRect = dropElementRef.current.getBoundingClientRect();
        const hoverMiddleY = (dropTargetRect.bottom - dropTargetRect.top) / 2;
        const hoverClientY = clientOffset.y - dropTargetRect.top;

        setDropSide(hoverClientY > hoverMiddleY ? "bottom" : "top");
      },

      drop: (draggedItem: TItem, monitor) => {
        if (monitor.didDrop()) {
          return;
        }
        // (max): nullish dropSide here indicates item was dropped on itself; no-op
        if (isDragEnabled && item != null && dropSide != null) {
          onDrop(draggedItem, item, dropSide === "top" ? "above" : "below");
        }
        setInitialOffsetWithinDragHandle(undefined);
        setDropSide(undefined);
      },
    }),
    [item, onDrop, dropSide, isDragEnabled],
  );

  // (clewis): Disable the default drag image so we can render our own via the dragState below.
  useMount(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  });

  const handleDragHandleMouseDown = useCallback(<T extends HTMLElement>(event: React.MouseEvent<T>) => {
    swallowChildMouseEvent(event);

    const mouseCoords = { x: event.clientX, y: event.clientY };
    const parentRect = event.currentTarget.getBoundingClientRect();

    setInitialOffsetWithinDragHandle({
      y: mouseCoords.y - parentRect.top,
      x: mouseCoords.x - parentRect.left,
    });
  }, []);

  const renderDragPreviewUnderCursorIfDragging = useCallback(
    (renderDragPreview: React.ReactNode, className?: string) => {
      if (!isDragEnabled || item == null || initialOffsetWithinDragHandle == null) {
        return null;
      }
      return (
        <RegrelloDragPreviewPositioner
          className={clsx("z-dragPreview", className)}
          initialOffsetWithinDragHandle={initialOffsetWithinDragHandle}
          item={item}
          itemType={itemType}
        >
          {renderDragPreview}
        </RegrelloDragPreviewPositioner>
      );
    },
    [initialOffsetWithinDragHandle, isDragEnabled, item, itemType],
  );

  const combinedDropState = useMemo(() => {
    return {
      isDraggingOver: dropState.isDraggingOver,
      isDraggingOverFromAbove: dropState.isDraggingOverFromAbove,
      isDraggingOverFromBelow: dropState.isDraggingOverFromBelow,
      dropSide,
    };
  }, [dropSide, dropState.isDraggingOver, dropState.isDraggingOverFromAbove, dropState.isDraggingOverFromBelow]);

  return {
    dragRef,
    dropRef: dropRef,
    dropState: combinedDropState,
    onDragHandleMouseDown: handleDragHandleMouseDown,
    renderDragPreviewUnderCursorIfDragging,
  };
}
